import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { collection, deleteDoc, doc, getDoc, addDoc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'
import { auth, db } from '../../../FirebaseConfig'

import {
   Button,
   Box,
   Typography,
   List,
   ListItem,
   IconButton,
   Divider,
   ListItemText,
   Badge,
   Modal,
   ListItemAvatar,
   Avatar,
   FormControl,
   RadioGroup,
   FormControlLabel,
   Radio,
   Alert,
   Select,
   InputLabel,
   MenuItem,
   Link,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import myStyles from './LegisConstis.module.css'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import AddLegisConsti from './AddLegisConsti'
import { useNavigate } from 'react-router-dom'
import { selectCountries } from '../../../redux/reducers/countries-slice'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { Helmet } from 'react-helmet'

function LegisConstis({ legis }) {
   //
   const user = useSelector(selectUsers)
   const ip = user.ip
   const countries = useSelector(selectCountries).countriesList

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const [uploadingConstisList, setUploadingConstisList] = useState(true)
   const [constisListRoot, setConstisListRoot] = useState([])
   const [constisList, setConstisList] = useState([])

   const [statesList, setStatesList] = useState([])
   const [stateFull, setStateFull] = useState('')

   const [districtsList, setDistrictsList] = useState([])

   const [parentConstisList, setParentConstisList] = useState([])

   const [lastConsti, setLastConsti] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const [showStatesInModal, setShowStatesInModal] = useState(false)
   // const [showDivisionsInModal, setShowDivisionsInModal] = useState(false)
   const [showConstisListFromParentInModal, setShowConstisListFromParentInModal] = useState(false)

   const [sortField, setSortField] = useState('Name')
   const [selectedState, setSelectedState] = useState('')

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         if (legis.Constituencies && legis.Constituencies.length > 0) {
            const sortedConstituencies = [...legis.Constituencies].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setConstisListRoot(sortedConstituencies)
            setConstisList(sortedConstituencies)
            setUploadingConstisList(false)
         }
         //
         switch (legis.Strata) {
            case 'Central':
               console.log('reached the blank use effect - case central')
               fetchStatesList()
               // for modal
               setShowStatesInModal(true)
               setShowConstisListFromParentInModal(false)
               break
            case 'State':
               console.log('reached the blank use effect - case state')
               fetchStateFull(legis.State_id)
               // for modal
               setShowConstisListFromParentInModal(true)
               break
            case 'Division':
               //
               break
            case 'District':
               //
               break
            default:
         }
         //
         //
         setFirstLoadStatus('success')
      }
   }, [])

   // related to constituencies list

   // const fetchConstisListByLegis = async () => {
   //    try {
   //       setUploadingConstisList(true)
   //       const q = query(
   //          collection(db, 'LegisConstituencies'),
   //          where('Legislature_id', '==', legis.id),
   //          orderBy('Name', 'asc'),
   //       )
   //       const querySnapshot = await getDocs(q)
   //       const constisListFetched = []
   //       querySnapshot.forEach((doc) => {
   //          constisListFetched.push({ id: doc.id, ...doc.data() })
   //       })
   //       setConstisListRoot(constisListFetched)
   //       setConstisList(constisListFetched)
   //       setUploadingConstisList(false)
   //       console.log('consti list fetched by legis: ', constisListFetched)
   //    } catch (err) {
   //       console.log('error', err)
   //    }
   // }

   // const fetchConstisListByState = async (stateId) => {
   //    setConstisList(constisListRoot.filter((item) => item.State_id === stateId))
   // }

   // const fetchConstisListByParentConsti = async () => {
   //    setUploadingConstisList(true)
   //    setConstisList(constisListRoot.filter((item) => item.ParentConsti_id === selectedParentConsti))
   //    setUploadingConstisList(false)
   // }
   // const fetchConstisListBySelectedDistrict = async () => {
   //    setConstisList(constisListRoot.filter((item) => item.State_id === stateId))
   // }

   // const fetchConstisListByDivision = async (divisionId) => {
   //    setConstisList(constisListRoot.filter((f) => divisionId.some((item) => item === f.Division_id)))
   // }

   // const fetchConstisListByDistrict = async () => {
   //    setConstisList(constisListRoot.filter((f) => selectedDistrictIDs.some((item) => item === f.District_id)))
   // }

   // related to states

   const fetchStateFull = async (stateId) => {
      try {
         const docRef = doc(db, 'States', stateId)
         const docSnap = await getDoc(docRef)
         if (docSnap.exists()) {
            setStateFull({ ...docSnap.data(), id: docSnap.id })
         }
      } catch (err) {
         console.log('error: ', err)
      }
   }

   useEffect(() => {
      console.log('reached the use effect of stateFull outside if condition')
      if (stateFull !== '') {
         //
         fetchParentConstisListByState(legis.State_id)

         fetchDistrictsListByState()
      }
   }, [stateFull])

   const fetchStatesList = async () => {
      try {
         const selectedCountryComplete = countries.find((item) => item.id === legis.Country_id)
         console.log('selectedCountryComplete: ', selectedCountryComplete)

         const sortedList = [...selectedCountryComplete.States].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })

         setStatesList(sortedList)
      } catch (err) {
         console.log('error fetching states list: ', err)
      }
   }

   // related to Districts

   const fetchDistrictsListByState = async () => {
      try {
         const sortedList = [...stateFull.Districts].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })

         setDistrictsList(sortedList)

         console.log('districts list fetched by state ', sortedList)
      } catch (err) {
         console.log('error fetching districts list: ', err)
      }
   }

   // related to parent constituencies

   const fetchParentConstisListByState = async (stateId) => {
      try {
         const docRef = doc(db, 'Legislatures', legis.ParentLegislature_id)
         const docSnap = await getDoc(docRef)

         const parent = [...docSnap.data().Constituencies].filter((item) => item.State_id === stateId)

         const sortedParent = parent.sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setParentConstisList(sortedParent)

         console.log('consti list from parent by state fetched by state: ', sortedParent)
      } catch (err) {
         console.log('error', err)
      }
   }

   // related to form filling and submission

   const [openModalAddConsti, setOpenModalAddConsti] = useState(false)

   const handleOpenModalAddConsti = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAddConsti(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalAddConsti = () => setOpenModalAddConsti(false)

   const handleAddConsti = async (constiDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               constiDetails.Name.length >= 3 &&
               constiDetails.IsNominated !== '' &&
               constiDetails.ConstituencyNumber !== ''
            ) {
               setUploading(true)

               constiDetails.CrBy = auth.currentUser.uid
               constiDetails.CrDt = new Date()
               constiDetails.CrIP = ip.data.ip
               constiDetails.NameOld = [
                  {
                     Name: constiDetails.Name,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (constiDetails.NameHindi !== '') {
                  constiDetails.NameHindiOld = [
                     { Name: constiDetails.NameHindi, CrBy: auth.currentUser.uid, CrDt: new Date(), CrIP: ip.data.ip },
                  ]
               }
               if (constiDetails.NameLocal !== '') {
                  constiDetails.NameLocalOld = [
                     {
                        Name: constiDetails.NameLocal,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               console.log('constiDetails before entry: ', constiDetails)
               setLastConsti(constiDetails.Name)
               try {
                  const docRef = await addDoc(collection(db, 'LegisConstituencies'), constiDetails)
                  constiDetails.Constituency_id = docRef.id

                  const record = {
                     Constituency_id: docRef.id,
                     Legislature_id: legis.id,
                     LegislatureName: legis.Name,
                     ConstituencyNumber: constiDetails.ConstituencyNumber,
                     Name: constiDetails.Name,
                     NameHindi: constiDetails.NameHindi,
                     NameLocal: constiDetails.NameLocal,
                     NameAlt: constiDetails.NameAlt,
                     StateName: constiDetails.StateName,
                     State_id: constiDetails.State_id,
                     DivisionName: constiDetails.DivisionName,
                     Division_id: constiDetails.Division_id,
                     DistrictName: constiDetails.DistrictName,
                     District_id: constiDetails.District_id,
                     SubDistrict_id: constiDetails.SubDistrict_id,
                     SubDistrictName: constiDetails.SubDistrictName,
                     Block_id: constiDetails.Block_id,
                     BlockName: constiDetails.BlockName,
                     Village_id: constiDetails.Village_id,
                     VillageName: constiDetails.VillageName,
                     ParentConsti_id: constiDetails.ParentConsti_id,
                     ParentConstiName: constiDetails.ParentConstiName,
                     IsNominated: constiDetails.IsNominated,
                     Strata: constiDetails.Strata,
                  }
                  console.log('record: ', record)
                  console.log(legis.id)

                  const docRefUpdate = doc(db, 'Legislatures', legis.id)
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayUnion(record),
                  })

                  setConstisList([...constisList, constiDetails])
                  setConstisListRoot([...constisListRoot, constiDetails])
                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding legislature: ', error.message)
                  console.log(error)
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Related to edits

   const handleDelete = async (
      e,
      thisConstituencyId,
      thisLegislatureId,
      thisLegislatureName,
      thisConstituencyNumber,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisNameAlt,
      thisStateName,
      thisStateId,
      thisDivisionName,
      thisDivisionId,
      thisDistrictName,
      thisDistrictId,
      thisSubDistrictId,
      thisSubDistrictName,
      thisBlockId,
      thisBlockName,
      thisVillageId,
      thisVillageName,
      thisParentConstiId,
      thisParentConstiName,
      thisIsNominated,
      thisStrata,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            setUploading(false)
            setUploaded(false)
            setUploadingFailed(false)
            if (confirm('Are you sure you want to erase this constitency?')) {
               try {
                  console.log('xxxxxx')
                  await deleteDoc(doc(db, 'LegisConstituencies', thisConstituencyId))
                  console.log('yyyyyy')
                  console.log('legislature id: ', thisLegislatureId)
                  const docRefUpdate = doc(db, 'Legislatures', thisLegislatureId)
                  await updateDoc(docRefUpdate, {
                     Constituencies: arrayRemove({
                        Constituency_id: thisConstituencyId,
                        Legislature_id: thisLegislatureId,
                        LegislatureName: thisLegislatureName,
                        ConstituencyNumber: thisConstituencyNumber,
                        Name: thisName,
                        NameHindi: thisNameHindi,
                        NameLocal: thisNameLocal,
                        NameAlt: thisNameAlt,
                        StateName: thisStateName,
                        State_id: thisStateId,
                        DivisionName: thisDivisionName,
                        Division_id: thisDivisionId,
                        DistrictName: thisDistrictName,
                        District_id: thisDistrictId,
                        SubDistrict_id: thisSubDistrictId,
                        SubDistrictName: thisSubDistrictName,
                        Block_id: thisBlockId,
                        BlockName: thisBlockName,
                        Village_id: thisVillageId,
                        VillageName: thisVillageName,
                        ParentConsti_id: thisParentConstiId,
                        ParentConstiName: thisParentConstiName,
                        IsNominated: thisIsNominated,
                        Strata: thisStrata,
                     }),
                  })
                  console.log('zzzzzz')
                  setConstisList(constisList.filter((item) => item.Constituency_id !== thisConstituencyId))
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to sorting and filtering

   function handleSortField(e) {
      e.preventDefault()
      setSortField(e.target.value)
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         let sortedConstis
         switch (sortField) {
            case 'Name':
               sortedConstis = [...constisList].sort((a, b) => {
                  if (b.Name.trim() > a.Name.trim()) {
                     return -1
                  } else if (b.Name.trim() < a.Name.trim()) {
                     return 1
                  } else {
                     return 0
                  }
               })
               break
            case 'ConstituencyNumber':
               sortedConstis = [...constisList].sort((a, b) => a.ConstituencyNumber - b.ConstituencyNumber)
               break
         }

         setConstisList(sortedConstis)
      }
   }, [sortField])

   function handleChangeState(e) {
      e.preventDefault()
      setSelectedState(e.target.value)
   }

   useEffect(() => {
      if (firstLoadStatus !== 'idle') {
         if (selectedState !== '') {
            const constisListByState = constisListRoot.filter((item) => item.State_id === selectedState)

            let sortedConstis
            switch (sortField) {
               case 'Name':
                  sortedConstis = [...constisListByState].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  break
               case 'ConstituencyNumber':
                  sortedConstis = [...constisListByState].sort((a, b) => a.ConstituencyNumber - b.ConstituencyNumber)
                  break
            }

            setConstisList(sortedConstis)
         } else {
            let sortedConstis
            switch (sortField) {
               case 'Name':
                  sortedConstis = [...constisListRoot].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  break
               case 'ConstituencyNumber':
                  sortedConstis = [...constisListRoot].sort((a, b) => a.ConstituencyNumber - b.ConstituencyNumber)
                  break
            }

            setConstisList(sortedConstis)
         }
      }
   }, [selectedState])

   return (
      <div className={myStyles.main_div}>
         <div>
            <Helmet>
               <title>{`Track Democracy - Constituencies of ${legis.Name}`}</title>
            </Helmet>
         </div>
         <div>
            <Modal
               open={openModalAddConsti}
               onClose={handleCloseModalAddConsti}
               aria-labelledby="modal-modal-title-addConsti"
               aria-describedby="modal-modal-description-addConsti"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addConsti"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a constituency
                  </Typography>
                  <AddLegisConsti
                     legis={legis}
                     statesList={statesList}
                     districtsList={districtsList}
                     lastConsti={lastConsti}
                     uploading={uploading}
                     uploaded={uploaded}
                     uploadingFailed={uploadingFailed}
                     handleAddConsti={handleAddConsti}
                     stateFull={stateFull}
                     parentConstisList={parentConstisList}
                     showStatesInModal={showStatesInModal}
                     showConstisListFromParentInModal={showConstisListFromParentInModal}
                  ></AddLegisConsti>
               </Box>
            </Modal>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-line',
               lineHeight: 1.2,
            }}
         >
            Constituencies: {'\n'} निर्वाचन क्षेत्र:
         </Typography>

         {legis.HasConstituencies && (
            <Box>
               <Box id="addbutton" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 1 }}>
                  {(isDataEditor || isSuper) && (
                     <Button
                        onClick={(e) => {
                           handleOpenModalAddConsti(e)
                        }}
                        size="small"
                        variant="outlined"
                        sx={{ textTransform: 'none', py: 0, px: 1, minWidth: 0, mr: 1 }}
                     >
                        Add
                     </Button>
                  )}
                  <Box sx={{ display: 'flex', flexDirection: 'column', p: 0, ml: 'auto' }}>
                     <Box sx={{ color: '#795548', fontSize: 15 }}>Sort by:</Box>
                     <Box>
                        <FormControl
                           sx={{
                              p: 0,
                           }}
                        >
                           {/* <FormLabel id="demo-controlled-radio-buttons-group">Sort by:</FormLabel> */}
                           <RadioGroup
                              row
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="SortField"
                              value={sortField}
                              // style={{ display: 'flex', gap: '2rem' }}
                              onChange={(e) => {
                                 handleSortField(e)
                              }}
                              sx={{
                                 backgroundColor: '#ffffff',
                                 borderRadius: 1,
                                 py: 0,
                                 '& .MuiSvgIcon-root': {
                                    fontSize: 15,
                                 },
                                 '& .MuiButtonBase-root': {
                                    py: 0,
                                 },
                                 '& MuiFormControlLabel-label': {
                                    fontSize: 13,
                                    lineHeight: 16,
                                    py: 0,
                                 },
                              }}
                           >
                              <FormControlLabel
                                 value="Name"
                                 control={<Radio size="small" />}
                                 label="Name"
                                 sx={{ py: 0, fontSize: 15 }}
                              />
                              <FormControlLabel
                                 value="ConstituencyNumber"
                                 control={<Radio size="small" />}
                                 label="Constituency number"
                                 sx={{ py: 0, fontSize: 15 }}
                              />
                           </RadioGroup>
                        </FormControl>
                     </Box>
                  </Box>
               </Box>
               <Box id="sort" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 1 }}>
                  {legis.Strata === 'Central' && (
                     <>
                        <Box sx={{ display: 'inline', ml: 'auto', color: '#795548' }}>filter by:</Box>
                        <Box sx={{ display: 'inline', padding: 0 }}>
                           {legis.Strata === 'Central' && (
                              <FormControl sx={{ m: 1, width: '30ch' }} size="small">
                                 <InputLabel>State / UT</InputLabel>
                                 <Select
                                    value={selectedState}
                                    label="State / UT"
                                    onChange={handleChangeState}
                                    sx={{ backgroundColor: '#ffffff' }}
                                 >
                                    <MenuItem value="">
                                       <em>None</em>
                                    </MenuItem>
                                    {statesList &&
                                       statesList.map((item) => (
                                          <MenuItem key={item.State_id} value={item.State_id}>
                                             {item.Name}
                                          </MenuItem>
                                       ))}
                                 </Select>
                              </FormControl>
                           )}
                        </Box>
                     </>
                  )}
               </Box>
            </Box>
         )}

         <div className={myStyles.div200}>
            {legis.HasConstituencies === 'true' ? (
               <div>
                  {uploadingConstisList && (
                     <MyLoaderCircularGradient
                        title={'Fetching the list of constituencies...'}
                     ></MyLoaderCircularGradient>
                  )}
                  {!uploadingConstisList && constisList && constisList.length > 0 && (
                     <Box>
                        {/* <Pager
                     totalCount={totalCount}
                     totalPages={totalPages}
                     presentPage={presentPage}
                     showingPresentCountX={showingPresentCountX}
                     showingPresentCountY={showingPresentCountY}
                     handleNextClick={handleNextClick}
                     handlePreviousClick={handlePreviousClick}
                  ></Pager> */}
                        <List dense>
                           {constisList.map((item, index) => (
                              <div key={item.Constituency_id}>
                                 <ListItem
                                    key={item.Constituency_id}
                                    secondaryAction={
                                       <Box sx={{ ml: 'auto' }}>
                                          {isSuper && (
                                             <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(e) => {
                                                   handleDelete(
                                                      e,
                                                      item.Constituency_id,
                                                      item.Legislature_id,
                                                      item.LegislatureName,
                                                      item.ConstituencyNumber,
                                                      item.Name,
                                                      item.NameHindi,
                                                      item.NameLocal,
                                                      item.NameAlt,
                                                      item.StateName,
                                                      item.State_id,
                                                      item.DivisionName,
                                                      item.Division_id,
                                                      item.DistrictName,
                                                      item.District_id,
                                                      item.SubDistrict_id,
                                                      item.SubDistrictName,
                                                      item.Block_id,
                                                      item.BlockName,
                                                      item.Village_id,
                                                      item.VillageName,
                                                      item.ParentConsti_id,
                                                      item.ParentConstiName,
                                                      item.IsNominated,
                                                      item.Strata,
                                                   )
                                                }}
                                                sx={{ mr: 1, height: '25px', width: '25px' }}
                                             >
                                                <DeleteIcon fontSize="small" />
                                             </IconButton>
                                          )}
                                       </Box>
                                    }
                                 >
                                    <ListItemAvatar>
                                       <Avatar sx={{ height: '30px', width: '30px' }}>
                                          <Badge badgeContent={index + 1} color="secondary" max={999}></Badge>
                                       </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText
                                       secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                       primaryTypographyProps={{
                                          whiteSpace: 'pre-line',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                       }}
                                       primary={
                                          <>
                                             <Link
                                                href={`/constituency/${item.Name}/${item.Constituency_id}`}
                                                sx={{ mr: 1, textDecoration: 'none !important' }}
                                                rel="noreferrer"
                                                target="_blank"
                                             >
                                                <strong>{item.Name.toUpperCase()}</strong> ({item.ConstituencyNumber}){' '}
                                                {item.NameLocal && <>{item.NameLocal}</>} {item.NameHindi}
                                             </Link>
                                          </>
                                       }
                                       component={'span'}
                                       secondary={
                                          <>
                                             {item.Strata === 'State' && (
                                                <>
                                                   {' Parent PC: '}
                                                   <Link
                                                      href={`/constituency/${item.ParentConstiName}/${item.ParentConsti_id}`}
                                                      sx={{ mr: 1, textDecoration: 'none !important' }}
                                                      rel="noreferrer"
                                                      target="_blank"
                                                   >
                                                      {item.ParentConstiName}
                                                   </Link>
                                                   <br />
                                                </>
                                             )}
                                             {' District: '}
                                             <>
                                                {item.DistrictName.map((itemDis, index) => (
                                                   <>
                                                      {index + 1}.
                                                      <Link
                                                         key={itemDis.District_id}
                                                         // href={`/district/${itemDis.Name}/${itemDis.District_id}`}
                                                         sx={{
                                                            mr: 1,
                                                            ml: 0.5,
                                                            textDecoration: 'none !important',
                                                            color: '#000000',
                                                         }}
                                                         rel="noreferrer"
                                                         target="_blank"
                                                      >
                                                         {itemDis.Name}
                                                      </Link>
                                                   </>
                                                ))}

                                                {/* <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="text.primary"
                                                >
                                                   {item.DistrictName.join(', ')}
                                                </Typography> */}
                                             </>
                                             {item.DivisionName.length > 0 && (
                                                <>
                                                   <br />
                                                   {' Division: '}
                                                   {item.DivisionName.map((itemDiv, index) => (
                                                      <>
                                                         {index + 1}.
                                                         <Link
                                                            key={itemDiv.Division_id}
                                                            // href={`/division/${itemDiv.Name}/${itemDiv.Division_id}`}
                                                            sx={{
                                                               mr: 1,
                                                               ml: 0.5,
                                                               textDecoration: 'none !important',
                                                               color: '#000000',
                                                            }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            {itemDiv.Name}
                                                         </Link>
                                                      </>
                                                   ))}
                                                   {/* <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.DivisionName.join(', ')}
                                                   </Typography> */}
                                                </>
                                             )}
                                             {legis.Strata === 'Central' && (
                                                <>
                                                   <br />
                                                   {' State / UT: '}
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.StateName}
                                                   </Typography>
                                                </>
                                             )}
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </div>
                           ))}
                        </List>
                        {/* Pagination */}
                        {/* <div className={myStyles.pagerContainer}>
                     <div className={myStyles.pagerRangeDisplay}>
                        <Typography variant="body2" component="div" color="text.secondary">
                           Showing <strong>{showingPresentCountX}</strong> to <strong>{showingPresentCountY}</strong> of{' '}
                           <strong>{totalCount}</strong> results
                        </Typography>
                     </div>

                     <div className={myStyles.pagerNavigator}>
                        <Button disabled={presentPage <= 1} onClick={handlePreviousClick}>
                           <ArrowBackIosRounded sx={{ bgcolor: 'text.primary', color: 'background.paper' }} />
                        </Button>
                        <span>{presentPage}</span>
                        <Button
                           disabled={presentPage === totalPages || totalPages === 0}
                           onClick={handleNextClick}
                           sx={{ m: 0, p: 0 }}
                        >
                           <ArrowForwardIosRounded sx={{ bgcolor: 'text.primary', color: 'background.paper' }} />
                        </Button>
                     </div>
                  </div> */}
                     </Box>
                  )}
                  {!uploadingConstisList && constisList && constisList.length === 0 && (
                     <Alert variant="outlined" severity="warning" sx={{ mx: 5, mt: 5 }}>
                        No constituencies available as of now!
                     </Alert>
                  )}
               </div>
            ) : (
               <div>
                  <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                     This legislative body doesnt have a provision for constituencies for public.
                     <br />
                     इस विधायी निकाय में जनता के लिए निर्वाचन क्षेत्रों का प्रावधान नहीं है।
                  </Alert>
               </div>
            )}
            {}
         </div>
      </div>
   )
}

LegisConstis.propTypes = {
   legis: PropTypes.object.isRequired,
}

export default LegisConstis
