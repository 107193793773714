import React from 'react'
import styles from './MainHeader.module.css'
import logo from '../../Images/12345.png'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from 'firebase/auth'
import { auth } from '../../FirebaseConfig.js'
import { selectUserProfile, selectUsers, setUser, setUserProfile } from '../../redux/reducers/users-slice.js'
import { Avatar, Box, Button, Link } from '@mui/material'

function MainHeader() {
   const user = useSelector(selectUsers).currentUser
   const userProfile = useSelector(selectUserProfile)
   console.log('user from main header: ', user)
   console.log('user profile from main header: ', userProfile)
   const navigate = useNavigate()
   const dispatch = useDispatch()

   function handleSignOut() {
      if (confirm('Are you sure you want to log out?')) {
         signOut(auth)
            .then(() => {
               dispatch(setUser(null))
               dispatch(setUserProfile(null))
               navigate('/')
            })
            .catch((error) => {
               // An error happened.
               console.log(error)
            })
      }
   }
   return (
      <div>
         <div className={styles.header}>
            <NavLink style={{ textDecoration: 'none' }} to="/">
               <div className={styles.header__logo}>
                  <img className={styles.logo} src={logo} />
                  {/* <h2 className={styles.header__logoTitle}>Track Democracy</h2> */}
                  <span className={styles.header__logoTitle}>Track Democracy</span>
               </div>
            </NavLink>

            <div className={styles.header__nav}>
               {/* <div className={styles.nav__item}>
                  <span className={styles.nav__itemLineOne}>Your</span>
                  <span className={styles.nav__itemLineTwo}>Shop</span>
               </div> */}
               <Box sx={{ textDecoration: 'none !important', display: 'flex', flexDirection: 'row' }}>
                  <Box>
                     <Avatar
                        alt={userProfile && userProfile.NameFirst ? userProfile.NameFirst : 'Guest'}
                        src={userProfile && userProfile.Image && userProfile.Image.Image}
                     />
                  </Box>
                  <div className={styles.nav__item}>
                     {userProfile ? (
                        <>
                           {/* <span className={styles.nav__itemLineTwo_Two}>Hello</span> */}
                           <span
                              className={styles.nav__itemLineTwo}
                           >{`${userProfile.NameFirst} ${userProfile.NameMiddle} ${userProfile.NameLast}`}</span>
                        </>
                     ) : (
                        <span className={styles.nav__itemLineTwo_Two}>Hello Guest</span>
                     )}
                     {/* {user.currentUser ? ( */}

                     {/* ) : (
                        <span className={styles.nav__itemLineOne}>Hello Guest</span>
                     )} */}
                     {/* <span className={styles.nav__itemLineOne}>Hello Guest</span> */}
                     {/* <span className={styles.nav__itemLineTwo}>Sign In</span> */}
                     {user ? (
                        <Box>
                           <Button
                              variant="contained"
                              sx={{ py: 0, px: 1, mt: 0.5, fontSize: 11 }}
                              className={styles.nav__itemLineTwo_Three}
                              onClick={() => handleSignOut('login')}
                           >
                              Log Out
                           </Button>
                           <Link href={`/my-dashboard`}>
                              <Button
                                 variant="contained"
                                 sx={{ py: 0, px: 1, mt: 0.5, ml: 0.5, fontSize: 11 }}
                                 className={styles.nav__itemLineTwo_Three}
                              >
                                 My dashboard
                              </Button>
                           </Link>
                        </Box>
                     ) : (
                        <NavLink className={styles.nav__itemLineTwo} to="/login">
                           Sign In
                        </NavLink>
                     )}
                     {/* )} */}
                  </div>
               </Box>

               {/* <div style={{ textDecoration: 'none' }}>
                  <div className={styles.nav__itemBasket}>
                     <CartButton />
                     <span className={(styles.nav__itemLineTwo, styles.nav__basketCount)}>xxx</span>
                  </div>
               </div> */}
            </div>
         </div>
         <div className={styles.subHeader}>
            <span className={styles.subHeaderText}>... participate in democratic governance</span>
         </div>
      </div>
   )
}

export default MainHeader
