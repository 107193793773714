import { Alert, AlertTitle, Box } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

function ElecFilters({ legis, elec }) {
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Filtered results of ${elec.Name}`}</title>
            </Helmet>
         </div>
         {elec.IsSpeculated && (
            <Box>
               <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                  <strong>
                     <u>Disclaimer:</u>
                  </strong>{' '}
                  The <strong>dates</strong> of this election <strong>are speculated</strong> for the purpose of
                  display. Please wait for the actual announcement by the Election Commission of India.
                  <br />
                  <br />
                  <strong>
                     <u>अस्वीकरण:</u>
                  </strong>{' '}
                  इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से <strong>अनुमान</strong> हैं। कृपया भारत
                  के चुनाव आयोग द्वारा वास्तविक घोषणा की प्रतीक्षा करें।
               </Alert>
            </Box>
         )}
         {!elec.IsSpeculated && (
            <Box>
               <Box
                  sx={{
                     p: 1,
                     mt: 0.5,
                     backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                     color: '#ffffff',
                  }}
               >
                  <Box>Filtered data:</Box>
                  <Box>निस्यंदित आंकड़े:</Box>
               </Box>
               <Box sx={{ mt: 10, mx: 10 }}>
                  <Alert
                     variant="outlined"
                     severity="success"
                     sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                  >
                     <AlertTitle sx={{ fontSize: 12 }}>
                        This facility will be available soon. <br /> यह सुविधा शीघ्र ही उपलब्ध होगी|
                     </AlertTitle>
                  </Alert>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecFilters.propTypes = {
   elec: PropTypes.object.isRequired,
   legis: PropTypes.object.isRequired,
}

export default ElecFilters
