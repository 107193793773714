import { Alert, Box } from '@mui/material'
import React from 'react'

function ElectionsSU() {
   return (
      <Box>
         <Alert
            variant="outlined"
            severity="success"
            sx={{ mx: 5, mt: 5, justifyContent: 'center', alignItems: 'center' }}
         >
            This facility will be available soon!
            <br />
            यह सुविधा जल्द ही उपलब्ध होगी!
         </Alert>
      </Box>
   )
}

export default ElectionsSU
