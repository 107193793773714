import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './ElecCandis.module.css'
import {
   Alert,
   AlertTitle,
   Avatar,
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   Divider,
   FormControl,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Modal,
   Select,
   Slide,
   Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'

import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
// import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import HowToVoteIcon from '@mui/icons-material/HowToVote'
import { v4 } from 'uuid'

import { useDispatch, useSelector } from 'react-redux'
import { selectUserProfile, selectUsers, setUserProfile } from '../../../redux/reducers/users-slice'
import winner from '../../../Images/winner.png'

import { useNavigate } from 'react-router-dom'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import {
   addDoc,
   arrayRemove,
   arrayUnion,
   collection,
   deleteDoc,
   doc,
   getDoc,
   getDocs,
   increment,
   query,
   setDoc,
   updateDoc,
   where,
} from 'firebase/firestore'

import AddCandis from './AddCandis'
import moment from 'moment'
import {
   stringAvatar80,
   stringAvatar80Single,
   styleModalBox,
   styleModalTypography,
} from '../../../Utils/MUITheme/MUITheme'
import { countWordsUsingReplace, sentenceCase } from '../../../Utils/Conversions/CaseConversion'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import AddCandiStatus from './AddCandiStatus'
import AddCandiOnlineSupport from './AddCandiOnlineSupport'
import AddWinner from './AddWinner'
import AddNumber from '../../../Components/Edits/AddNumber'
import NOTA from '../../../Images/NOTA.png'
import { Helmet } from 'react-helmet'
import ElecCandiConstiTransfer from './ElecCandiConstiTransfer'
import { selectPParties } from '../../../redux/reducers/pParties-slice'

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />
})

function ElecCandis({ legis, elec, constituencyId, candidatesListRoot, handleCandidatesListRootUpdate }) {
   console.log('value from props from inside Elec Candis page: ', elec)

   const user = useSelector(selectUsers)
   const ip = user.ip
   const dispatch = useDispatch()
   const pParties = useSelector(selectPParties)

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('ElecCandis - first use effect entered')

      if (user.currentUser !== null) {
         console.log('ElecCandis - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('ElecCandis - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const userProfile = useSelector(selectUserProfile)
   console.log('user profile: ', userProfile)

   useEffect(() => {
      if (userProfile && userProfile !== '') {
         console.log('userProfile use state: ', userProfile)
         switch (legis.Strata) {
            case 'Central':
               getLastSupportCenter()
               break
            case 'State':
               getLastSupportState()
               break
            case 'Division':
               //
               break
            case 'District':
               //
               break
            case 'SubDistrict':
               //
               break
            case 'Block':
               //
               break
            case 'Municipality':
               //
               break
            case 'Village':
               //
               break
         }
      }
   }, [userProfile])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [constisList, setConstisList] = useState([])
   const [selectedConsti, setSelectedConsti] = useState('')
   const [selectedConstiName, setSelectedConstiName] = useState('')
   const [selectedConstiNameHindi, setSelectedConstiNameHindi] = useState('')
   const [selectedConstiNameLocal, setSelectedConstiNameLocal] = useState('')
   const [selectedConstiNumber, setSelectedConstiNumber] = useState('')
   const [selectedConstiPhaseNum, setSelectedConstiPhaseNum] = useState('')
   const [selectedConstiPhaseDate, setSelectedConstiPhaseDate] = useState('')
   const [selectedConstiSeatType, setSelectedConstiSeatType] = useState('')
   const [selectedConstiStateName, setSelectedConstiStateName] = useState('')
   const [selectedConstiStateId, setSelectedConstiStateId] = useState('')
   const [selectedConstiParentConstiId, setSelectedConstiParentConstiId] = useState('')
   const [selectedConstiParentConstiName, setSelectedConstiParentConstiName] = useState('')

   const [candidatesListRootLocal, setCandidatesListRootLocal] = useState([])
   const [candidatesListRejected, setCandidatesListRejected] = useState([])
   const [candidatesListWithdrawn, setCandidatesListWithdrawn] = useState([])
   const [candidatesListAnnounceCancelled, setCandidatesListAnnounceCancelled] = useState([])
   const [candidatesListWaiting, setCandidatesListWaiting] = useState([])
   const [candidatesListAccepted, setCandidatesListAccepted] = useState([])
   const [partiesList, setPartiesList] = useState([])

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         setPartiesList(pParties.pPartiesList)

         setCandidatesListRootLocal(candidatesListRoot)

         const sortedConstisList = [...elec.Constituencies].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setConstisList(sortedConstisList)

         setFirstLoadStatus('success')
         console.log('ElecCandis - first use effect blank')
      }
   }, [])

   useEffect(() => {
      if (constisList && constisList.length > 0) {
         setSelectedConsti(constituencyId)
      } else {
         console.log('ElecCandis - use effect of candis list root: no list')
      }
   }, [constisList])

   const handleChangeConsti = async (e) => {
      e.preventDefault()

      setSelectedConsti(e.target.value)
      console.log('ElecCandis - handle change constis')
   }

   useEffect(() => {
      console.log('ElecCandis - use effect of selected consti - start - out')
      if (selectedConsti && selectedConsti !== '') {
         console.log('ElecCandis - use effect of selected consti - start - in - 1', selectedConsti)
         const consti = elec.Constituencies.find((item) => item.Constituency_id === selectedConsti)

         setSelectedConstiName(consti.Name)
         setSelectedConstiNameHindi(consti.NameHindi)
         setSelectedConstiNameLocal(consti.NameLocal)
         setSelectedConstiNumber(consti.ConstituencyNumber)
         setSelectedConstiPhaseNum(consti.PhaseNum)
         setSelectedConstiSeatType(consti.SeatType)
         setSelectedConstiStateName(consti.StateName)
         setSelectedConstiStateId(consti.State_id)
         setSelectedConstiParentConstiId(consti.ParentConsti_id)
         setSelectedConstiParentConstiName(consti.ParentConstiName)

         const phase = elec.Phases.find((item) => item.PhaseNum === consti.PhaseNum)
         setSelectedConstiPhaseDate(phase.DateOfPoll)

         const list = candidatesListRootLocal.filter((item) => item.Constituency_id === selectedConsti)
         const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')
         const listRejected = list.filter((item) => item.Rejected === 'Rejected')
         const listWithdrawn = list.filter((item) => item.Withdrawn === 'Withdrawn')
         // const listAccepted = list.filter((item) => item.Accepted === 'Accepted')
         const listWaiting = list.filter(
            (item) => item.Accepted !== 'Accepted' && item.Rejected !== 'Rejected' && item.Withdrawn !== 'Withdrawn',
         )

         console.log('list: ', list)

         let sortedListWaiting = []
         sortedListWaiting = [...listWaiting].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setCandidatesListWaiting(sortedListWaiting)

         let sortedListAccepted = []
         if (!elec.ResultsDeclared) {
            sortedListAccepted = [...listAccepted].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            console.log('ElecCandis - use effect of selected consti - start - in - 3')
         } else {
            sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
         }

         setCandidatesListAccepted(sortedListAccepted)
         console.log('ElecCandis - use effect of selected consti - end: sortedListAccepted: ', sortedListAccepted)

         let sortedListRejected = []
         sortedListRejected = [...listRejected].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setCandidatesListRejected(sortedListRejected)

         let sortedListWithdrawn = []
         sortedListWithdrawn = [...listWithdrawn].sort((a, b) => {
            if (b.Name.trim() > a.Name.trim()) {
               return -1
            } else if (b.Name.trim() < a.Name.trim()) {
               return 1
            } else {
               return 0
            }
         })
         setCandidatesListWithdrawn(sortedListWithdrawn)
      } else {
         setCandidatesListWaiting([])
         setCandidatesListAccepted([])
         setCandidatesListRejected([])
         setCandidatesListWithdrawn([])
      }
   }, [selectedConsti])

   // related to form filling and submission of candis

   const [openModalAddCandis, setOpenModalAddCandis] = useState(false)

   const handleOpenModalAddCandis = async (e) => {
      e.preventDefault()

      setOpenModalAddCandis(true)
   }

   const handleCloseModalAddCandis = () => setOpenModalAddCandis(false)

   const [lastCandi, setLastCandi] = useState('')
   const [uploadingCandis, setUploadingCandis] = useState(false)
   const [uploadedCandis, setUploadedCandis] = useState(false)
   const [uploadingFailedCandis, setUploadingFailedCandis] = useState(false)

   const handleAddCandis = async (candiDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (candiDetails.Name.length > 3 && !isNaN(candiDetails.Age)) {
               setUploadingCandis(true)

               candiDetails.CrBy = auth.currentUser.uid
               candiDetails.CrDt = new Date()
               candiDetails.CrIP = ip.data.ip

               candiDetails.Country_id = legis.Country_id
               candiDetails.CountryName = legis.CountryName
               candiDetails.State_id = legis.State_id
               candiDetails.StateName = legis.StateName
               candiDetails.Division_id = legis.Division_id
               candiDetails.DivisionName = legis.DivisionName
               candiDetails.District_id = legis.District_id
               candiDetails.DistrictName = legis.DistrictName
               candiDetails.SubDistrict_id = legis.SubDistrict_id
               candiDetails.SubDistrictName = legis.SubDistrictName
               candiDetails.Block_id = legis.Block_id
               candiDetails.BlockName = legis.BlockName
               candiDetails.Village_id = legis.Village_id
               candiDetails.VillageName = legis.VillageName

               candiDetails.Name = sentenceCase(candiDetails.Name).trim()

               candiDetails.NameOld = [
                  {
                     Name: candiDetails.Name,
                     CrBy: auth.currentUser.uid,
                     CrDt: new Date(),
                     CrIP: ip.data.ip,
                  },
               ]
               if (candiDetails.NameHindi !== '') {
                  candiDetails.NameHindiOld = [
                     { Name: candiDetails.NameHindi, CrBy: auth.currentUser.uid, CrDt: new Date(), CrIP: ip.data.ip },
                  ]
               }
               if (candiDetails.NameLocal !== '') {
                  candiDetails.NameLocalOld = [
                     {
                        Name: candiDetails.NameLocal,
                        CrBy: auth.currentUser.uid,
                        CrDt: new Date(),
                        CrIP: ip.data.ip,
                     },
                  ]
               }
               candiDetails.DocType = 'Candidate'
               candiDetails.Legislature_id = legis.id
               candiDetails.LegislatureName = legis.Name
               candiDetails.LegislatureNameHindi = legis.NameHindi
               candiDetails.LegislatureNameLocal = legis.NameLocal

               candiDetails.Election_id = elec.id
               candiDetails.ElectionName = elec.Name
               candiDetails.ElectionNameHindi = elec.NameHindi
               candiDetails.ElectionNameLocal = elec.NameLocal

               candiDetails.PhaseNum = selectedConstiPhaseNum
               candiDetails.Constituency_id = selectedConsti
               candiDetails.ConstituencyName = selectedConstiName
               candiDetails.ConstituencyNameHindi = selectedConstiNameHindi
               candiDetails.ConstituencyNameLocal = selectedConstiNameLocal
               candiDetails.ConstituencyNumber = selectedConstiNumber
               candiDetails.SeatType = selectedConstiSeatType

               candiDetails.OnlineSupport = 0
               candiDetails.OnlineSwingSupport = 0
               candiDetails.ExitSupport = 0

               const queryAlliance = query(
                  collection(db, 'PPAlliances'),
                  where('Party_id_s', 'array-contains', candiDetails.Party_id),
               )
               const querySnapshotAlliance = await getDocs(queryAlliance)
               querySnapshotAlliance.forEach(async (docSnap) => {
                  candiDetails.Alliances.push({
                     Alliance_id: docSnap.id,
                     Name: docSnap.data().Name,
                     Abbreviation: docSnap.data().Abbreviation,
                  })
                  candiDetails.AlliancesForMainDoc.push({
                     Alliance_id: docSnap.id,
                     Name: docSnap.data().Name,
                     Abbreviation: docSnap.data().Abbreviation,
                     Parties: docSnap.data().Parties,
                  })
               })

               console.log('candiDetails 1: ', candiDetails)

               setLastCandi(candiDetails.Name)
               console.log('candiDetails 2: ', candiDetails, ' uploading: ', uploadingCandis)

               let coreListId = ''
               let candidatesCount = 0

               // we are fetching docElecFresh so that we get the real count of candidates just before the entry of new candidate
               const docRefElecFresh = doc(db, 'Elections', elec.id)
               const docSnapElecFresh = await getDoc(docRefElecFresh)
               candidatesCount = docSnapElecFresh.data().CandidatesCount

               console.log('local Candidates Count before core list: ', candidatesCount)

               const slab = 500
               const quotient = Math.floor(candidatesCount / slab)
               // const remainder = candidatesCount % slab
               const num = quotient + 1
               const numStr = String(num).padStart(4, '0')

               // coreListId = 'CandiCoreList-' + numStr
               coreListId = 'CandiCoreList-' + numStr

               candiDetails.CoreListId = coreListId
               console.log('coreListId: ', coreListId)

               const record = {
                  Candidate_id: '',
                  Name: candiDetails.Name,
                  NameHindi: candiDetails.NameHindi,
                  NameLocal: candiDetails.NameLocal,
                  PhaseNum: selectedConstiPhaseNum,
                  Constituency_id: selectedConsti,
                  ConstituencyName: selectedConstiName,
                  ConstituencyNameHindi: selectedConstiNameHindi,
                  ConstituencyNameLocal: selectedConstiNameLocal,
                  Age: candiDetails.Age,
                  DoB: candiDetails.DoB,
                  Gender: candiDetails.Gender,
                  Party_id: candiDetails.Party_id,
                  PartyName: candiDetails.PartyName,
                  PartyNameHindi: candiDetails.PartyNameHindi,
                  PartyNameLocal: candiDetails.PartyNameLocal,
                  PartyAbbreviation: candiDetails.PartyAbbreviation,
                  PartyFlag: candiDetails.PartyFlag,
                  PartySymbol: candiDetails.PartySymbol,
                  AllottedSymbol: candiDetails.AllottedSymbol,
                  Alliances: candiDetails.Alliances,
                  Image: '',
                  CoreListId: coreListId,
                  Announced: candiDetails.Announced,
                  AnnouncedDate: candiDetails.AnnouncedDate,
                  AnnounceCancelled: candiDetails.AnnounceCancelled,
                  AnnounceCancelledDate: candiDetails.AnnounceCancelledDate,
                  Filed: candiDetails.Filed,
                  FiledDate: candiDetails.FiledDate,
                  Accepted: candiDetails.Accepted,
                  AcceptedDate: candiDetails.AcceptedDate,
                  Rejected: candiDetails.Rejected,
                  RejectedDate: candiDetails.RejectedDate,
                  Withdrawn: candiDetails.Withdrawn,
                  WithdrawnDate: candiDetails.WithdrawnDate,
                  Votes: candiDetails.Votes,
                  Winner: candiDetails.Winner,

                  OnlineSupport: 0,
                  OnlineSwingSupport: 0,
                  ExitSupport: 0,
               }

               try {
                  let recordNew = {}

                  const docRefSubColElec = doc(db, 'Elections', elec.id, 'CoreLists', coreListId)
                  const docSnapSubColElec = await getDoc(docRefSubColElec)

                  // if (!elec.CandidatesCoreLists.includes(coreListId)) {
                  //    //
                  //    console.log('core list from local state of election before creation: ', elec.CandidatesCoreLists)

                  //    const docRef = doc(db, 'ElectionCandidates', coreListId)

                  //    setDoc(docRef, { Candidates: [], DocType: 'Core List', Election_id: elec.id }, { merge: true })

                  //    console.log('core list created: ', coreListId)
                  //    elec.CandidatesCoreLists.push(coreListId)
                  //    console.log('core list from local state of election after creation: ', elec.CandidatesCoreLists)
                  //    //
                  // }
                  if (candiDetails.Image !== '') {
                     const imageRef = ref(dbStorage, `ElectionCandidates/${elec.id}/${candiDetails.Image.name + v4()}`)
                     await uploadBytes(imageRef, candiDetails.Image).then((snapshot) => {
                        getDownloadURL(snapshot.ref).then(async (url) => {
                           candiDetails = {
                              ...candiDetails,
                              Image: url,
                              ImagesOld: [
                                 {
                                    Image: url,
                                    CrBy: auth.currentUser.uid,
                                    CrDt: new Date(),
                                    CrIP: ip.data.ip,
                                 },
                              ],
                           }

                           // create the main doc
                           const docRef = await addDoc(collection(db, 'ElectionCandidates'), candiDetails)
                           candiDetails.id = docRef.id

                           recordNew = { ...record, Candidate_id: docRef.id, Image: url }
                           console.log('record new after iamge upload: ', recordNew)

                           // const docRefUpdate = doc(db, 'ElectionCandidates', coreListId)
                           // console.log('core list update stage 1')
                           // await updateDoc(docRefUpdate, {
                           //    Candidates: arrayUnion(recordNew),
                           // })

                           if (docSnapSubColElec.exists()) {
                              // means at least one record has been inserted erliear for this subcollection
                              // update subcollection 'CoreLists' of legislature with new values
                              await updateDoc(docRefSubColElec, {
                                 Candidates: arrayUnion(recordNew),
                                 DocType: 'CandidatesCoreList',
                              })
                           } else {
                              // means no record has been inserted for this subcollection
                              // create the subcollection CoreLists with new values
                              setDoc(
                                 docRefSubColElec,
                                 { Candidates: [recordNew], DocType: 'CandidatesCoreList' },
                                 { merge: true },
                              )
                           }

                           setCandidatesListWaiting([...candidatesListWaiting, recordNew])
                           setCandidatesListRootLocal([...candidatesListRootLocal, recordNew])
                           handleCandidatesListRootUpdate([...candidatesListRootLocal, recordNew])
                           setLastCandi(candiDetails.Name)
                        })
                     })
                  } else {
                     // create the main doc
                     const docRef = await addDoc(collection(db, 'ElectionCandidates'), candiDetails)
                     candiDetails.id = docRef.id

                     recordNew = { ...record, Candidate_id: docRef.id }
                     console.log('record new without iamge upload: ', recordNew)

                     // const docRefUpdate = doc(db, 'ElectionCandidates', coreListId)
                     // console.log('core list update stage 2')
                     // await updateDoc(docRefUpdate, {
                     //    Candidates: arrayUnion(recordNew),
                     // })

                     if (docSnapSubColElec.exists()) {
                        // means at least one record has been inserted erliear for this subcollection
                        // update subcollection 'CoreLists' of legislature with new values
                        await updateDoc(docRefSubColElec, {
                           Candidates: arrayUnion(recordNew),
                           DocType: 'CandidatesCoreList',
                        })
                     } else {
                        // means no record has been inserted for this subcollection
                        // create the subcollection CoreLists with new values
                        setDoc(
                           docRefSubColElec,
                           { Candidates: [recordNew], DocType: 'CandidatesCoreList' },
                           { merge: true },
                        )
                     }

                     setCandidatesListWaiting([...candidatesListWaiting, recordNew])
                     setCandidatesListRootLocal([...candidatesListRootLocal, recordNew])
                     handleCandidatesListRootUpdate([...candidatesListRootLocal, recordNew])
                     setLastCandi(candiDetails.Name)
                  }

                  // Note:
                  // increment the CandidatesCount and CandidatesCoreLists in Elections
                  const docRefUpdateElection = doc(db, 'Elections', elec.id)
                  await updateDoc(docRefUpdateElection, {
                     CandidatesCount: increment(1),
                  })

                  // Note:
                  // increment the CandidatesCount in local state
                  // elec.CandidatesCount = elec.CandidatesCount + 1

                  // console.log('local CandidatesCount after increment ', elec.CandidatesCount)

                  setUploadingCandis(false)
                  setUploadedCandis(true)
                  setUploadingFailedCandis(false)
               } catch (error) {
                  alert('Error adding candidate: ', error.message)
                  console.log('Error adding candidate: ', error)
                  setUploadingCandis(false)
                  setUploadedCandis(false)
                  setUploadingFailedCandis(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (
      e,
      thisCandidateId,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisPhaseNum,
      thisConstituencyId,
      thisConstituencyName,
      thisConstituencyNameHindi,
      thisConstituencyNameLocal,
      thisAge,
      thisDoB,
      thisGender,
      thisPartyId,
      thisPartyName,
      thisPartyNameHindi,
      thisPartyNameLocal,
      thisPartyAbbreviation,
      thisPartyFlag,
      thisPartySymbol,
      thisAllottedSymbol,
      thisAlliances,
      thisImage,
      thisCoreListId,
      thisAnnounced,
      thisAnnouncedDate,
      thisAnnounceCancelled,
      thisAnnounceCancelledDate,
      thisFiled,
      thisFiledDate,
      thisAccepted,
      thisAcceptedDate,
      thisRejected,
      thisRejectedDate,
      thisWithdrawn,
      thisWithdrawnDate,
      thisVotes,
      thisWinner,
      thisOnlineSupport,
      thisOnlineSwingSupport,
      thisExitSupport,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this candidate?')) {
               try {
                  // 1. Delete the main doc
                  await deleteDoc(doc(db, 'ElectionCandidates', thisCandidateId))

                  // create record to delete in sub collection
                  const record = {
                     Candidate_id: thisCandidateId,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,
                     PhaseNum: thisPhaseNum,
                     Constituency_id: thisConstituencyId,
                     ConstituencyName: thisConstituencyName,
                     ConstituencyNameHindi: thisConstituencyNameHindi,
                     ConstituencyNameLocal: thisConstituencyNameLocal,
                     Age: thisAge,
                     DoB: thisDoB,
                     Gender: thisGender,
                     Party_id: thisPartyId,
                     PartyName: thisPartyName,
                     PartyNameHindi: thisPartyNameHindi,
                     PartyNameLocal: thisPartyNameLocal,
                     PartyAbbreviation: thisPartyAbbreviation,
                     PartyFlag: thisPartyFlag,
                     PartySymbol: thisPartySymbol,
                     AllottedSymbol: thisAllottedSymbol,
                     Alliances: thisAlliances,
                     Image: thisImage,
                     CoreListId: thisCoreListId,
                     Announced: thisAnnounced,
                     AnnouncedDate: thisAnnouncedDate,
                     AnnounceCancelled: thisAnnounceCancelled,
                     AnnounceCancelledDate: thisAnnounceCancelledDate,
                     Filed: thisFiled,
                     FiledDate: thisFiledDate,
                     Accepted: thisAccepted,
                     AcceptedDate: thisAcceptedDate,
                     Rejected: thisRejected,
                     RejectedDate: thisRejectedDate,
                     Withdrawn: thisWithdrawn,
                     WithdrawnDate: thisWithdrawnDate,
                     Votes: thisVotes,
                     Winner: thisWinner,
                     OnlineSupport: thisOnlineSupport,
                     OnlineSwingSupport: thisOnlineSwingSupport,
                     ExitSupport: thisExitSupport,
                  }
                  console.log('value to remove: ', record)
                  // 1. Get reference of subcollection
                  const docRefSubColElec = doc(db, 'Elections', elec.id, 'CoreLists', thisCoreListId)
                  // 2. Delete from sub collection
                  await updateDoc(docRefSubColElec, {
                     Candidates: arrayRemove(record),
                  })
                  // 3. Decrement the count in Elections
                  const docRefUpdateElection = doc(db, 'Elections', elec.id)
                  await updateDoc(docRefUpdateElection, {
                     CandidatesCount: increment(-1),
                  })

                  setCandidatesListWaiting(
                     candidatesListWaiting.filter((item) => item.Candidate_id !== thisCandidateId),
                  )
                  setCandidatesListAccepted(
                     candidatesListAccepted.filter((item) => item.Candidate_id !== thisCandidateId),
                  )
                  setCandidatesListRejected(
                     candidatesListRejected.filter((item) => item.Candidate_id !== thisCandidateId),
                  )
                  setCandidatesListWithdrawn(
                     candidatesListWithdrawn.filter((item) => item.Candidate_id !== thisCandidateId),
                  )
                  setCandidatesListRootLocal(
                     candidatesListRootLocal.filter((item) => item.Candidate_id !== thisCandidateId),
                  )
                  handleCandidatesListRootUpdate(
                     candidatesListRootLocal.filter((item) => item.Candidate_id !== thisCandidateId),
                  )
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // status of candidates

   const [openModalStatus, setOpenModalStatus] = useState(false)
   const [uploadingStatus, setUploadingStatus] = useState(false)
   const [uploadedStatus, setUploadedStatus] = useState(false)
   const [uploadingFailedStatus, setUploadingFailedStatus] = useState(false)
   const [selectedCandidate, setSelectedCandidate] = useState('')

   const handleOpenModalStatus = async (
      e,
      thisCandidateId,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisPhaseNum,
      thisConstituencyId,
      thisConstituencyName,
      thisConstituencyNameHindi,
      thisConstituencyNameLocal,
      thisAge,
      thisDoB,
      thisGender,
      thisPartyId,
      thisPartyName,
      thisPartyNameHindi,
      thisPartyNameLocal,
      thisPartyAbbreviation,
      thisPartyFlag,
      thisPartySymbol,
      thisAllottedSymbol,
      thisAlliances,
      thisImage,
      thisCoreListId,
      thisAnnounced,
      thisAnnouncedDate,
      thisAnnounceCancelled,
      thisAnnounceCancelledDate,
      thisFiled,
      thisFiledDate,
      thisAccepted,
      thisAcceptedDate,
      thisRejected,
      thisRejectedDate,
      thisWithdrawn,
      thisWithdrawnDate,
      thisVotes,
      thisWinner,
      thisOnlineSupport,
      thisOnlineSwingSupport,
      thisExitSupport,
   ) => {
      e.preventDefault()

      setSelectedCandidate({
         Candidate_id: thisCandidateId,
         Name: thisName,
         NameHindi: thisNameHindi,
         NameLocal: thisNameLocal,
         PhaseNum: thisPhaseNum,
         Constituency_id: thisConstituencyId,
         ConstituencyName: thisConstituencyName,
         ConstituencyNameHindi: thisConstituencyNameHindi,
         ConstituencyNameLocal: thisConstituencyNameLocal,
         Age: thisAge,
         DoB: thisDoB,
         Gender: thisGender,
         Party_id: thisPartyId,
         PartyName: thisPartyName,
         PartyNameHindi: thisPartyNameHindi,
         PartyNameLocal: thisPartyNameLocal,
         PartyAbbreviation: thisPartyAbbreviation,
         PartyFlag: thisPartyFlag,
         PartySymbol: thisPartySymbol,
         AllottedSymbol: thisAllottedSymbol,
         Alliances: thisAlliances,
         Image: thisImage,
         CoreListId: thisCoreListId,
         Announced: thisAnnounced,
         AnnouncedDate: thisAnnouncedDate,
         AnnounceCancelled: thisAnnounceCancelled,
         AnnounceCancelledDate: thisAnnounceCancelledDate,
         Filed: thisFiled,
         FiledDate: thisFiledDate,
         Accepted: thisAccepted,
         AcceptedDate: thisAcceptedDate,
         Rejected: thisRejected,
         RejectedDate: thisRejectedDate,
         Withdrawn: thisWithdrawn,
         WithdrawnDate: thisWithdrawnDate,
         Votes: thisVotes,
         Winner: thisWinner,
         OnlineSupport: thisOnlineSupport,
         OnlineSwingSupport: thisOnlineSwingSupport,
         ExitSupport: thisExitSupport,
      })

      setOpenModalStatus(true)
   }

   const handleCloseModalStatus = () => setOpenModalStatus(false)

   const handleAddStatus = async (record, actionIsValid) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (actionIsValid) {
               setUploadingStatus(true)

               record.Candidate_id = selectedCandidate.Candidate_id
               record.Name = selectedCandidate.Name
               record.NameHindi = selectedCandidate.NameHindi
               record.NameLocal = selectedCandidate.NameLocal
               record.PhaseNum = selectedCandidate.PhaseNum
               record.Constituency_id = selectedCandidate.Constituency_id
               record.ConstituencyName = selectedCandidate.ConstituencyName
               record.ConstituencyNameHindi = selectedCandidate.ConstituencyNameHindi
               record.ConstituencyNameLocal = selectedCandidate.ConstituencyNameLocal
               record.Age = selectedCandidate.Age
               record.DoB = selectedCandidate.DoB
               record.Gender = selectedCandidate.Gender
               record.Party_id = selectedCandidate.Party_id
               record.PartyName = selectedCandidate.PartyName
               record.PartyNameHindi = selectedCandidate.PartyNameHindi
               record.PartyNameLocal = selectedCandidate.PartyNameLocal
               record.PartyAbbreviation = selectedCandidate.PartyAbbreviation
               record.PartyFlag = selectedCandidate.PartyFlag
               record.PartySymbol = selectedCandidate.PartySymbol
               record.AllottedSymbol = selectedCandidate.AllottedSymbol
               record.Alliances = selectedCandidate.Alliances
               record.Image = selectedCandidate.Image
               record.CoreListId = selectedCandidate.CoreListId
               // record.Announced = selectedCandidate.Announced
               // record.AnnouncedDate = selectedCandidate.AnnouncedDate
               // record.AnnounceCancelled = selectedCandidate.AnnounceCancelled
               // record.AnnounceCancelledDate = selectedCandidate.AnnounceCancelledDate
               // record.Filed = selectedCandidate.Filed
               // record.FiledDate = selectedCandidate.FiledDate
               // record.Accepted = selectedCandidate.Accepted
               // record.AcceptedDate = selectedCandidate.AcceptedDate
               // record.Rejected = selectedCandidate.Rejected
               // record.RejectedDate = selectedCandidate.RejectedDate
               // record.Withdrawn = selectedCandidate.Withdrawn
               // record.WithdrawnDate = selectedCandidate.WithdrawnDate
               record.Votes = selectedCandidate.Votes
               record.Winner = selectedCandidate.Winner
               record.OnlineSupport = selectedCandidate.OnlineSupport
               record.OnlineSwingSupport = selectedCandidate.OnlineSwingSupport
               record.ExitSupport = selectedCandidate.ExitSupport

               try {
                  console.log('value to add: ', record)

                  const docRefUpdateCandidate = doc(db, 'ElectionCandidates', selectedCandidate.Candidate_id)
                  const docRefUpdateSubColl = doc(db, 'Elections', elec.id, 'CoreLists', selectedCandidate.CoreListId)

                  await updateDoc(docRefUpdateCandidate, {
                     Announced: record.Announced,
                     AnnouncedDate: record.AnnouncedDate,
                     AnnounceCancelled: record.AnnounceCancelled,
                     AnnounceCancelledDate: record.AnnounceCancelledDate,
                     Filed: record.Filed,
                     FiledDate: record.FiledDate,
                     Accepted: record.Accepted,
                     AcceptedDate: record.AcceptedDate,
                     Rejected: record.Rejected,
                     RejectedDate: record.RejectedDate,
                     Withdrawn: record.Withdrawn,
                     WithdrawnDate: record.WithdrawnDate,
                  })

                  await updateDoc(docRefUpdateSubColl, {
                     Candidates: arrayRemove(selectedCandidate),
                  })
                  await updateDoc(docRefUpdateSubColl, {
                     Candidates: arrayUnion(record),
                  })

                  const root = candidatesListRootLocal.filter(
                     (item) => item.Candidate_id !== selectedCandidate.Candidate_id,
                  )
                  console.log('root: ', root)
                  root.push(record)
                  console.log('root 2: ', root)
                  setCandidatesListRootLocal(root)
                  handleCandidatesListRootUpdate(root)

                  const list = root.filter((item) => item.Constituency_id === selectedConsti)

                  const listWaiting = list.filter(
                     (item) =>
                        item.Accepted !== 'Accepted' &&
                        item.Rejected !== 'Rejected' &&
                        item.Withdrawn !== 'Withdrawn' &&
                        item.AnnounceCancelled !== 'AnnounceCancelled',
                  )

                  let sortedListWaiting = []
                  sortedListWaiting = [...listWaiting].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  console.log('sorted list waiting after status update: ', sortedListWaiting)

                  setCandidatesListWaiting(sortedListWaiting)

                  if (record.Accepted === 'Accepted' && record.Withdrawn === '') {
                     const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

                     let sortedListAccepted = []
                     if (!elec.ResultsDeclared) {
                        sortedListAccepted = [...listAccepted].sort((a, b) => {
                           if (b.Name.trim() > a.Name.trim()) {
                              return -1
                           } else if (b.Name.trim() < a.Name.trim()) {
                              return 1
                           } else {
                              return 0
                           }
                        })
                     } else {
                        sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
                     }
                     console.log('sorted list accepted after status update: ', sortedListAccepted)
                     setCandidatesListAccepted(sortedListAccepted)
                  } else if (record.Rejected === 'Rejected') {
                     const listRejected = list.filter((item) => item.Rejected === 'Rejected')

                     let sortedListRejected = []
                     sortedListRejected = [...listRejected].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                     console.log('sorted list rejected after status update: ', sortedListRejected)
                     setCandidatesListRejected(sortedListRejected)
                  } else if (record.Withdrawn === 'Withdrawn') {
                     const listWithdrawn = list.filter((item) => item.Withdrawn === 'Withdrawn')
                     let sortedListWithdrawn = []
                     sortedListWithdrawn = [...listWithdrawn].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                     console.log('sorted list withdrawn after status update: ', sortedListWithdrawn)
                     setCandidatesListWithdrawn(sortedListWithdrawn)
                  } else if (record.AnnounceCancelled === 'AnnounceCancelled') {
                     const listAnnounceCancelled = list.filter((item) => item.AnnounceCancelled === 'AnnounceCancelled')
                     let sortedListAnnounceCancelled = []
                     sortedListAnnounceCancelled = [...listAnnounceCancelled].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                     console.log('sorted list Announce Cancelled after status update: ', sortedListAnnounceCancelled)
                     setCandidatesListAnnounceCancelled(sortedListAnnounceCancelled)
                     console.log(candidatesListAnnounceCancelled)
                  }

                  setUploadingStatus(false)
                  setUploadedStatus(true)
                  setUploadingFailedStatus(false)

                  handleCloseModalStatus()
               } catch (error) {
                  alert(`Error adding candidate's status: `, error.message)
                  console.log(`Error adding candidate's status: `, error)
                  setUploadingStatus(false)
                  setUploadedStatus(false)
                  setUploadingFailedStatus(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // transfer of constituency of candidates

   const [openModalConstiTransfer, setOpenModalConstiTransfer] = useState(false)
   const [uploadingConstiTransfer, setUploadingConstiTransfer] = useState(false)
   const [uploadedConstiTransfer, setUploadedConstiTransfer] = useState(false)
   const [uploadingFailedConstiTransfer, setUploadingFailedConstiTransfer] = useState(false)

   const handleOpenModalConstiTransfer = async (
      e,
      thisCandidateId,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisPhaseNum,
      thisConstituencyId,
      thisConstituencyName,
      thisConstituencyNameHindi,
      thisConstituencyNameLocal,
      thisAge,
      thisDoB,
      thisGender,
      thisPartyId,
      thisPartyName,
      thisPartyNameHindi,
      thisPartyNameLocal,
      thisPartyAbbreviation,
      thisPartyFlag,
      thisPartySymbol,
      thisAllottedSymbol,
      thisAlliances,
      thisImage,
      thisCoreListId,
      thisAnnounced,
      thisAnnouncedDate,
      thisAnnounceCancelled,
      thisAnnounceCancelledDate,
      thisFiled,
      thisFiledDate,
      thisAccepted,
      thisAcceptedDate,
      thisRejected,
      thisRejectedDate,
      thisWithdrawn,
      thisWithdrawnDate,
      thisVotes,
      thisWinner,
      thisOnlineSupport,
      thisOnlineSwingSupport,
      thisExitSupport,
   ) => {
      e.preventDefault()

      setSelectedCandidate({
         Candidate_id: thisCandidateId,
         Name: thisName,
         NameHindi: thisNameHindi,
         NameLocal: thisNameLocal,
         PhaseNum: thisPhaseNum,
         Constituency_id: thisConstituencyId,
         ConstituencyName: thisConstituencyName,
         ConstituencyNameHindi: thisConstituencyNameHindi,
         ConstituencyNameLocal: thisConstituencyNameLocal,
         Age: thisAge,
         DoB: thisDoB,
         Gender: thisGender,
         Party_id: thisPartyId,
         PartyName: thisPartyName,
         PartyNameHindi: thisPartyNameHindi,
         PartyNameLocal: thisPartyNameLocal,
         PartyAbbreviation: thisPartyAbbreviation,
         PartyFlag: thisPartyFlag,
         PartySymbol: thisPartySymbol,
         AllottedSymbol: thisAllottedSymbol,
         Alliances: thisAlliances,
         Image: thisImage,
         CoreListId: thisCoreListId,
         Announced: thisAnnounced,
         AnnouncedDate: thisAnnouncedDate,
         AnnounceCancelled: thisAnnounceCancelled,
         AnnounceCancelledDate: thisAnnounceCancelledDate,
         Filed: thisFiled,
         FiledDate: thisFiledDate,
         Accepted: thisAccepted,
         AcceptedDate: thisAcceptedDate,
         Rejected: thisRejected,
         RejectedDate: thisRejectedDate,
         Withdrawn: thisWithdrawn,
         WithdrawnDate: thisWithdrawnDate,
         Votes: thisVotes,
         Winner: thisWinner,
         OnlineSupport: thisOnlineSupport,
         OnlineSwingSupport: thisOnlineSwingSupport,
         ExitSupport: thisExitSupport,
      })

      setOpenModalConstiTransfer(true)
   }

   const handleCloseModalConstiTransfer = () => setOpenModalConstiTransfer(false)

   const handleConstiTransfer = async (record) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (record.Constituency_id !== '') {
               setUploadingConstiTransfer(true)

               record.Candidate_id = selectedCandidate.Candidate_id
               record.Name = selectedCandidate.Name
               record.NameHindi = selectedCandidate.NameHindi
               record.NameLocal = selectedCandidate.NameLocal
               record.PhaseNum = selectedCandidate.PhaseNum
               // record.Constituency_id = selectedCandidate.Constituency_id
               // record.ConstituencyName = selectedCandidate.ConstituencyName
               // record.ConstituencyNameHindi = selectedCandidate.ConstituencyNameHindi
               // record.ConstituencyNameLocal = selectedCandidate.ConstituencyNameLocal
               record.Age = selectedCandidate.Age
               record.DoB = selectedCandidate.DoB
               record.Gender = selectedCandidate.Gender
               record.Party_id = selectedCandidate.Party_id
               record.PartyName = selectedCandidate.PartyName
               record.PartyNameHindi = selectedCandidate.PartyNameHindi
               record.PartyNameLocal = selectedCandidate.PartyNameLocal
               record.PartyAbbreviation = selectedCandidate.PartyAbbreviation
               record.PartyFlag = selectedCandidate.PartyFlag
               record.PartySymbol = selectedCandidate.PartySymbol
               record.AllottedSymbol = selectedCandidate.AllottedSymbol
               record.Alliances = selectedCandidate.Alliances
               record.Image = selectedCandidate.Image
               record.CoreListId = selectedCandidate.CoreListId
               record.Announced = selectedCandidate.Announced
               record.AnnouncedDate = selectedCandidate.AnnouncedDate
               record.AnnounceCancelled = selectedCandidate.AnnounceCancelled
               record.AnnounceCancelledDate = selectedCandidate.AnnounceCancelledDate
               record.Filed = selectedCandidate.Filed
               record.FiledDate = selectedCandidate.FiledDate
               record.Accepted = selectedCandidate.Accepted
               record.AcceptedDate = selectedCandidate.AcceptedDate
               record.Rejected = selectedCandidate.Rejected
               record.RejectedDate = selectedCandidate.RejectedDate
               record.Withdrawn = selectedCandidate.Withdrawn
               record.WithdrawnDate = selectedCandidate.WithdrawnDate
               record.Votes = selectedCandidate.Votes
               record.Winner = selectedCandidate.Winner
               record.OnlineSupport = selectedCandidate.OnlineSupport
               record.OnlineSwingSupport = selectedCandidate.OnlineSwingSupport
               record.ExitSupport = selectedCandidate.ExitSupport

               try {
                  console.log('value to add: ', record)

                  const docRefUpdateCandidate = doc(db, 'ElectionCandidates', selectedCandidate.Candidate_id)
                  const docRefUpdateSubColl = doc(db, 'Elections', elec.id, 'CoreLists', selectedCandidate.CoreListId)

                  await updateDoc(docRefUpdateCandidate, {
                     Constituency_id: record.Constituency_id,
                     ConstituencyName: record.ConstituencyName,
                     ConstituencyNameHindi: record.ConstituencyNameHindi,
                     ConstituencyNameLocal: record.ConstituencyNameLocal,
                  })

                  await updateDoc(docRefUpdateSubColl, {
                     Candidates: arrayRemove(selectedCandidate),
                  })
                  await updateDoc(docRefUpdateSubColl, {
                     Candidates: arrayUnion(record),
                  })

                  const root = candidatesListRootLocal.filter(
                     (item) => item.Candidate_id !== selectedCandidate.Candidate_id,
                  )
                  console.log('root: ', root)
                  root.push(record)
                  console.log('root 2: ', root)
                  setCandidatesListRootLocal(root)
                  handleCandidatesListRootUpdate(root)

                  const list = root.filter((item) => item.Constituency_id === selectedConsti)

                  const listWaiting = list.filter(
                     (item) =>
                        item.Accepted !== 'Accepted' &&
                        item.Rejected !== 'Rejected' &&
                        item.Withdrawn !== 'Withdrawn' &&
                        item.AnnounceCancelled !== 'AnnounceCancelled',
                  )

                  let sortedListWaiting = []
                  sortedListWaiting = [...listWaiting].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  console.log('sorted list waiting after transfer update: ', sortedListWaiting)

                  setCandidatesListWaiting(sortedListWaiting)

                  const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

                  let sortedListAccepted = []
                  if (!elec.ResultsDeclared) {
                     sortedListAccepted = [...listAccepted].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                  } else {
                     sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
                  }
                  console.log('sorted list accepted after transfer update: ', sortedListAccepted)
                  setCandidatesListAccepted(sortedListAccepted)

                  const listRejected = list.filter((item) => item.Rejected === 'Rejected')

                  let sortedListRejected = []
                  sortedListRejected = [...listRejected].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  console.log('sorted list rejected after transfer update: ', sortedListRejected)
                  setCandidatesListRejected(sortedListRejected)

                  const listWithdrawn = list.filter((item) => item.Withdrawn === 'Withdrawn')
                  let sortedListWithdrawn = []
                  sortedListWithdrawn = [...listWithdrawn].sort((a, b) => {
                     if (b.Name.trim() > a.Name.trim()) {
                        return -1
                     } else if (b.Name.trim() < a.Name.trim()) {
                        return 1
                     } else {
                        return 0
                     }
                  })
                  console.log('sorted list withdrawn after transfer update: ', sortedListWithdrawn)
                  setCandidatesListWithdrawn(sortedListWithdrawn)

                  setUploadingConstiTransfer(false)
                  setUploadedConstiTransfer(true)
                  setUploadingFailedConstiTransfer(false)

                  handleCloseModalConstiTransfer()
               } catch (error) {
                  alert(`Error adding candidate's transfer: `, error.message)
                  console.log(`Error adding candidate's transfer: `, error)
                  setUploadingConstiTransfer(false)
                  setUploadedConstiTransfer(false)
                  setUploadingFailedConstiTransfer(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // online support: related to last support

   const [hoursCenter, setHoursCenter] = useState('')
   // const [lastCandiSupported, setLastCandiSupported] = useState('')
   const [hoursState, setHoursState] = useState('')

   const getLastSupportCenter = async () => {
      console.log('getLastSupportCenter : enetered')

      const lastSupportCenter = userProfile.LS_Candi_CenterConstituency
      console.log('lastSupportCenter: ', lastSupportCenter)

      if (lastSupportCenter) {
         if (lastSupportCenter.Election_id === elec.id) {
            // find the difference in hours
            const lastDateCenter = new Date(lastSupportCenter.CrDt.toDate())
            const date1 = moment(lastDateCenter)
            const date2 = moment(new Date())
            const resHousrs = date2.diff(date1, 'hours')
            setHoursCenter(resHousrs)
            // setLastCandiSupported(lastSupportCenter.Candidate_id)
         } else {
            setHoursCenter('')
            // setLastCandiSupported('')
         }
      } else {
         setHoursCenter('')
         // setLastCandiSupported('')
      }
   }

   useEffect(() => {
      if (hoursCenter !== '') {
         console.log('hours Center: ', hoursCenter)
      }
   }, [hoursCenter])

   const getLastSupportState = async () => {
      console.log('getLastSupportState : enetered')

      const lastSupportState = userProfile.LS_Candi_StateConstituency
      console.log('lastSupportState: ', lastSupportState)

      if (lastSupportState) {
         if (lastSupportState.Election_id === elec.id) {
            // find the difference in hours
            const lastDateState = new Date(lastSupportState.CrDt.toDate())
            const date1 = moment(lastDateState)
            const date2 = moment(new Date())
            const resHousrs = date2.diff(date1, 'hours')
            setHoursState(resHousrs)
            // setLastCandiSupported(lastSupportState.Candidate_id)
         } else {
            setHoursState('')
            // setLastCandiSupported('')
         }
      } else {
         setHoursState('')
         // setLastCandiSupported('')
      }
   }

   useEffect(() => {
      if (hoursState !== '') {
         console.log('hours State: ', hoursState)
      }
   }, [hoursState])

   // online support: opening the modal

   const [openModalOnlineSupport, setOpenModalOnlineSupport] = useState(false)
   const [uploadingOnlineSupport, setUploadingOnlineSupport] = useState(false)
   const [uploadedOnlineSupport, setUploadedOnlineSupport] = useState(false)
   const [uploadingFailedOnlineSupport, setUploadingFailedOnlineSupport] = useState(false)
   const [actionForSupport, setActionForSupport] = useState('')

   const handleOpenModalOnlineSupport = async (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         console.log('reached stage prior to open modal for support - you are logged in.')

         if (new Date().toDateString() < new Date(selectedConstiPhaseDate.toDate()).toDateString()) {
            //
            // console.log(
            //    'today date ',
            //    new Date().toDateString(),
            //    ' is lower than election date ',
            //    new Date(selectedConstiPhaseDate.toDate()).toDateString(),
            // )
            handleOpenDialogDatePassed()
         } else {
            switch (legis.Strata) {
               case 'Central':
                  if (userProfile.AtCentralConstituency === '') {
                     // means he is new and fresh at Central level, so no need of any calculation
                     // proceed to vote and prompt the user at submit that he will be automatically
                     // attached to certain constituencies
                     setActionForSupport('attach and then accept the support')

                     setUploadingOnlineSupport(false)
                     setUploadedOnlineSupport(false)
                     setUploadingFailedOnlineSupport(false)

                     setOpenModalOnlineSupport(true)
                  } else {
                     // means (1) he is NOT fresh at Central level (2) he is already attached to some constituency at Central level
                     // Now, find if he is attached to the selected constituency
                     if (userProfile.AtCentralConstituency.Constitueny_id === selectedConsti) {
                        // means he is attached to this constituency, so proceed further
                        if (hoursCenter !== '') {
                           // // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                           // if (parseInt(hoursCenter) < 24) {
                           //    // prompt the user that (1) he has supported within last 24 hours (2) he can support another one after 24 hours
                           //    setOpenModalOnlineSupport(false)
                           //    handleOpenDialogHours()
                           // } else {
                           //    // he is past 24 hours and now he can support another candidate
                           //    setActionForSupport('accept the support if another candidate')
                           //    setOpenModalOnlineSupport(true)
                           // }
                           handleOpenDialogAlreadySupported()
                        } else {
                           // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                           // proceed to vote and accept the vote
                           setActionForSupport('accept the support')

                           setUploadingOnlineSupport(false)
                           setUploadedOnlineSupport(false)
                           setUploadingFailedOnlineSupport(false)

                           setOpenModalOnlineSupport(true)
                        }
                     } else {
                        // means (1) he is NOT attached to this constituency
                        // means (2) he is trying to support a candidate here
                        // prompt the user that he is attached to some other constituency at this level
                        setActionForSupport('attached to other consti')

                        handleOpenDialogAtOtherConsti()
                     }
                  }
                  break
               case 'State':
                  if (userProfile.AtStateConstituency === '') {
                     // means he is new and fresh at State level, so no need of any calculation
                     // proceed to vote and prompt the user at submit that he will be automatically
                     // attached to certain constituencies
                     setActionForSupport('attach and then accept the support')

                     setUploadingOnlineSupport(false)
                     setUploadedOnlineSupport(false)
                     setUploadingFailedOnlineSupport(false)

                     setOpenModalOnlineSupport(true)
                  } else {
                     // means (1) he is NOT fresh at State level (2) he is already attached to some constituency at State level
                     // Now, find if he is attached to the selected constituency
                     if (userProfile.AtStateConstituency.Constitueny_id === selectedConsti) {
                        // means he is attached to this constituency, so proceed further
                        if (hoursState !== '') {
                           // // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                           // if (parseInt(hoursState) < 24) {
                           //    // prompt the user that (1) he has supported within last 24 hours (2) he can support another one after 24 hours
                           //    setOpenModalOnlineSupport(false)
                           //    handleOpenDialogHours()
                           // } else {
                           //    // he is past 24 hours and now he can support another candidate
                           //    setActionForSupport('accept the support if another candidate')
                           //    setOpenModalOnlineSupport(true)
                           // }
                           handleOpenDialogAlreadySupported()
                        } else {
                           // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                           // proceed to vote and accept the vote
                           setActionForSupport('accept the support')

                           setUploadingOnlineSupport(false)
                           setUploadedOnlineSupport(false)
                           setUploadingFailedOnlineSupport(false)

                           setOpenModalOnlineSupport(true)
                        }
                     } else {
                        // means (1) he is NOT attached to this constituency
                        // means (2) he is trying to support a candidate here
                        // prompt the user that he is attached to some other constituency at this level
                        setActionForSupport('attached to other consti')

                        handleOpenDialogAtOtherConsti()
                     }
                  }
                  break
               case 'Division':
                  //
                  break
               case 'District':
                  //
                  break
               case 'SubDistrict':
                  //
                  break
               case 'Block':
                  //
                  break
               case 'Village':
                  //
                  break
            }
         }
      } else {
         console.log('reached stage prior to open modal for support - You are NOT logged in.')
         handleOpenDialogNotLoggedIn()
      }
   }

   const closeModalOnlineSupport = () => setOpenModalOnlineSupport(false)

   // online support: final record

   const addOnlineSupport = async (onlineSupportDetails) => {
      console.log('reached adding stage of vote.')
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         console.log('reached adding stage of vote - you are logged in.')

         if (onlineSupportDetails.Candidate_id !== '') {
            setUploadingOnlineSupport(true)

            try {
               if (new Date().toDateString() < new Date(selectedConstiPhaseDate.toDate()).toDateString()) {
                  // means the date is over
                  // this condition was already checked befor opening the add modal. So reaching this stage means fraud.
                  navigate('/', { replace: true })
               } else {
                  // means the date is NOT over
                  const docRefUserProfile = doc(db, 'UserProfiles', auth.currentUser.uid)

                  switch (legis.Strata) {
                     case 'Central':
                        if (userProfile.AtCentralConstituency === '') {
                           // 0. The user is NOT YET attached to any constituency at central level
                           // 1. fetch constituency - in this case the present selected consti is applicable
                           // 2. attach the user to constituency

                           await updateDoc(docRefUserProfile, {
                              AtCentralConstituency: {
                                 Constituency_id: selectedConsti,
                                 ConstituencyNumber: selectedConstiNumber,
                                 ConstituencyName: selectedConstiName,

                                 StateName: selectedConstiStateName,
                                 State_id: selectedConstiStateId,
                              },
                           })
                           // 3. record the support vote
                           recordOnlineSupport(onlineSupportDetails, 'positive')
                        } else {
                           if (userProfile.AtCentralConstituency.Constitueny_id === selectedConsti) {
                              // means he is attached to this constituency, so proceed further
                              if (hoursCenter !== '') {
                                 // // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                                 // if (parseInt(hoursCenter) < 24) {
                                 //    // it is less than 24 hrs, how did he reach here?
                                 //    navigate('/', { replace: true })
                                 // } else {
                                 //    // he is past 24 hours and now he can support another candidate
                                 //    // now check if he is again supporting the last supported candidate
                                 //    if (lastCandiSupported === onlineSupportDetails.Candidate_id) {
                                 //       setOpenModalOnlineSupport(false)
                                 //       handleOpenDialogSameCandi()
                                 //    } else {
                                 //       recordOnlineSupport(onlineSupportDetails, 'negative')
                                 //       handleOpenDialogDifferentCandi()
                                 //    }
                                 // }
                                 navigate('/', { replace: true })
                              } else {
                                 // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                                 // proceed to vote and accept the vote
                                 recordOnlineSupport(onlineSupportDetails, 'positive')
                              }
                           } else {
                              // 0. The user is NOT attached to this consti. How did he reach here?
                              // 1. Prompt the user that he is attached to some other constituency at this level.
                              // 2. This condition was already checked befor opening the add modal. So reaching this stage means fraud.
                              navigate('/', { replace: true })
                           }
                        }
                        break
                     case 'State':
                        // 1. fetch constituency
                        // 2. fetch parent constituency
                        // 2. attach the user to constituency and the parent constituency
                        // 3. record the support vote
                        if (userProfile.AtStateConstituency === '') {
                           // 0. The user is not attached to any constituency at state level.
                           // 1. &  2. fetch constituency and parent constituency - in this case the present selected consti is applicable
                           // 3. attach the user to constituency

                           await updateDoc(docRefUserProfile, {
                              AtStateConstituency: {
                                 Constituency_id: selectedConsti,
                                 ConstituencyNumber: selectedConstiNumber,
                                 ConstituencyName: selectedConstiName,
                                 ParentConsti_id: selectedConstiParentConstiId,
                                 ParentConstiName: selectedConstiParentConstiName,

                                 StateName: selectedConstiStateName,
                                 State_id: selectedConstiStateId,
                              },
                           })
                           // 3. record the support vote
                           recordOnlineSupport(onlineSupportDetails, 'positive')
                        } else {
                           if (userProfile.AtStateConstituency.Constitueny_id === selectedConsti) {
                              // means he is attached to this constituency, so proceed further
                              if (hoursState !== '') {
                                 // // means (1) he is attached to this constituency (2) he has already supported a candidate in this election
                                 // if (parseInt(hoursState) < 24) {
                                 //    // it is less than 24 hrs, how did he reach here?
                                 //    navigate('/', { replace: true })
                                 // } else {
                                 //    // he is past 24 hours and now he can support another candidate
                                 //    // now check if he is again supporting the last supported candidate
                                 //    if (lastCandiSupported === onlineSupportDetails.Candidate_id) {
                                 //       setOpenModalOnlineSupport(false)
                                 //       handleOpenDialogSameCandi()
                                 //    } else {
                                 //       recordOnlineSupport(onlineSupportDetails, 'negative')
                                 //       handleOpenDialogDifferentCandi()
                                 //    }
                                 // }
                                 navigate('/', { replace: true })
                              } else {
                                 // means (1) he is attached to this constituency (2) he has NOT yet supported any candidate for this election
                                 // proceed to vote and accept the vote
                                 recordOnlineSupport(onlineSupportDetails, 'positive')
                              }
                           } else {
                              // 0. The user is NOT attached to this consti. How did he reach here?
                              // 1. Prompt the user that he is attached to some other constituency at this level
                              // 2. This condition was already checked befor opening the add modal. So reaching this stage means fraud.
                              navigate('/', { replace: true })
                           }
                        }
                        break
                     case 'Division':
                        //
                        break
                     case 'District':
                        //
                        break
                     case 'SubDistrict':
                        //
                        break
                     case 'Block':
                        //
                        break
                     case 'Village':
                        //
                        break
                  }
               }

               closeModalOnlineSupport()
               handleOpenDialogThanks()

               closeModalOnlineSupport()

               setUploadingOnlineSupport(false)
               setUploadedOnlineSupport(true)
               setUploadingFailedOnlineSupport(false)
            } catch (error) {
               alert(`Error adding public vote: `, error.message)
               console.log(`Error adding public vote: `, error)
               setUploadingOnlineSupport(false)
               setUploadedOnlineSupport(false)
               setUploadingFailedOnlineSupport(true)
            }
         } else {
            alert('Please select a candidate to proceed. \n कृपया आगे बढ़ने के लिए एक प्रत्याशी का चयन करें।')
         }
      } else {
         console.log('reached adding stage of vote - You are NOT logged in.')
         // this condition was already checked befor opening the add modal. So reaching this stage means fraud.
         navigate('/', { replace: true })
      }
   }

   const recordOnlineSupport = async (onlineSupportDetails, type) => {
      const supportObj4Candi = {
         CrBy: auth.currentUser.uid,
         CrDt: new Date(),
         CrIP: ip.data.ip,
      }

      const supportObj4UserProfile = {
         Election_id: elec.id,
         ElectionName: elec.Name,
         Constituency_id: selectedConsti,
         ConstituencyName: selectedConstiName,
         Candidate_id: onlineSupportDetails.Candidate_id,
         CandidateName: onlineSupportDetails.CandidateName,
         PartyName: onlineSupportDetails.PartyName,
         PartyId: onlineSupportDetails.Party_id,
         Alliances: onlineSupportDetails.Alliances,
         CrDt: new Date(),
         CrIP: ip.data.ip,
      }
      //
      const slab = 10000
      const quotient = Math.floor(onlineSupportDetails.OnlineSupport / slab)
      const num = quotient + 1
      const numStr = String(num).padStart(4, '0')

      // update the support count in main candidate doc
      const docRefCandi = doc(db, 'ElectionCandidates', onlineSupportDetails.Candidate_id)
      await updateDoc(docRefCandi, { OnlineSupport: increment(1) })

      // add record to candidate's subcollection named "OnlineSupports"
      const docRefCandiSubCollection = doc(
         db,
         'ElectionCandidates',
         onlineSupportDetails.Candidate_id,
         'OnlineSupports',
         numStr,
      )
      const docSnapCandiSubCollection = await getDoc(docRefCandiSubCollection)

      if (docSnapCandiSubCollection.exists()) {
         await updateDoc(docRefCandiSubCollection, {
            Supports: arrayUnion(supportObj4Candi),
         })
      } else {
         try {
            setDoc(docRefCandiSubCollection, { Supports: [supportObj4Candi] }, { merge: true })
         } catch (error) {
            alert('Error adding support: Please try again')
            console.log('Error adding support: ', error)

            closeModalOnlineSupport()
            return
         }
      }

      // add record to user profile main doc

      // add record to user profile subCollection
      const docRefUserSubCollection = doc(db, 'UserProfiles', userProfile.id, 'CoreLists', 'CandiadtesSupported')
      const docSnapUserSubCollection = await getDoc(docRefUserSubCollection)

      if (docSnapUserSubCollection.exists()) {
         await updateDoc(docRefUserSubCollection, {
            Supports: arrayUnion(supportObj4UserProfile),
         })
      } else {
         setDoc(docRefUserSubCollection, { Supports: [supportObj4UserProfile] }, { merge: true })
      }

      // update the support count in candidates core list inside Elections
      const docRefElectionCandiCoreList = doc(db, 'Elections', elec.id, 'CoreLists', onlineSupportDetails.CoreListId)

      const candiRecord = candidatesListRoot.find((item) => item.Candidate_id === onlineSupportDetails.Candidate_id)
      const candiRecordNew = { ...candiRecord, OnlineSupport: candiRecord.OnlineSupport + 1 }

      await updateDoc(docRefElectionCandiCoreList, {
         Candidates: arrayRemove(candiRecord),
      })
      await updateDoc(docRefElectionCandiCoreList, {
         Candidates: arrayUnion(candiRecordNew),
      })

      // update the support in local state
      candiRecord.OnlineSupport = candiRecord.OnlineSupport + 1
      const candiRecordAccepted = candidatesListAccepted.find(
         (item) => item.Candidate_id === onlineSupportDetails.Candidate_id,
      )
      candiRecordAccepted.OnlineSupport = candiRecordAccepted.OnlineSupport + 1

      // set the state of userProfile afresh
      const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', auth.currentUser.uid))
      const querySnapshot = await getDocs(querryUser)
      let userFetched = ''
      querySnapshot.forEach((docSnap) => {
         userFetched = { id: docSnap.id, ...docSnap.data() }
      })
      dispatch(setUserProfile(userFetched))
   }

   const cancelOnlineSupport = async () => {
      setOpenModalOnlineSupport(false)
   }

   // winner

   const [openModalWinner, setOpenModalWinner] = useState(false)

   const handleOpenModalWinner = async (
      e,
      thisCandidateId,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisPhaseNum,
      thisConstituencyId,
      thisConstituencyName,
      thisConstituencyNameHindi,
      thisConstituencyNameLocal,
      thisAge,
      thisDoB,
      thisGender,
      thisPartyId,
      thisPartyName,
      thisPartyNameHindi,
      thisPartyNameLocal,
      thisPartyAbbreviation,
      thisPartyFlag,
      thisPartySymbol,
      thisAllottedSymbol,
      thisAlliances,
      thisImage,
      thisCoreListId,
      thisAnnounced,
      thisAnnouncedDate,
      thisAnnounceCancelled,
      thisAnnounceCancelledDate,
      thisFiled,
      thisFiledDate,
      thisAccepted,
      thisAcceptedDate,
      thisRejected,
      thisRejectedDate,
      thisWithdrawn,
      thisWithdrawnDate,
      thisVotes,
      thisWinner,
      thisOnlineSupport,
      thisOnlineSwingSupport,
      thisExitSupport,
   ) => {
      e.preventDefault()

      setSelectedCandidate({
         Candidate_id: thisCandidateId,
         Name: thisName,
         NameHindi: thisNameHindi,
         NameLocal: thisNameLocal,
         PhaseNum: thisPhaseNum,
         Constituency_id: thisConstituencyId,
         ConstituencyName: thisConstituencyName,
         ConstituencyNameHindi: thisConstituencyNameHindi,
         ConstituencyNameLocal: thisConstituencyNameLocal,
         Age: thisAge,
         DoB: thisDoB,
         Gender: thisGender,
         Party_id: thisPartyId,
         PartyName: thisPartyName,
         PartyNameHindi: thisPartyNameHindi,
         PartyNameLocal: thisPartyNameLocal,
         PartyAbbreviation: thisPartyAbbreviation,
         PartyFlag: thisPartyFlag,
         PartySymbol: thisPartySymbol,
         AllottedSymbol: thisAllottedSymbol,
         Alliances: thisAlliances,
         Image: thisImage,
         CoreListId: thisCoreListId,
         Announced: thisAnnounced,
         AnnouncedDate: thisAnnouncedDate,
         AnnounceCancelled: thisAnnounceCancelled,
         AnnounceCancelledDate: thisAnnounceCancelledDate,
         Filed: thisFiled,
         FiledDate: thisFiledDate,
         Accepted: thisAccepted,
         AcceptedDate: thisAcceptedDate,
         Rejected: thisRejected,
         RejectedDate: thisRejectedDate,
         Withdrawn: thisWithdrawn,
         WithdrawnDate: thisWithdrawnDate,
         Votes: thisVotes,
         Winner: thisWinner,
         OnlineSupport: thisOnlineSupport,
         OnlineSwingSupport: thisOnlineSwingSupport,
         ExitSupport: thisExitSupport,
      })

      setOpenModalWinner(true)
   }

   const handleCloseModalWinner = () => setOpenModalWinner(false)

   const handleAddWinner = async (record) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (record.Winner !== '') {
               record.Candidate_id = selectedCandidate.Candidate_id
               record.Name = selectedCandidate.Name
               record.NameHindi = selectedCandidate.NameHindi
               record.NameLocal = selectedCandidate.NameLocal
               record.PhaseNum = selectedCandidate.PhaseNum
               record.Constituency_id = selectedCandidate.Constituency_id
               record.ConstituencyName = selectedCandidate.ConstituencyName
               record.ConstituencyNameHindi = selectedCandidate.ConstituencyNameHindi
               record.ConstituencyNameLocal = selectedCandidate.ConstituencyNameLocal
               record.Age = selectedCandidate.Age
               record.DoB = selectedCandidate.DoB
               record.Gender = selectedCandidate.Gender
               record.Party_id = selectedCandidate.Party_id
               record.PartyName = selectedCandidate.PartyName
               record.PartyNameHindi = selectedCandidate.PartyNameHindi
               record.PartyNameLocal = selectedCandidate.PartyNameLocal
               record.PartyAbbreviation = selectedCandidate.PartyAbbreviation
               record.PartyFlag = selectedCandidate.PartyFlag
               record.PartySymbol = selectedCandidate.PartySymbol
               record.AllottedSymbol = selectedCandidate.AllottedSymbol
               record.Alliances = selectedCandidate.Alliances
               record.Image = selectedCandidate.Image
               record.CoreListId = selectedCandidate.CoreListId
               record.Announced = selectedCandidate.Announced
               record.AnnouncedDate = selectedCandidate.AnnouncedDate
               record.AnnounceCancelled = selectedCandidate.AnnounceCancelled
               record.AnnounceCancelledDate = selectedCandidate.AnnounceCancelledDate
               record.Filed = selectedCandidate.Filed
               record.FiledDate = selectedCandidate.FiledDate
               record.Accepted = selectedCandidate.Accepted
               record.AcceptedDate = selectedCandidate.AcceptedDate
               record.Rejected = selectedCandidate.Rejected
               record.RejectedDate = selectedCandidate.RejectedDate
               record.Withdrawn = selectedCandidate.Withdrawn
               record.WithdrawnDate = selectedCandidate.WithdrawnDate
               record.Votes = selectedCandidate.Votes
               // record.Winner = selectedCandidate.Winner
               record.OnlineSupport = selectedCandidate.OnlineSupport
               record.OnlineSwingSupport = selectedCandidate.OnlineSwingSupport
               record.ExitSupport = selectedCandidate.ExitSupport

               try {
                  console.log('value to add: ', record)

                  const docRefUpdateCandidate = doc(db, 'ElectionCandidates', selectedCandidate.Candidate_id)
                  const docRefUpdateSubColl = doc(db, 'Elections', elec.id, 'CoreLists', selectedCandidate.CoreListId)

                  await updateDoc(docRefUpdateCandidate, {
                     Winner: record.Winner,
                  })

                  await updateDoc(docRefUpdateSubColl, {
                     Candidates: arrayRemove(selectedCandidate),
                  })
                  await updateDoc(docRefUpdateSubColl, {
                     Candidates: arrayUnion(record),
                  })

                  const root = candidatesListRootLocal.filter(
                     (item) => item.Candidate_id !== selectedCandidate.Candidate_id,
                  )
                  console.log('root: ', root)
                  root.push(record)
                  console.log('root 2: ', root)
                  setCandidatesListRootLocal(root)
                  handleCandidatesListRootUpdate(root)

                  const list = root.filter((item) => item.Constituency_id === selectedConsti)

                  const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

                  let sortedListAccepted = []
                  if (!elec.ResultsDeclared) {
                     sortedListAccepted = [...listAccepted].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                  } else {
                     sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
                  }
                  console.log('sorted list accepted after status update: ', sortedListAccepted)
                  setCandidatesListAccepted(sortedListAccepted)

                  handleCloseModalWinner()
               } catch (error) {
                  alert(`Error adding candidate's winner: `, error.message)
                  console.log(`Error adding candidate's winner: `, error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // Votes

   const [openModalVotes, setOpenModalVotes] = useState(false)

   const handleOpenModalVotes = async (
      e,
      thisCandidateId,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisPhaseNum,
      thisConstituencyId,
      thisConstituencyName,
      thisConstituencyNameHindi,
      thisConstituencyNameLocal,
      thisAge,
      thisDoB,
      thisGender,
      thisPartyId,
      thisPartyName,
      thisPartyNameHindi,
      thisPartyNameLocal,
      thisPartyAbbreviation,
      thisPartyFlag,
      thisPartySymbol,
      thisAllottedSymbol,
      thisAlliances,
      thisImage,
      thisCoreListId,
      thisAnnounced,
      thisAnnouncedDate,
      thisAnnounceCancelled,
      thisAnnounceCancelledDate,
      thisFiled,
      thisFiledDate,
      thisAccepted,
      thisAcceptedDate,
      thisRejected,
      thisRejectedDate,
      thisWithdrawn,
      thisWithdrawnDate,
      thisVotes,
      thisWinner,
      thisOnlineSupport,
      thisOnlineSwingSupport,
      thisExitSupport,
   ) => {
      e.preventDefault()

      setSelectedCandidate({
         Candidate_id: thisCandidateId,
         Name: thisName,
         NameHindi: thisNameHindi,
         NameLocal: thisNameLocal,
         PhaseNum: thisPhaseNum,
         Constituency_id: thisConstituencyId,
         ConstituencyName: thisConstituencyName,
         ConstituencyNameHindi: thisConstituencyNameHindi,
         ConstituencyNameLocal: thisConstituencyNameLocal,
         Age: thisAge,
         DoB: thisDoB,
         Gender: thisGender,
         Party_id: thisPartyId,
         PartyName: thisPartyName,
         PartyNameHindi: thisPartyNameHindi,
         PartyNameLocal: thisPartyNameLocal,
         PartyAbbreviation: thisPartyAbbreviation,
         PartyFlag: thisPartyFlag,
         PartySymbol: thisPartySymbol,
         AllottedSymbol: thisAllottedSymbol,
         Alliances: thisAlliances,
         Image: thisImage,
         CoreListId: thisCoreListId,
         Announced: thisAnnounced,
         AnnouncedDate: thisAnnouncedDate,
         AnnounceCancelled: thisAnnounceCancelled,
         AnnounceCancelledDate: thisAnnounceCancelledDate,
         Filed: thisFiled,
         FiledDate: thisFiledDate,
         Accepted: thisAccepted,
         AcceptedDate: thisAcceptedDate,
         Rejected: thisRejected,
         RejectedDate: thisRejectedDate,
         Withdrawn: thisWithdrawn,
         WithdrawnDate: thisWithdrawnDate,
         Votes: thisVotes,
         Winner: thisWinner,
         OnlineSupport: thisOnlineSupport,
         OnlineSwingSupport: thisOnlineSwingSupport,
         ExitSupport: thisExitSupport,
      })

      setOpenModalVotes(true)
   }

   const handleCloseModalVotes = () => setOpenModalVotes(false)

   const handleAddVotes = async (numberDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (numberDetails.Number !== '') {
               const record = {
                  Candidate_id: selectedCandidate.Candidate_id,
                  Name: selectedCandidate.Name,
                  NameHindi: selectedCandidate.NameHindi,
                  NameLocal: selectedCandidate.NameLocal,
                  PhaseNum: selectedCandidate.PhaseNum,
                  Constituency_id: selectedCandidate.Constituency_id,
                  ConstituencyName: selectedCandidate.ConstituencyName,
                  ConstituencyNameHindi: selectedCandidate.ConstituencyNameHindi,
                  ConstituencyNameLocal: selectedCandidate.ConstituencyNameLocal,
                  Age: selectedCandidate.Age,
                  DoB: selectedCandidate.DoB,
                  Gender: selectedCandidate.Gender,
                  Party_id: selectedCandidate.Party_id,
                  PartyName: selectedCandidate.PartyName,
                  PartyNameHindi: selectedCandidate.PartyNameHindi,
                  PartyNameLocal: selectedCandidate.PartyNameLocal,
                  PartyAbbreviation: selectedCandidate.PartyAbbreviation,
                  PartyFlag: selectedCandidate.PartyFlag,
                  PartySymbol: selectedCandidate.PartySymbol,
                  AllottedSymbol: selectedCandidate.AllottedSymbol,
                  Alliances: selectedCandidate.Alliances,
                  Image: selectedCandidate.Image,
                  CoreListId: selectedCandidate.CoreListId,
                  Announced: selectedCandidate.Announced,
                  AnnouncedDate: selectedCandidate.AnnouncedDate,
                  AnnounceCancelled: selectedCandidate.AnnounceCancelled,
                  AnnounceCancelledDate: selectedCandidate.AnnounceCancelledDate,
                  Filed: selectedCandidate.Filed,
                  FiledDate: selectedCandidate.FiledDate,
                  Accepted: selectedCandidate.Accepted,
                  AcceptedDate: selectedCandidate.AcceptedDate,
                  Rejected: selectedCandidate.Rejected,
                  RejectedDate: selectedCandidate.RejectedDate,
                  Withdrawn: selectedCandidate.Withdrawn,
                  WithdrawnDate: selectedCandidate.WithdrawnDate,
                  Votes: numberDetails.Number,
                  Winner: selectedCandidate.Winner,
                  OnlineSupport: selectedCandidate.OnlineSupport,
                  OnlineSwingSupport: selectedCandidate.OnlineSwingSupport,
                  ExitSupport: selectedCandidate.ExitSupport,
               }

               try {
                  console.log('value to add: ', record)

                  const docRefUpdateCandidate = doc(db, 'ElectionCandidates', selectedCandidate.Candidate_id)
                  const docRefUpdateSubColl = doc(db, 'Elections', elec.id, 'CoreLists', selectedCandidate.CoreListId)

                  await updateDoc(docRefUpdateCandidate, {
                     Votes: record.Votes,
                  })

                  await updateDoc(docRefUpdateSubColl, {
                     Candidates: arrayRemove(selectedCandidate),
                  })
                  await updateDoc(docRefUpdateSubColl, {
                     Candidates: arrayUnion(record),
                  })

                  const root = candidatesListRootLocal.filter(
                     (item) => item.Candidate_id !== selectedCandidate.Candidate_id,
                  )
                  console.log('root: ', root)
                  root.push(record)
                  console.log('root 2: ', root)
                  setCandidatesListRootLocal(root)
                  handleCandidatesListRootUpdate(root)

                  const list = root.filter((item) => item.Constituency_id === selectedConsti)

                  const listAccepted = list.filter((item) => item.Accepted === 'Accepted' && item.Withdrawn === '')

                  let sortedListAccepted = []
                  if (!elec.ResultsDeclared) {
                     sortedListAccepted = [...listAccepted].sort((a, b) => {
                        if (b.Name.trim() > a.Name.trim()) {
                           return -1
                        } else if (b.Name.trim() < a.Name.trim()) {
                           return 1
                        } else {
                           return 0
                        }
                     })
                  } else {
                     sortedListAccepted = [...listAccepted].sort((a, b) => b.Votes - a.Votes)
                  }
                  console.log('sorted list accepted after status update: ', sortedListAccepted)
                  setCandidatesListAccepted(sortedListAccepted)

                  handleCloseModalVotes()
               } catch (error) {
                  alert(`Error adding public vote: `, error.message)
                  console.log(`Error adding public vote: `, error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // not logged in

   const [openDialogNotLoggedIn, setOpenDialogNotLoggedIn] = useState(false)

   const handleOpenDialogNotLoggedIn = async () => {
      setOpenDialogNotLoggedIn(true)
   }

   const handleCloseDialogNotLoggedIn = () => setOpenDialogNotLoggedIn(false)

   // date passed

   const [openDialogDatePassed, setOpenDialogDatePassed] = useState(false)

   const handleOpenDialogDatePassed = async () => {
      setOpenDialogDatePassed(true)
   }

   const handleCloseDialogDatePassed = () => setOpenDialogDatePassed(false)

   // hours remaining

   // const [openDialogHours, setOpenDialogHours] = useState(false)

   // const handleOpenDialogHours = async () => {
   //    setOpenDialogHours(true)
   // }

   // const handleCloseDialogHours = () => setOpenDialogHours(false)

   // attached to other consti at the same level

   const [openDialogAtOtherConsti, setOpenDialogAtOtherConsti] = useState(false)

   const handleOpenDialogAtOtherConsti = async () => {
      setOpenDialogAtOtherConsti(true)
   }

   const handleCloseDialogAtOtherConsti = () => setOpenDialogAtOtherConsti(false)

   // supporting same candidate

   // const [openDialogSameCandi, setOpenDialogSameCandi] = useState(false)

   // const handleOpenDialogSameCandi = async () => {
   //    setOpenDialogSameCandi(true)
   // }

   // const handleCloseDialogSameCandi = () => setOpenDialogSameCandi(false)

   // supporting different candidate

   // const [openDialogDifferentCandi, setOpenDialogDifferentCandi] = useState(false)

   // const handleOpenDialogDifferentCandi = async () => {
   //    setOpenDialogDifferentCandi(true)
   // }

   // const handleCloseDialogDifferentCandi = () => setOpenDialogDifferentCandi(false)

   // already supported

   const [openDialogAlreadySupported, setOpenDialogAlreadySupported] = useState(false)

   const handleOpenDialogAlreadySupported = async () => {
      setOpenDialogAlreadySupported(true)
   }

   const handleCloseDialogAlreadySupported = () => setOpenDialogAlreadySupported(false)

   // thanks for support

   const [openDialogThanks, setOpenDialogThanks] = useState(false)

   const handleOpenDialogThanks = async () => {
      setOpenDialogThanks(true)
   }

   const handleCloseDialogThanks = () => setOpenDialogThanks(false)

   // send candidate to legislature
   const handleSendToLegis = async (
      e,
      thisCandidateId,
      thisName,
      thisNameHindi,
      thisNameLocal,
      thisConstituencyId,
      thisConstituencyName,
      thisConstituencyNameHindi,
      thisConstituencyNameLocal,
      thisAge,
      thisDoB,
      thisGender,
      thisPartyId,
      thisPartyName,
      thisPartyNameHindi,
      thisPartyNameLocal,
      thisPartyAbbreviation,
      thisPartyFlag,
      thisAlliances,
      thisImage,
      thisVotes,
      thisOnlineSupport,
      thisOnlineSwingSupport,
      thisExitSupport,
   ) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to send this candidate to members list?')) {
               try {
                  const data = {
                     Candidate_id: thisCandidateId,
                     NameAsCandidate: thisName,
                     Name: thisName,
                     NameOld: [],
                     NameHindi: thisNameHindi,
                     NameHindiOld: [],
                     NameLocal: thisNameLocal,
                     NameLocalOld: [],

                     Age: thisAge,
                     DoB: thisDoB,
                     DoBOld: [],
                     Gender: thisGender,
                     GenderOld: [],

                     Constituency_id: thisConstituencyId,
                     ConstituencyName: thisConstituencyName,
                     ConstituencyNameHindi: thisConstituencyNameHindi,
                     ConstituencyNameLocal: thisConstituencyNameLocal,
                     ConstituencyNumber: selectedConstiNumber,
                     SeatType: selectedConstiSeatType,

                     Image: thisImage,

                     Party_id: thisPartyId,
                     PartyName: thisPartyName,
                     PartyNameHindi: thisPartyNameHindi,
                     PartyNameLocal: thisPartyNameLocal,
                     PartyAbbreviation: thisPartyAbbreviation,
                     PartyFlag: thisPartyFlag,
                     Alliances: thisAlliances,
                     AlliancesNow: [],

                     Votes: thisVotes,
                     OnlineSupport: thisOnlineSupport,
                     OnlineSwingSupport: thisOnlineSwingSupport,
                     ExitSupport: thisExitSupport,

                     Election_id: elec.id,
                     ElectionName: elec.Name,
                     ElectionNameHindi: elec.NameHindi,
                     ElectionNameLocal: elec.NameLocal,

                     Legislature_id: elec.Legislature_id,
                     LegislatureName: elec.LegislatureName,
                     LegislatureNameHindi: elec.LegislatureNameHindi,
                     LegislatureNameLocal: elec.LegislatureNameLocal,
                     HouseNumber: elec.HouseNumber,

                     FromDate: elec.DateCompletion,

                     ToDate: '',
                     Oath: '',
                     Resignation: '',
                     Disqualification: '',
                     TermCompletionDate: '',
                     Crux_id: '',
                     Crux_id_Helpers: [],
                     NetaProfile_id: '',

                     Address: [],
                     AddressOld: [],
                     Phones: [],
                     PhonesOld: [],
                     WebLinks: [],
                     WebLinksOld: [],
                     Emails: [],
                     EmailsOld: [],
                  }

                  const record = {
                     LegisMem_id: '',
                     Candidate_id: thisCandidateId,
                     NameAsCandidate: thisName,
                     Name: thisName,
                     NameHindi: thisNameHindi,
                     NameLocal: thisNameLocal,

                     Age: thisAge,
                     DoB: thisDoB,
                     Gender: thisGender,

                     Constituency_id: thisConstituencyId,
                     ConstituencyName: thisConstituencyName,
                     ConstituencyNameHindi: thisConstituencyNameHindi,
                     ConstituencyNameLocal: thisConstituencyNameLocal,
                     ConstituencyNumber: selectedConstiNumber,
                     SeatType: selectedConstiSeatType,

                     Image: thisImage,

                     Party_id: thisPartyId,
                     PartyName: thisPartyName,
                     PartyNameHindi: thisPartyNameHindi,
                     PartyNameLocal: thisPartyNameLocal,
                     PartyAbbreviation: thisPartyAbbreviation,
                     PartyFlag: thisPartyFlag,

                     Alliances: thisAlliances,
                     AlliancesNow: [],

                     Votes: thisVotes,
                     OnlineSupport: thisOnlineSupport,
                     OnlineSwingSupport: thisOnlineSwingSupport,
                     ExitSupport: thisExitSupport,

                     Election_id: elec.id,
                     ElectionName: elec.Name,
                     ElectionNameHindi: elec.NameHindi,
                     ElectionNameLocal: elec.NameLocal,

                     Legislature_id: elec.Legislature_id,
                     HouseNumber: elec.HouseNumber,

                     FromDate: elec.DateCompletion,

                     ToDate: '',
                     Oath: '',
                     Resignation: '',
                     Disqualification: '',
                     TermCompletionDate: '',
                     Crux_id: '',
                     NetaProfile_id: '',
                  }

                  console.log('Stage 1')
                  const subCollectionDocId = 'MemCoreList-' + elec.HouseNumber

                  // get ref of the document inside the subCollection
                  const docRefSubLegis = doc(
                     db,
                     'Legislatures',
                     elec.Legislature_id,
                     'MemCoreLists',
                     subCollectionDocId,
                  )
                  const docSnapSubLegis = await getDoc(docRefSubLegis)

                  const docRefSubConsti = doc(db, 'LegisConstituencies', thisConstituencyId, 'CoreLists', 'MemCoreList')
                  const docSnapSubConsti = await getDoc(docRefSubConsti)

                  console.log('Stage 2')

                  if (docSnapSubLegis.exists()) {
                     // update the subcollection MemCoreLists
                     if (!docSnapSubLegis.data().CandidateIdsList.includes(thisCandidateId)) {
                        // 1. create the main doc in collection LegisMembers
                        const docRef = await addDoc(collection(db, 'LegisMembers'), data)
                        record.LegisMem_id = docRef.id

                        // 2. update subcollection 'MemCoreLists' of legislature with new values
                        await updateDoc(docRefSubLegis, {
                           Members: arrayUnion(record),
                           CandidateIdsList: arrayUnion(thisCandidateId),
                        })

                        // 3. update subcollection of Constituencies
                        if (docSnapSubConsti.exists()) {
                           await updateDoc(docRefSubConsti, {
                              Members: arrayUnion(record),
                              CandidateIdsList: arrayUnion(thisCandidateId),
                           })
                        } else {
                           setDoc(
                              docRefSubConsti,
                              { Members: [record], CandidateIdsList: [thisCandidateId] },
                              { merge: true },
                           )
                        }
                     } else {
                        /// alert, the candidate id already present
                        alert('This candidate is already in the members list of legislature!')
                     }
                  } else {
                     console.log('value to add in main doc: ', data)
                     // 1. create the main doc in collection LegisMembers
                     const docRef = await addDoc(collection(db, 'LegisMembers'), data)
                     record.LegisMem_id = docRef.id

                     console.log('value to add in subcollection: ', record)

                     // 2. create the subcollection MemCoreLists with new values
                     setDoc(
                        docRefSubLegis,
                        { Members: [record], CandidateIdsList: [thisCandidateId], HouseNumber: elec.HouseNumber },
                        { merge: true },
                     )

                     // 3. update Legislatures with following values
                     const docRefLegis = doc(db, 'Legislatures', elec.Legislature_id)
                     await updateDoc(docRefLegis, {
                        Houses: arrayUnion({
                           HouseNumber: elec.HouseNumber,
                           FromDate: '',
                           ToDate: '',
                        }),
                        HouseNumbers: arrayUnion(elec.HouseNumber),
                     })

                     // 4. update constituencies core list
                     if (docSnapSubConsti.exists()) {
                        await updateDoc(docRefSubConsti, {
                           Members: arrayUnion(record),
                           CandidateIdsList: arrayUnion(thisCandidateId),
                        })
                     } else {
                        setDoc(
                           docRefSubConsti,
                           { Members: [record], CandidateIdsList: [thisCandidateId] },
                           { merge: true },
                        )
                     }
                  }
               } catch (error) {
                  alert('Error adding member')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Candidates for ${elec.Name}`}</title>
            </Helmet>
         </div>
         {elec.IsSpeculated && (
            <Box>
               <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                  <strong>
                     <u>Disclaimer:</u>
                  </strong>{' '}
                  The <strong>dates</strong> of this election <strong>are speculated</strong> for the purpose of
                  display. Please wait for the actual announcement by the Election Commission of India.
                  <br />
                  <br />
                  <strong>
                     <u>अस्वीकरण:</u>
                  </strong>{' '}
                  इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से <strong>अनुमान</strong> हैं। कृपया भारत
                  के चुनाव आयोग द्वारा वास्तविक घोषणा की प्रतीक्षा करें।
               </Alert>
            </Box>
         )}
         {!elec.IsSpeculated && (
            <Box sx={{ px: 1 }}>
               <Box
                  sx={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                     px: 1,
                     mt: 0.5,
                     '& .MuiFormControl-root': { maxWidth: '50ch', minWidth: '35ch' },
                  }}
               >
                  <Box sx={{ pt: 1 }}>
                     <FormControl sx={{ ml: 1 }} size="small">
                        <InputLabel>Constituency / निर्वाचन क्षेत्र</InputLabel>
                        <Select
                           value={selectedConsti}
                           label="Constituency / निर्वाचन क्षेत्र"
                           onChange={(e) => {
                              handleChangeConsti(e)
                           }}
                        >
                           <MenuItem value="">
                              <em>None</em>
                           </MenuItem>
                           {constisList &&
                              constisList.length > 0 &&
                              constisList.map((item) => (
                                 <MenuItem
                                    key={item.Constituency_id}
                                    value={item.Constituency_id}
                                    sx={{ fontSize: 13 }}
                                 >
                                    {item.Name} ({item.ConstituencyNumber}) - {item.NameHindi}{' '}
                                    {item.NameLocal !== '' ? `-${item.NameLocal}` : null}
                                 </MenuItem>
                              ))}
                        </Select>
                     </FormControl>
                  </Box>
               </Box>
               <Box>
                  <Box
                     sx={{
                        p: 1,
                        mt: 0.5,
                        backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                        color: '#ffffff',
                     }}
                  >
                     {selectedConsti === '' && <Box>Candidates for this election:</Box>}
                     {selectedConsti === '' && <Box>इस चुनाव के लिए प्रत्याशी:</Box>}
                     {constisList && constisList.length > 0 && selectedConsti !== '' && (
                        <Box>
                           Phase: <strong>{selectedConstiPhaseNum}</strong>
                           <HowToVoteIcon sx={{ ml: 1 }} />{' '}
                           {selectedConstiPhaseDate && moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')}
                        </Box>
                     )}
                     {constisList && constisList.length > 0 && selectedConsti !== '' && (
                        <Box>
                           Candidates for constituency{' '}
                           <strong>
                              <u>
                                 {selectedConstiName} ({selectedConstiNumber})
                              </u>
                           </strong>
                           :
                        </Box>
                     )}
                     {constisList && constisList.length > 0 && selectedConsti !== '' && (
                        <Box>
                           {selectedConstiNameLocal !== '' && (
                              <>
                                 निर्वाचन क्षेत्र{' '}
                                 <strong>
                                    {selectedConstiNameLocal} - {selectedConstiNameHindi} ({selectedConstiNumber})
                                 </strong>{' '}
                                 के लिए प्रत्याशी:
                              </>
                           )}
                           {selectedConstiNameLocal === '' && (
                              <>
                                 निर्वाचन क्षेत्र{' '}
                                 <strong>
                                    {selectedConstiNameHindi} ({selectedConstiNumber})
                                 </strong>{' '}
                                 के लिए प्रत्याशी:
                              </>
                           )}
                           <Box>
                              Seat: <strong>{selectedConstiSeatType}</strong>
                           </Box>
                        </Box>
                     )}
                  </Box>
                  {selectedConsti !== '' && constisList && constisList.length < 1 && (
                     <Box>
                        <Alert
                           variant="outlined"
                           severity="warning"
                           sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                           <AlertTitle sx={{ fontSize: 12 }}>
                              To be available soon. <br /> शीघ्र उपलब्ध होगा |
                           </AlertTitle>
                        </Alert>
                     </Box>
                  )}
                  {selectedConsti === '' && (
                     <Box>
                        <Alert
                           variant="outlined"
                           severity="warning"
                           sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                           <AlertTitle sx={{ fontSize: 12 }}>
                              Please select a constituency. <br /> कृपया एक निर्वाचन क्षेत्र चुनें |
                           </AlertTitle>
                        </Alert>
                     </Box>
                  )}

                  {(isSuper || isDataEditor) && constisList && constisList.length > 0 && selectedConsti !== '' && (
                     <Box>
                        <Button
                           sx={{ py: 0, px: 1, minWidth: 0 }}
                           onClick={(e) => {
                              handleOpenModalAddCandis(e)
                           }}
                        >
                           Add Candidates
                        </Button>
                     </Box>
                  )}

                  <Box>
                     <List dense>
                        {candidatesListWaiting &&
                           candidatesListWaiting.length > 0 &&
                           candidatesListWaiting.map((item, index) => (
                              <div key={item.Candidate_id}>
                                 <ListItem
                                    sx={{ pl: 0, ml: 0 }}
                                    key={item.Candidate_id}
                                    secondaryAction={
                                       <Box sx={{ ml: 'auto' }}>
                                          {isSuper && (
                                             <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(e) => {
                                                   handleDelete(
                                                      e,
                                                      item.Candidate_id,
                                                      item.Name,
                                                      item.NameHindi,
                                                      item.NameLocal,
                                                      item.PhaseNum,
                                                      item.Constituency_id,
                                                      item.ConstituencyName,
                                                      item.ConstituencyNameHindi,
                                                      item.ConstituencyNameLocal,
                                                      item.Age,
                                                      item.DoB,
                                                      item.Gender,
                                                      item.Party_id,
                                                      item.PartyName,
                                                      item.PartyNameHindi,
                                                      item.PartyNameLocal,
                                                      item.PartyAbbreviation,
                                                      item.PartyFlag,
                                                      item.PartySymbol,
                                                      item.AllottedSymbol,
                                                      item.Alliances,
                                                      item.Image,
                                                      item.CoreListId,
                                                      item.Announced,
                                                      item.AnnouncedDate,
                                                      item.AnnounceCancelled,
                                                      item.AnnounceCancelledDate,
                                                      item.Filed,
                                                      item.FiledDate,
                                                      item.Accepted,
                                                      item.AcceptedDate,
                                                      item.Rejected,
                                                      item.RejectedDate,
                                                      item.Withdrawn,
                                                      item.WithdrawnDate,
                                                      item.Votes,
                                                      item.Winner,
                                                      item.OnlineSupport,
                                                      item.OnlineSwingSupport,
                                                      item.ExitSupport,
                                                   )
                                                }}
                                                sx={{ mr: 1, height: '25px', width: '25px' }}
                                             >
                                                <DeleteIcon fontSize="small" />
                                             </IconButton>
                                          )}
                                       </Box>
                                    }
                                 >
                                    <Link
                                       href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                          <Typography
                                             component="span"
                                             variant="body2"
                                             sx={{ color: 'text.primary', display: 'inline', fontWeight: 600, mb: 1 }}
                                          >
                                             {index + 1}.
                                          </Typography>
                                          {item.Name &&
                                             (countWordsUsingReplace(item.Name) > 1 ? (
                                                <Avatar
                                                   {...stringAvatar80(item.Name)}
                                                   alt="Travis Howard"
                                                   src={item.Image}
                                                />
                                             ) : (
                                                <Avatar
                                                   {...stringAvatar80Single(item.Name)}
                                                   alt="Travis Howard"
                                                   src={item.Image}
                                                />
                                             ))}
                                       </ListItemAvatar>
                                    </Link>

                                    <ListItemText
                                       sx={{ ml: 1 }}
                                       secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                       primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                       primary={
                                          <>
                                             {item.Name !== 'Zznota' && (
                                                <>
                                                   <Link
                                                      // color="inherit"
                                                      underline="hover"
                                                      href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                      sx={{ textDecoration: 'none !important' }}
                                                      target="_blank"
                                                      rel="noopener"
                                                   >
                                                      <strong>{item.Name.toUpperCase()}</strong>
                                                   </Link>{' '}
                                                   -{' '}
                                                   {item.NameLocal && (
                                                      <Link
                                                         href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                         className={styles.overrideUnderline}
                                                         sx={{ textDecoration: 'none !important' }}
                                                         target="_blank"
                                                         rel="noopener"
                                                      >
                                                         {item.NameLocal}
                                                      </Link>
                                                   )}
                                                   {'  '}-{' '}
                                                   <Link
                                                      underline="none"
                                                      href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                      color="warning"
                                                      sx={{ textDecoration: 'none !important' }}
                                                      target="_blank"
                                                      rel="noopener"
                                                   >
                                                      {/* <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '} */}
                                                      {item.NameHindi}
                                                      {'  '}
                                                   </Link>
                                                   ({item.Age}y)
                                                </>
                                             )}
                                             {item.Name === 'Zznota' && (
                                                <>
                                                   <strong>
                                                      NOTA
                                                      <br />
                                                      {item.NameLocal}
                                                      <br />
                                                      {item.NameHindi}
                                                   </strong>
                                                </>
                                             )}
                                          </>
                                       }
                                       component={'span'}
                                       secondary={
                                          <>
                                             <>
                                                {item.PartyFlag && (
                                                   <Link
                                                      href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                      target="_blank"
                                                      rel="noopener"
                                                   >
                                                      <img src={item.PartyFlag} className={styles.imgX2} />
                                                   </Link>
                                                )}

                                                {item.PartySymbol && (
                                                   <img src={item.PartySymbol} className={styles.imgX3} />
                                                )}
                                                {item.AllottedSymbol && (
                                                   <img src={item.AllottedSymbol} className={styles.imgX3} />
                                                )}

                                                <br />
                                             </>
                                             <>
                                                {<strong> Party: </strong>}
                                                {item.PartyName !== 'Independent' && (
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      <Link
                                                         href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                         underline="none"
                                                         sx={{ textDecoration: 'none !important' }}
                                                         target="_blank"
                                                         rel="noopener"
                                                      >
                                                         {item.PartyName}
                                                      </Link>
                                                   </Typography>
                                                )}
                                                {item.PartyName === 'Independent' && (
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.PartyName}
                                                   </Typography>
                                                )}
                                                <>
                                                   <br />
                                                   {<strong> Alliances: </strong>}
                                                   {item.Alliances.map((itemAl, index) => (
                                                      <Fragment key={itemAl.Alliance_id}>
                                                         {index + 1}.
                                                         <Link
                                                            key={itemAl.Alliance_id}
                                                            href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                                            sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            {itemAl.Abbreviation}
                                                         </Link>
                                                      </Fragment>
                                                   ))}
                                                </>
                                                <>
                                                   <br />
                                                   {<strong> Status: </strong>}
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="#0"
                                                   >
                                                      {item.Accepted === ''
                                                         ? 'Filed, waiting response.'
                                                         : item.Accepted}
                                                   </Typography>

                                                   {(isDataEditor || isSuper) && (
                                                      <IconButton
                                                         edge="end"
                                                         aria-label="edit"
                                                         onClick={(e) => {
                                                            handleOpenModalStatus(
                                                               e,
                                                               item.Candidate_id,
                                                               item.Name,
                                                               item.NameHindi,
                                                               item.NameLocal,
                                                               item.PhaseNum,
                                                               item.Constituency_id,
                                                               item.ConstituencyName,
                                                               item.ConstituencyNameHindi,
                                                               item.ConstituencyNameLocal,
                                                               item.Age,
                                                               item.DoB,
                                                               item.Gender,
                                                               item.Party_id,
                                                               item.PartyName,
                                                               item.PartyNameHindi,
                                                               item.PartyNameLocal,
                                                               item.PartyAbbreviation,
                                                               item.PartyFlag,
                                                               item.PartySymbol,
                                                               item.AllottedSymbol,
                                                               item.Alliances,
                                                               item.Image,
                                                               item.CoreListId,
                                                               item.Announced,
                                                               item.AnnouncedDate,
                                                               item.AnnounceCancelled,
                                                               item.AnnounceCancelledDate,
                                                               item.Filed,
                                                               item.FiledDate,
                                                               item.Accepted,
                                                               item.AcceptedDate,
                                                               item.Rejected,
                                                               item.RejectedDate,
                                                               item.Withdrawn,
                                                               item.WithdrawnDate,
                                                               item.Votes,
                                                               item.Winner,
                                                               item.OnlineSupport,
                                                               item.OnlineSwingSupport,
                                                               item.ExitSupport,
                                                            )
                                                         }}
                                                         sx={{ ml: 1, height: '15px', width: '15px' }}
                                                      >
                                                         <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                      </IconButton>
                                                   )}
                                                </>
                                                {(isDataEditor || isSuper) && (
                                                   <>
                                                      <br />
                                                      {<strong> Transfer to another constituency: </strong>}

                                                      <IconButton
                                                         edge="end"
                                                         aria-label="edit"
                                                         onClick={(e) => {
                                                            handleOpenModalConstiTransfer(
                                                               e,
                                                               item.Candidate_id,
                                                               item.Name,
                                                               item.NameHindi,
                                                               item.NameLocal,
                                                               item.PhaseNum,
                                                               item.Constituency_id,
                                                               item.ConstituencyName,
                                                               item.ConstituencyNameHindi,
                                                               item.ConstituencyNameLocal,
                                                               item.Age,
                                                               item.DoB,
                                                               item.Gender,
                                                               item.Party_id,
                                                               item.PartyName,
                                                               item.PartyNameHindi,
                                                               item.PartyNameLocal,
                                                               item.PartyAbbreviation,
                                                               item.PartyFlag,
                                                               item.PartySymbol,
                                                               item.AllottedSymbol,
                                                               item.Alliances,
                                                               item.Image,
                                                               item.CoreListId,
                                                               item.Announced,
                                                               item.AnnouncedDate,
                                                               item.AnnounceCancelled,
                                                               item.AnnounceCancelledDate,
                                                               item.Filed,
                                                               item.FiledDate,
                                                               item.Accepted,
                                                               item.AcceptedDate,
                                                               item.Rejected,
                                                               item.RejectedDate,
                                                               item.Withdrawn,
                                                               item.WithdrawnDate,
                                                               item.Votes,
                                                               item.Winner,
                                                               item.OnlineSupport,
                                                               item.OnlineSwingSupport,
                                                               item.ExitSupport,
                                                            )
                                                         }}
                                                         sx={{ ml: 1, height: '15px', width: '15px' }}
                                                      >
                                                         <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                      </IconButton>
                                                   </>
                                                )}
                                             </>
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </div>
                           ))}
                     </List>
                     {candidatesListWaiting &&
                        candidatesListWaiting.length < 1 &&
                        selectedConsti !== '' &&
                        candidatesListAccepted.length < 1 &&
                        candidatesListWithdrawn.length < 1 &&
                        candidatesListRejected.length < 1 && (
                           <Box>
                              <Alert
                                 variant="outlined"
                                 severity="warning"
                                 sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                              >
                                 <AlertTitle sx={{ fontSize: 12 }}>
                                    List of candidates for{' '}
                                    <strong>
                                       <u>{selectedConstiName}</u>
                                    </strong>{' '}
                                    to be available soon. <br />{' '}
                                    <strong>
                                       <u>{selectedConstiNameHindi}</u>
                                    </strong>{' '}
                                    के लिए प्रत्याशियों की सूची शीघ्र ही उपलब्ध होगी |
                                 </AlertTitle>
                              </Alert>
                           </Box>
                        )}
                  </Box>
                  <Box
                     sx={{
                        p: 1,
                        color: '#ffffff',
                        backgroundImage: 'linear-gradient(to right, #4caf50, #4caf50, #ffffff)',
                        fontWeight: 700,
                     }}
                  >
                     Accepted:
                  </Box>
                  <Box>
                     <Grid2 container sx={{ mt: 2 }} id="gridContainer">
                        <Grid2 item xs={8} id="grid1">
                           <List dense>
                              {candidatesListAccepted &&
                                 candidatesListAccepted.length > 0 &&
                                 candidatesListAccepted.map((item, index) => (
                                    <div key={item.Candidate_id}>
                                       <ListItem
                                          sx={{ pl: 0, ml: 0 }}
                                          key={item.Candidate_id}
                                          secondaryAction={
                                             <Box sx={{ ml: 'auto' }}>
                                                {isSuper && (
                                                   <IconButton
                                                      edge="end"
                                                      aria-label="delete"
                                                      onClick={(e) => {
                                                         handleDelete(
                                                            e,
                                                            item.Candidate_id,
                                                            item.Name,
                                                            item.NameHindi,
                                                            item.NameLocal,
                                                            item.PhaseNum,
                                                            item.Constituency_id,
                                                            item.ConstituencyName,
                                                            item.ConstituencyNameHindi,
                                                            item.ConstituencyNameLocal,
                                                            item.Age,
                                                            item.DoB,
                                                            item.Gender,
                                                            item.Party_id,
                                                            item.PartyName,
                                                            item.PartyNameHindi,
                                                            item.PartyNameLocal,
                                                            item.PartyAbbreviation,
                                                            item.PartyFlag,
                                                            item.PartySymbol,
                                                            item.AllottedSymbol,
                                                            item.Alliances,
                                                            item.Image,
                                                            item.CoreListId,
                                                            item.Announced,
                                                            item.AnnouncedDate,
                                                            item.AnnounceCancelled,
                                                            item.AnnounceCancelledDate,
                                                            item.Filed,
                                                            item.FiledDate,
                                                            item.Accepted,
                                                            item.AcceptedDate,
                                                            item.Rejected,
                                                            item.RejectedDate,
                                                            item.Withdrawn,
                                                            item.WithdrawnDate,
                                                            item.Votes,
                                                            item.Winner,
                                                            item.OnlineSupport,
                                                            item.OnlineSwingSupport,
                                                            item.ExitSupport,
                                                         )
                                                      }}
                                                      sx={{ mr: 1, height: '25px', width: '25px' }}
                                                   >
                                                      <DeleteIcon fontSize="small" />
                                                   </IconButton>
                                                )}
                                             </Box>
                                          }
                                       >
                                          <Link
                                             href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                             sx={{ textDecoration: 'none !important' }}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography
                                                   component="span"
                                                   variant="body2"
                                                   sx={{
                                                      color: 'text.primary',
                                                      display: 'inline',
                                                      fontWeight: 600,
                                                      mb: 1,
                                                   }}
                                                >
                                                   {index + 1}.
                                                </Typography>
                                                {item.Name &&
                                                   (countWordsUsingReplace(item.Name) > 1 ? (
                                                      <Avatar
                                                         {...stringAvatar80(item.Name)}
                                                         alt="Travis Howard"
                                                         src={item.Image}
                                                      />
                                                   ) : item.Name !== 'Zznota' ? (
                                                      <Avatar
                                                         {...stringAvatar80Single(item.Name)}
                                                         alt="Travis Howard"
                                                         src={item.Image}
                                                      />
                                                   ) : (
                                                      <Avatar
                                                         sx={{
                                                            width: 80,
                                                            height: 80,
                                                            backgroundColor: 'Yellow',
                                                         }}
                                                         src={NOTA}
                                                      />
                                                   ))}
                                             </ListItemAvatar>
                                          </Link>

                                          <ListItemText
                                             sx={{ ml: 1 }}
                                             secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                             primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                             primary={
                                                <>
                                                   {item.Name !== 'Zznota' && (
                                                      <>
                                                         <Link
                                                            // color="inherit"
                                                            underline="hover"
                                                            href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                            sx={{ textDecoration: 'none !important' }}
                                                            target="_blank"
                                                            rel="noopener"
                                                         >
                                                            <strong>{item.Name.toUpperCase()}</strong>
                                                         </Link>{' '}
                                                         -{' '}
                                                         {item.NameLocal && (
                                                            <Link
                                                               href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                               className={styles.overrideUnderline}
                                                               sx={{ textDecoration: 'none !important' }}
                                                               target="_blank"
                                                               rel="noopener"
                                                            >
                                                               {item.NameLocal}
                                                            </Link>
                                                         )}
                                                         {'  '}-{' '}
                                                         <Link
                                                            underline="none"
                                                            href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                            color="warning"
                                                            sx={{ textDecoration: 'none !important' }}
                                                            target="_blank"
                                                            rel="noopener"
                                                         >
                                                            {/* <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '} */}
                                                            {item.NameHindi}
                                                            {'  '}
                                                         </Link>
                                                         ({item.Age}y)
                                                      </>
                                                   )}
                                                   {item.Name === 'Zznota' && (
                                                      <>
                                                         <strong>
                                                            NOTA
                                                            <br />
                                                            {item.NameLocal}
                                                            <br />
                                                            {item.NameHindi}
                                                         </strong>
                                                      </>
                                                   )}
                                                </>
                                             }
                                             secondary={
                                                <>
                                                   {item.Name !== 'Zznota' && (
                                                      <>
                                                         <Link
                                                            href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                            target="_blank"
                                                            rel="noopener"
                                                         >
                                                            <img src={item.PartyFlag} className={styles.imgX2} />
                                                         </Link>
                                                         {item.PartySymbol !== '' ? (
                                                            <img src={item.PartySymbol} className={styles.imgX3} />
                                                         ) : (
                                                            item.AllottedSymbol !== '' && (
                                                               <img
                                                                  src={item.AllottedSymbol}
                                                                  className={styles.imgX3}
                                                               />
                                                            )
                                                         )}

                                                         <br />
                                                      </>
                                                   )}
                                                   <>
                                                      {item.Name !== 'Zznota' && (
                                                         <>
                                                            {<strong> Party: </strong>}
                                                            {item.PartyName !== 'Independent' && (
                                                               <Typography
                                                                  sx={{ display: 'inline' }}
                                                                  component="span"
                                                                  variant="body2"
                                                                  color="text.primary"
                                                               >
                                                                  <Link
                                                                     href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                                     underline="none"
                                                                     sx={{ textDecoration: 'none !important' }}
                                                                     target="_blank"
                                                                     rel="noopener"
                                                                  >
                                                                     {item.PartyName}
                                                                  </Link>
                                                               </Typography>
                                                            )}
                                                            {item.PartyName === 'Independent' && (
                                                               <Typography
                                                                  sx={{ display: 'inline' }}
                                                                  component="span"
                                                                  variant="body2"
                                                                  color="text.primary"
                                                               >
                                                                  {item.PartyName}
                                                               </Typography>
                                                            )}
                                                            <Box sx={{ flexWrap: 'wrap', wordWrap: 'break-word' }}>
                                                               {<strong> Alliances: </strong>}
                                                               {item.Alliances.map((itemAl, index) => (
                                                                  <Fragment key={itemAl.Alliance_id}>
                                                                     {index + 1}.
                                                                     <Link
                                                                        key={itemAl.Alliance_id}
                                                                        href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                                                        sx={{
                                                                           mr: 1,
                                                                           ml: 0.5,
                                                                           textDecoration: 'none !important',
                                                                        }}
                                                                        rel="noreferrer"
                                                                        target="_blank"
                                                                     >
                                                                        {itemAl.Abbreviation}
                                                                     </Link>
                                                                  </Fragment>
                                                               ))}
                                                            </Box>
                                                            {!item.Winner && (
                                                               <>
                                                                  {<strong> Status: </strong>}
                                                                  <Typography
                                                                     sx={{ display: 'inline' }}
                                                                     component="span"
                                                                     variant="body2"
                                                                     color="#4caf50"
                                                                  >
                                                                     {item.Accepted}
                                                                  </Typography>

                                                                  {(isDataEditor || isSuper) && (
                                                                     <IconButton
                                                                        edge="end"
                                                                        aria-label="edit"
                                                                        onClick={(e) => {
                                                                           handleOpenModalStatus(
                                                                              e,
                                                                              item.Candidate_id,
                                                                              item.Name,
                                                                              item.NameHindi,
                                                                              item.NameLocal,
                                                                              item.PhaseNum,
                                                                              item.Constituency_id,
                                                                              item.ConstituencyName,
                                                                              item.ConstituencyNameHindi,
                                                                              item.ConstituencyNameLocal,
                                                                              item.Age,
                                                                              item.DoB,
                                                                              item.Gender,
                                                                              item.Party_id,
                                                                              item.PartyName,
                                                                              item.PartyNameHindi,
                                                                              item.PartyNameLocal,
                                                                              item.PartyAbbreviation,
                                                                              item.PartyFlag,
                                                                              item.PartySymbol,
                                                                              item.AllottedSymbol,
                                                                              item.Alliances,
                                                                              item.Image,
                                                                              item.CoreListId,
                                                                              item.Announced,
                                                                              item.AnnouncedDate,
                                                                              item.AnnounceCancelled,
                                                                              item.AnnounceCancelledDate,
                                                                              item.Filed,
                                                                              item.FiledDate,
                                                                              item.Accepted,
                                                                              item.AcceptedDate,
                                                                              item.Rejected,
                                                                              item.RejectedDate,
                                                                              item.Withdrawn,
                                                                              item.WithdrawnDate,
                                                                              item.Votes,
                                                                              item.Winner,
                                                                              item.OnlineSupport,
                                                                              item.OnlineSwingSupport,
                                                                              item.ExitSupport,
                                                                           )
                                                                        }}
                                                                        sx={{ ml: 1, height: '15px', width: '15px' }}
                                                                     >
                                                                        <EditIcon
                                                                           sx={{ height: '15px', width: '15px' }}
                                                                        />
                                                                     </IconButton>
                                                                  )}
                                                                  <br />
                                                               </>
                                                            )}
                                                         </>
                                                      )}
                                                      <>
                                                         {<strong> Votes: </strong>}
                                                         <Typography
                                                            sx={{ display: 'inline', color: 'Crimson' }}
                                                            component="span"
                                                            variant="body2"
                                                            color="#4caf50"
                                                         >
                                                            <strong>{item.Votes}</strong>
                                                         </Typography>

                                                         {(isDataEditor || isSuper) && (
                                                            <IconButton
                                                               edge="end"
                                                               aria-label="edit"
                                                               onClick={(e) => {
                                                                  handleOpenModalVotes(
                                                                     e,
                                                                     item.Candidate_id,
                                                                     item.Name,
                                                                     item.NameHindi,
                                                                     item.NameLocal,
                                                                     item.PhaseNum,
                                                                     item.Constituency_id,
                                                                     item.ConstituencyName,
                                                                     item.ConstituencyNameHindi,
                                                                     item.ConstituencyNameLocal,
                                                                     item.Age,
                                                                     item.DoB,
                                                                     item.Gender,
                                                                     item.Party_id,
                                                                     item.PartyName,
                                                                     item.PartyNameHindi,
                                                                     item.PartyNameLocal,
                                                                     item.PartyAbbreviation,
                                                                     item.PartyFlag,
                                                                     item.PartySymbol,
                                                                     item.AllottedSymbol,
                                                                     item.Alliances,
                                                                     item.Image,
                                                                     item.CoreListId,
                                                                     item.Announced,
                                                                     item.AnnouncedDate,
                                                                     item.AnnounceCancelled,
                                                                     item.AnnounceCancelledDate,
                                                                     item.Filed,
                                                                     item.FiledDate,
                                                                     item.Accepted,
                                                                     item.AcceptedDate,
                                                                     item.Rejected,
                                                                     item.RejectedDate,
                                                                     item.Withdrawn,
                                                                     item.WithdrawnDate,
                                                                     item.Votes,
                                                                     item.Winner,
                                                                     item.OnlineSwingSupport,
                                                                     item.ExitSupport,
                                                                  )
                                                               }}
                                                               sx={{ ml: 1, height: '15px', width: '15px' }}
                                                            >
                                                               <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                            </IconButton>
                                                         )}
                                                         <br />
                                                      </>
                                                      {item.Name !== 'Zznota' && (
                                                         <>
                                                            {item.Winner === 'true' ? (
                                                               <img src={winner} className={styles.imgXX} />
                                                            ) : (
                                                               'Declare Winner'
                                                            )}
                                                            {(isDataEditor || isSuper) && (
                                                               <IconButton
                                                                  edge="end"
                                                                  aria-label="edit"
                                                                  onClick={(e) => {
                                                                     handleOpenModalWinner(
                                                                        e,
                                                                        item.Candidate_id,
                                                                        item.Name,
                                                                        item.NameHindi,
                                                                        item.NameLocal,
                                                                        item.PhaseNum,
                                                                        item.Constituency_id,
                                                                        item.ConstituencyName,
                                                                        item.ConstituencyNameHindi,
                                                                        item.ConstituencyNameLocal,
                                                                        item.Age,
                                                                        item.DoB,
                                                                        item.Gender,
                                                                        item.Party_id,
                                                                        item.PartyName,
                                                                        item.PartyNameHindi,
                                                                        item.PartyNameLocal,
                                                                        item.PartyAbbreviation,
                                                                        item.PartyFlag,
                                                                        item.PartySymbol,
                                                                        item.AllottedSymbol,
                                                                        item.Alliances,
                                                                        item.Image,
                                                                        item.CoreListId,
                                                                        item.Announced,
                                                                        item.AnnouncedDate,
                                                                        item.AnnounceCancelled,
                                                                        item.AnnounceCancelledDate,
                                                                        item.Filed,
                                                                        item.FiledDate,
                                                                        item.Accepted,
                                                                        item.AcceptedDate,
                                                                        item.Rejected,
                                                                        item.RejectedDate,
                                                                        item.Withdrawn,
                                                                        item.WithdrawnDate,
                                                                        item.Votes,
                                                                        item.Winner,
                                                                        item.OnlineSupport,
                                                                        item.OnlineSwingSupport,
                                                                        item.ExitSupport,
                                                                     )
                                                                  }}
                                                                  sx={{ ml: 1, height: '15px', width: '15px' }}
                                                               >
                                                                  <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                               </IconButton>
                                                            )}
                                                            <br />
                                                         </>
                                                      )}
                                                      {item.Winner === 'true' && (isDataEditor || isSuper) && (
                                                         <>
                                                            {<strong> Send to legislature: </strong>}

                                                            <IconButton
                                                               edge="end"
                                                               aria-label="sendToLegis"
                                                               onClick={(e) => {
                                                                  handleSendToLegis(
                                                                     e,
                                                                     item.Candidate_id,
                                                                     item.Name,
                                                                     item.NameHindi,
                                                                     item.NameLocal,
                                                                     item.Constituency_id,
                                                                     item.ConstituencyName,
                                                                     item.ConstituencyNameHindi,
                                                                     item.ConstituencyNameLocal,
                                                                     item.Age,
                                                                     item.DoB,
                                                                     item.Gender,
                                                                     item.Party_id,
                                                                     item.PartyName,
                                                                     item.PartyNameHindi,
                                                                     item.PartyNameLocal,
                                                                     item.PartyAbbreviation,
                                                                     item.PartyFlag,
                                                                     item.Alliances,
                                                                     item.Image,
                                                                     item.Votes,
                                                                     item.OnlineSupport,
                                                                     item.OnlineSwingSupport,
                                                                     item.ExitSupport,
                                                                  )
                                                               }}
                                                               sx={{ ml: 1, height: '20px', width: '20px' }}
                                                            >
                                                               <DoubleArrowIcon
                                                                  sx={{
                                                                     height: '20px',
                                                                     width: '20px',
                                                                     color: '#03a9f4',
                                                                  }}
                                                               />
                                                            </IconButton>

                                                            <br />
                                                         </>
                                                      )}
                                                      {(isDataEditor || isSuper) && (
                                                         <>
                                                            <br />
                                                            {<strong> Transfer to another constituency: </strong>}

                                                            <IconButton
                                                               edge="end"
                                                               aria-label="edit"
                                                               onClick={(e) => {
                                                                  handleOpenModalConstiTransfer(
                                                                     e,
                                                                     item.Candidate_id,
                                                                     item.Name,
                                                                     item.NameHindi,
                                                                     item.NameLocal,
                                                                     item.PhaseNum,
                                                                     item.Constituency_id,
                                                                     item.ConstituencyName,
                                                                     item.ConstituencyNameHindi,
                                                                     item.ConstituencyNameLocal,
                                                                     item.Age,
                                                                     item.DoB,
                                                                     item.Gender,
                                                                     item.Party_id,
                                                                     item.PartyName,
                                                                     item.PartyNameHindi,
                                                                     item.PartyNameLocal,
                                                                     item.PartyAbbreviation,
                                                                     item.PartyFlag,
                                                                     item.PartySymbol,
                                                                     item.AllottedSymbol,
                                                                     item.Alliances,
                                                                     item.Image,
                                                                     item.CoreListId,
                                                                     item.Announced,
                                                                     item.AnnouncedDate,
                                                                     item.AnnounceCancelled,
                                                                     item.AnnounceCancelledDate,
                                                                     item.Filed,
                                                                     item.FiledDate,
                                                                     item.Accepted,
                                                                     item.AcceptedDate,
                                                                     item.Rejected,
                                                                     item.RejectedDate,
                                                                     item.Withdrawn,
                                                                     item.WithdrawnDate,
                                                                     item.Votes,
                                                                     item.Winner,
                                                                     item.OnlineSupport,
                                                                     item.OnlineSwingSupport,
                                                                     item.ExitSupport,
                                                                  )
                                                               }}
                                                               sx={{ ml: 1, height: '15px', width: '15px' }}
                                                            >
                                                               <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                            </IconButton>
                                                         </>
                                                      )}
                                                   </>
                                                </>
                                             }
                                          />
                                       </ListItem>
                                       <Divider />
                                    </div>
                                 ))}
                           </List>
                        </Grid2>
                        <Grid2 item xs={4}>
                           {candidatesListAccepted && candidatesListAccepted.length > 0 && (
                              <Box sx={{ borderRadius: 1, border: 1, borderColor: '#03a9f4' }}>
                                 <Box
                                    sx={{
                                       backgroundColor: '#03a9f4',
                                       // backgroundImage: 'linear-gradient(to right, #48464c, #605d66, #eb9191)',
                                       whiteSpace: 'pre-line',
                                       textAlign: 'center',
                                       fontWeight: 700,
                                       color: '#FFFFFF',
                                       mt: 1,
                                    }}
                                 >
                                    Online Support {'\n'} ऑनलाइन समर्थन
                                 </Box>
                                 <Box sx={{ display: 'flex', pt: 0.5, px: 0.5 }}>
                                    <Button
                                       variant="contained"
                                       sx={{ py: 0, px: 1, minWidth: 0, ml: 'auto', textTransform: 'none' }}
                                       onClick={(e) => {
                                          handleOpenModalOnlineSupport(e)
                                       }}
                                       endIcon={<HowToVoteIcon />}
                                    >
                                       Your Support ...
                                    </Button>
                                 </Box>
                                 <Box>
                                    <List dense sx={{ py: 0.5 }}>
                                       {candidatesListAccepted &&
                                          candidatesListAccepted.length > 0 &&
                                          candidatesListAccepted.map((item, index) => (
                                             <Box key={item.Candidate_id}>
                                                <ListItem sx={{ p: 0, ml: 0 }} key={item.Candidate_id}>
                                                   <ListItemText
                                                      sx={{ ml: 1, fontSize: 13, p: 0 }}
                                                      secondaryTypographyProps={{
                                                         align: 'right',
                                                         whiteSpace: 'pre-line',
                                                         fontSize: 12,
                                                         color: 'Crimson',
                                                         pr: 1,
                                                      }}
                                                      primaryTypographyProps={{ whiteSpace: 'pre-line', fontSize: 12 }}
                                                      primary={
                                                         <>
                                                            {item.Name !== 'Zznota' ? (
                                                               <strong>{item.Name.toUpperCase()}</strong>
                                                            ) : (
                                                               <strong>NOTA</strong>
                                                            )}

                                                            {item.NameLocal && (
                                                               <>
                                                                  <br />
                                                                  {item.NameLocal}
                                                               </>
                                                            )}
                                                            {!item.NameLocal && (
                                                               <>
                                                                  <br />
                                                                  {item.NameHindi}
                                                               </>
                                                            )}
                                                         </>
                                                      }
                                                      secondary={<>0</>}
                                                   />
                                                </ListItem>
                                                <Divider />
                                             </Box>
                                          ))}
                                    </List>
                                 </Box>
                              </Box>
                           )}
                        </Grid2>
                     </Grid2>
                  </Box>
                  <Box
                     sx={{
                        p: 1,
                        mt: 0.5,
                        color: '#ffffff',
                        backgroundImage: 'linear-gradient(to right, #ff9800, #ff9800, #ffffff)',
                        fontWeight: 700,
                     }}
                  >
                     Withdrawn:
                  </Box>
                  <Box>
                     <List dense>
                        {candidatesListWithdrawn &&
                           candidatesListWithdrawn.length > 0 &&
                           candidatesListWithdrawn.map((item, index) => (
                              <div key={item.Candidate_id}>
                                 <ListItem
                                    sx={{ pl: 0, ml: 0 }}
                                    key={item.Candidate_id}
                                    secondaryAction={
                                       <Box sx={{ ml: 'auto' }}>
                                          {isSuper && (
                                             <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(e) => {
                                                   handleDelete(
                                                      e,
                                                      item.Candidate_id,
                                                      item.Name,
                                                      item.NameHindi,
                                                      item.NameLocal,
                                                      item.PhaseNum,
                                                      item.Constituency_id,
                                                      item.ConstituencyName,
                                                      item.ConstituencyNameHindi,
                                                      item.ConstituencyNameLocal,
                                                      item.Age,
                                                      item.DoB,
                                                      item.Gender,
                                                      item.Party_id,
                                                      item.PartyName,
                                                      item.PartyNameHindi,
                                                      item.PartyNameLocal,
                                                      item.PartyAbbreviation,
                                                      item.PartyFlag,
                                                      item.PartySymbol,
                                                      item.AllottedSymbol,
                                                      item.Alliances,
                                                      item.Image,
                                                      item.CoreListId,
                                                      item.Announced,
                                                      item.AnnouncedDate,
                                                      item.AnnounceCancelled,
                                                      item.AnnounceCancelledDate,
                                                      item.Filed,
                                                      item.FiledDate,
                                                      item.Accepted,
                                                      item.AcceptedDate,
                                                      item.Rejected,
                                                      item.RejectedDate,
                                                      item.Withdrawn,
                                                      item.WithdrawnDate,
                                                      item.Votes,
                                                      item.Winner,
                                                      item.OnlineSupport,
                                                      item.OnlineSwingSupport,
                                                      item.ExitSupport,
                                                   )
                                                }}
                                                sx={{ mr: 1, height: '25px', width: '25px' }}
                                             >
                                                <DeleteIcon fontSize="small" />
                                             </IconButton>
                                          )}
                                       </Box>
                                    }
                                 >
                                    <Link
                                       href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                          <Typography
                                             component="span"
                                             variant="body2"
                                             sx={{ color: 'text.primary', display: 'inline', fontWeight: 600, mb: 1 }}
                                          >
                                             {index + 1}.
                                          </Typography>
                                          {item.Name &&
                                             (countWordsUsingReplace(item.Name) > 1 ? (
                                                <Avatar
                                                   {...stringAvatar80(item.Name)}
                                                   alt="Travis Howard"
                                                   src={item.Image}
                                                />
                                             ) : (
                                                <Avatar
                                                   {...stringAvatar80Single(item.Name)}
                                                   alt="Travis Howard"
                                                   src={item.Image}
                                                />
                                             ))}
                                       </ListItemAvatar>
                                    </Link>

                                    <ListItemText
                                       sx={{ ml: 1 }}
                                       secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                       primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                       primary={
                                          <>
                                             <Link
                                                // color="inherit"
                                                underline="hover"
                                                href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                <strong>{item.Name.toUpperCase()}</strong>
                                             </Link>{' '}
                                             -{' '}
                                             {item.NameLocal && (
                                                <Link
                                                   href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                   className={styles.overrideUnderline}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   {item.NameLocal}
                                                </Link>
                                             )}
                                             {'  '}-{' '}
                                             <Link
                                                underline="none"
                                                href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                color="warning"
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                {/* <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '} */}
                                                {item.NameHindi}
                                                {'  '}
                                             </Link>
                                             ({item.Age}y)
                                          </>
                                       }
                                       component={'span'}
                                       secondary={
                                          <>
                                             <>
                                                <Link
                                                   href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <img src={item.PartyFlag} className={styles.imgX2} />
                                                </Link>
                                                {item.PartySymbol ? (
                                                   <img src={item.PartySymbol} className={styles.imgX3} />
                                                ) : (
                                                   <img src={item.AllottedSymbol} className={styles.imgX3} />
                                                )}

                                                <br />
                                             </>
                                             <>
                                                {<strong> Party: </strong>}
                                                {item.PartyName !== 'Independent' && (
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      <Link
                                                         href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                         underline="none"
                                                         sx={{ textDecoration: 'none !important' }}
                                                         target="_blank"
                                                         rel="noopener"
                                                      >
                                                         {item.PartyName}
                                                      </Link>
                                                   </Typography>
                                                )}
                                                {item.PartyName === 'Independent' && (
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.PartyName}
                                                   </Typography>
                                                )}
                                                <>
                                                   <br />
                                                   {<strong> Alliances: </strong>}
                                                   {item.Alliances.map((itemAl, index) => (
                                                      <Fragment key={itemAl.Alliance_id}>
                                                         {index + 1}.
                                                         <Link
                                                            key={itemAl.Alliance_id}
                                                            href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                                            sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            {itemAl.Abbreviation}
                                                         </Link>
                                                      </Fragment>
                                                   ))}
                                                </>
                                                <>
                                                   <br />
                                                   {<strong> Status: </strong>}
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="#ff9800"
                                                   >
                                                      {item.Withdrawn}
                                                   </Typography>

                                                   {(isDataEditor || isSuper) && (
                                                      <IconButton
                                                         edge="end"
                                                         aria-label="edit"
                                                         onClick={(e) => {
                                                            handleOpenModalStatus(
                                                               e,
                                                               item.Candidate_id,
                                                               item.Name,
                                                               item.NameHindi,
                                                               item.NameLocal,
                                                               item.PhaseNum,
                                                               item.Constituency_id,
                                                               item.ConstituencyName,
                                                               item.ConstituencyNameHindi,
                                                               item.ConstituencyNameLocal,
                                                               item.Age,
                                                               item.DoB,
                                                               item.Gender,
                                                               item.Party_id,
                                                               item.PartyName,
                                                               item.PartyNameHindi,
                                                               item.PartyNameLocal,
                                                               item.PartyAbbreviation,
                                                               item.PartyFlag,
                                                               item.PartySymbol,
                                                               item.AllottedSymbol,
                                                               item.Alliances,
                                                               item.Image,
                                                               item.CoreListId,
                                                               item.Announced,
                                                               item.AnnouncedDate,
                                                               item.AnnounceCancelled,
                                                               item.AnnounceCancelledDate,
                                                               item.Filed,
                                                               item.FiledDate,
                                                               item.Accepted,
                                                               item.AcceptedDate,
                                                               item.Rejected,
                                                               item.RejectedDate,
                                                               item.Withdrawn,
                                                               item.WithdrawnDate,
                                                               item.Votes,
                                                               item.Winner,
                                                               item.OnlineSupport,
                                                               item.OnlineSwingSupport,
                                                               item.ExitSupport,
                                                            )
                                                         }}
                                                         sx={{ ml: 1, height: '15px', width: '15px' }}
                                                      >
                                                         <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                      </IconButton>
                                                   )}
                                                   <br />
                                                </>
                                                {(isDataEditor || isSuper) && (
                                                   <>
                                                      <br />
                                                      {<strong> Transfer to another constituency: </strong>}

                                                      <IconButton
                                                         edge="end"
                                                         aria-label="edit"
                                                         onClick={(e) => {
                                                            handleOpenModalConstiTransfer(
                                                               e,
                                                               item.Candidate_id,
                                                               item.Name,
                                                               item.NameHindi,
                                                               item.NameLocal,
                                                               item.PhaseNum,
                                                               item.Constituency_id,
                                                               item.ConstituencyName,
                                                               item.ConstituencyNameHindi,
                                                               item.ConstituencyNameLocal,
                                                               item.Age,
                                                               item.DoB,
                                                               item.Gender,
                                                               item.Party_id,
                                                               item.PartyName,
                                                               item.PartyNameHindi,
                                                               item.PartyNameLocal,
                                                               item.PartyAbbreviation,
                                                               item.PartyFlag,
                                                               item.PartySymbol,
                                                               item.AllottedSymbol,
                                                               item.Alliances,
                                                               item.Image,
                                                               item.CoreListId,
                                                               item.Announced,
                                                               item.AnnouncedDate,
                                                               item.AnnounceCancelled,
                                                               item.AnnounceCancelledDate,
                                                               item.Filed,
                                                               item.FiledDate,
                                                               item.Accepted,
                                                               item.AcceptedDate,
                                                               item.Rejected,
                                                               item.RejectedDate,
                                                               item.Withdrawn,
                                                               item.WithdrawnDate,
                                                               item.Votes,
                                                               item.Winner,
                                                               item.OnlineSupport,
                                                               item.OnlineSwingSupport,
                                                               item.ExitSupport,
                                                            )
                                                         }}
                                                         sx={{ ml: 1, height: '15px', width: '15px' }}
                                                      >
                                                         <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                      </IconButton>
                                                   </>
                                                )}
                                             </>
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </div>
                           ))}
                     </List>
                  </Box>
                  <Box
                     sx={{
                        p: 1,
                        mt: 0.5,
                        backgroundImage: 'linear-gradient(to right, #f44336, #f44336, #ffffff)',
                        color: '#ffffff',
                        fontWeight: 700,
                     }}
                  >
                     Rejected:
                  </Box>
                  <Box>
                     <List dense>
                        {candidatesListRejected &&
                           candidatesListRejected.length > 0 &&
                           candidatesListRejected.map((item, index) => (
                              <div key={item.Candidate_id}>
                                 <ListItem
                                    sx={{ pl: 0, ml: 0 }}
                                    key={item.Candidate_id}
                                    secondaryAction={
                                       <Box sx={{ ml: 'auto' }}>
                                          {isSuper && (
                                             <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={(e) => {
                                                   handleDelete(
                                                      e,
                                                      item.Candidate_id,
                                                      item.Name,
                                                      item.NameHindi,
                                                      item.NameLocal,
                                                      item.PhaseNum,
                                                      item.Constituency_id,
                                                      item.ConstituencyName,
                                                      item.ConstituencyNameHindi,
                                                      item.ConstituencyNameLocal,
                                                      item.Age,
                                                      item.DoB,
                                                      item.Gender,
                                                      item.Party_id,
                                                      item.PartyName,
                                                      item.PartyNameHindi,
                                                      item.PartyNameLocal,
                                                      item.PartyAbbreviation,
                                                      item.PartyFlag,
                                                      item.PartySymbol,
                                                      item.AllottedSymbol,
                                                      item.Alliances,
                                                      item.Image,
                                                      item.CoreListId,
                                                      item.Announced,
                                                      item.AnnouncedDate,
                                                      item.AnnounceCancelled,
                                                      item.AnnounceCancelledDate,
                                                      item.Filed,
                                                      item.FiledDate,
                                                      item.Accepted,
                                                      item.AcceptedDate,
                                                      item.Rejected,
                                                      item.RejectedDate,
                                                      item.Withdrawn,
                                                      item.WithdrawnDate,
                                                      item.Votes,
                                                      item.Winner,
                                                      item.OnlineSupport,
                                                      item.OnlineSwingSupport,
                                                      item.ExitSupport,
                                                   )
                                                }}
                                                sx={{ mr: 1, height: '25px', width: '25px' }}
                                             >
                                                <DeleteIcon fontSize="small" />
                                             </IconButton>
                                          )}
                                       </Box>
                                    }
                                 >
                                    <Link
                                       href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                                          <Typography
                                             component="span"
                                             variant="body2"
                                             sx={{ color: 'text.primary', display: 'inline', fontWeight: 600, mb: 1 }}
                                          >
                                             {index + 1}.
                                          </Typography>
                                          {item.Name &&
                                             (countWordsUsingReplace(item.Name) > 1 ? (
                                                <Avatar
                                                   {...stringAvatar80(item.Name)}
                                                   alt="Travis Howard"
                                                   src={item.Image}
                                                />
                                             ) : (
                                                <Avatar
                                                   {...stringAvatar80Single(item.Name)}
                                                   alt="Travis Howard"
                                                   src={item.Image}
                                                />
                                             ))}
                                       </ListItemAvatar>
                                    </Link>

                                    <ListItemText
                                       sx={{ ml: 1 }}
                                       secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                                       primaryTypographyProps={{ whiteSpace: 'pre-line' }}
                                       primary={
                                          <>
                                             <Link
                                                // color="inherit"
                                                underline="hover"
                                                href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                <strong>{item.Name.toUpperCase()}</strong>
                                             </Link>{' '}
                                             -{' '}
                                             {item.NameLocal && (
                                                <Link
                                                   href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                   className={styles.overrideUnderline}
                                                   sx={{ textDecoration: 'none !important' }}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   {item.NameLocal}
                                                </Link>
                                             )}
                                             {'  '}-{' '}
                                             <Link
                                                underline="none"
                                                href={`/election/candidate/${item.Name}/${item.Candidate_id}`}
                                                color="warning"
                                                sx={{ textDecoration: 'none !important' }}
                                                target="_blank"
                                                rel="noopener"
                                             >
                                                {/* <FiberManualRecordIcon fontSize="12px" sx={{ color: '#888889' }} />{' '} */}
                                                {item.NameHindi}
                                                {'  '}
                                             </Link>
                                             ({item.Age}y)
                                          </>
                                       }
                                       component={'span'}
                                       secondary={
                                          <>
                                             <>
                                                <Link
                                                   href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                   target="_blank"
                                                   rel="noopener"
                                                >
                                                   <img src={item.PartyFlag} className={styles.imgX2} />
                                                </Link>
                                                {item.PartySymbol ? (
                                                   <img src={item.PartySymbol} className={styles.imgX3} />
                                                ) : (
                                                   <img src={item.AllottedSymbol} className={styles.imgX3} />
                                                )}

                                                <br />
                                             </>
                                             <>
                                                {<strong> Party: </strong>}
                                                {item.PartyName !== 'Independent' && (
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      <Link
                                                         href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                                         underline="none"
                                                         sx={{ textDecoration: 'none !important' }}
                                                         target="_blank"
                                                         rel="noopener"
                                                      >
                                                         {item.PartyName}
                                                      </Link>
                                                   </Typography>
                                                )}
                                                {item.PartyName === 'Independent' && (
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="text.primary"
                                                   >
                                                      {item.PartyName}
                                                   </Typography>
                                                )}
                                                <>
                                                   <br />
                                                   {<strong> Alliances: </strong>}
                                                   {item.Alliances.map((itemAl, index) => (
                                                      <Fragment key={itemAl.Alliance_id}>
                                                         {index + 1}.
                                                         <Link
                                                            key={itemAl.Alliance_id}
                                                            href={`/alliance/${itemAl.Name}/${itemAl.Alliance_id}`}
                                                            sx={{ mr: 1, ml: 0.5, textDecoration: 'none !important' }}
                                                            rel="noreferrer"
                                                            target="_blank"
                                                         >
                                                            {itemAl.Abbreviation}
                                                         </Link>
                                                      </Fragment>
                                                   ))}
                                                </>
                                                <>
                                                   <br />
                                                   {<strong> Status: </strong>}
                                                   <Typography
                                                      sx={{ display: 'inline' }}
                                                      component="span"
                                                      variant="body2"
                                                      color="#f44336"
                                                   >
                                                      {item.Rejected}
                                                   </Typography>

                                                   {(isDataEditor || isSuper) && (
                                                      <IconButton
                                                         edge="end"
                                                         aria-label="edit"
                                                         onClick={(e) => {
                                                            handleOpenModalStatus(
                                                               e,
                                                               item.Candidate_id,
                                                               item.Name,
                                                               item.NameHindi,
                                                               item.NameLocal,
                                                               item.PhaseNum,
                                                               item.Constituency_id,
                                                               item.ConstituencyName,
                                                               item.ConstituencyNameHindi,
                                                               item.ConstituencyNameLocal,
                                                               item.Age,
                                                               item.DoB,
                                                               item.Gender,
                                                               item.Party_id,
                                                               item.PartyName,
                                                               item.PartyNameHindi,
                                                               item.PartyNameLocal,
                                                               item.PartyAbbreviation,
                                                               item.PartyFlag,
                                                               item.PartySymbol,
                                                               item.AllottedSymbol,
                                                               item.Alliances,
                                                               item.Image,
                                                               item.CoreListId,
                                                               item.Announced,
                                                               item.AnnouncedDate,
                                                               item.AnnounceCancelled,
                                                               item.AnnounceCancelledDate,
                                                               item.Filed,
                                                               item.FiledDate,
                                                               item.Accepted,
                                                               item.AcceptedDate,
                                                               item.Rejected,
                                                               item.RejectedDate,
                                                               item.Withdrawn,
                                                               item.WithdrawnDate,
                                                               item.Votes,
                                                               item.Winner,
                                                               item.OnlineSupport,
                                                               item.OnlineSwingSupport,
                                                               item.ExitSupport,
                                                            )
                                                         }}
                                                         sx={{ ml: 1, height: '15px', width: '15px' }}
                                                      >
                                                         <EditIcon sx={{ height: '15px', width: '15px' }} />
                                                      </IconButton>
                                                   )}
                                                   <br />
                                                </>
                                             </>
                                          </>
                                       }
                                    />
                                 </ListItem>
                                 <Divider />
                              </div>
                           ))}
                     </List>
                  </Box>
                  <Box id="boxModals">
                     <Modal
                        open={openModalAddCandis}
                        onClose={handleCloseModalAddCandis}
                        aria-labelledby="modal-modal-title-addCandis"
                        aria-describedby="modal-modal-description-addCandis"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-addCandis"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              Add candidates for {'\n'} {selectedConstiName}
                           </Typography>
                           <AddCandis
                              partiesList={partiesList}
                              lastCandi={lastCandi}
                              uploadingCandis={uploadingCandis}
                              uploadedCandis={uploadedCandis}
                              uploadingFailedCandis={uploadingFailedCandis}
                              handleAddCandis={handleAddCandis}
                           ></AddCandis>
                        </Box>
                     </Modal>
                     <Modal
                        open={openModalStatus}
                        onClose={handleCloseModalStatus}
                        aria-labelledby="modal-modal-title-addStatus"
                        aria-describedby="modal-modal-description-addStatus"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-addStatus"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              {selectedCandidate !== '' && `Update status of ${selectedCandidate.Name.toUpperCase()}`}
                           </Typography>
                           <AddCandiStatus
                              selectedCandidate={selectedCandidate}
                              uploadingStatus={uploadingStatus}
                              uploadingFailedStatus={uploadingFailedStatus}
                              uploadedStatus={uploadedStatus}
                              handleAddStatus={handleAddStatus}
                           ></AddCandiStatus>
                        </Box>
                     </Modal>
                     <Modal
                        open={openModalConstiTransfer}
                        onClose={handleCloseModalConstiTransfer}
                        aria-labelledby="modal-modal-title-constiTransfer"
                        aria-describedby="modal-modal-description-constiTransfer"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-addStatus"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              {selectedCandidate !== '' &&
                                 `Transfer ${selectedCandidate.Name.toUpperCase()} to another constituency`}
                           </Typography>
                           <ElecCandiConstiTransfer
                              selectedCandidate={selectedCandidate}
                              uploadingConstiTransfer={uploadingConstiTransfer}
                              uploadingFailedConstiTransfer={uploadingFailedConstiTransfer}
                              uploadedConstiTransfer={uploadedConstiTransfer}
                              handleConstiTransfer={handleConstiTransfer}
                              constisList={constisList}
                           ></ElecCandiConstiTransfer>
                        </Box>
                     </Modal>
                     <Modal
                        open={openModalOnlineSupport}
                        onClose={closeModalOnlineSupport}
                        aria-labelledby="modal-modal-title-addOnlineSupport"
                        aria-describedby="modal-modal-description-addOnlineSupport"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-addOnlineSupport"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              Support a candidate for <strong>{selectedConstiName.toUpperCase()}</strong> {'\n'}{' '}
                              <strong>{selectedConstiNameHindi}</strong> के लिए एक प्रत्याशी का समर्थन करें
                           </Typography>
                           <AddCandiOnlineSupport
                              actionForSupport={actionForSupport}
                              candidatesListAccepted={candidatesListAccepted}
                              uploadingOnlineSupport={uploadingOnlineSupport}
                              uploadingFailedOnlineSupport={uploadingFailedOnlineSupport}
                              uploadedOnlineSupport={uploadedOnlineSupport}
                              addOnlineSupport={addOnlineSupport}
                              cancelOnlineSupport={cancelOnlineSupport}
                           ></AddCandiOnlineSupport>
                        </Box>
                     </Modal>
                     <Dialog
                        open={openDialogNotLoggedIn}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialogNotLoggedIn}
                        aria-describedby="alert-dialog-slide-notLoggedIn"
                     >
                        <DialogTitle
                           sx={{
                              whiteSpace: 'pre-line',
                              textAlign: 'center',
                           }}
                        >{`Please log in. ${'\n'} कृपया लॉगिन करें।`}</DialogTitle>
                        <DialogContent>
                           <DialogContentText
                              id="alert-dialog-slide-notLoggedIn"
                              sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                           >
                              You are not logged-in.
                              {'\n'}
                              आपने लॉग - इन नहीं किया है।
                           </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={handleCloseDialogNotLoggedIn}>Close</Button>
                        </DialogActions>
                     </Dialog>
                     <Dialog
                        open={openDialogDatePassed}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialogDatePassed}
                        aria-describedby="alert-dialog-slide-datePassed"
                     >
                        <DialogTitle
                           sx={{
                              whiteSpace: 'pre-line',
                              textAlign: 'center',
                           }}
                        >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                        <DialogContent>
                           <DialogContentText
                              id="alert-dialog-slide-datePassed"
                              sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                           >
                              The date for <strong>phase {selectedConstiPhaseNum}</strong> of{' '}
                              <strong>{elec.Name}</strong> is over for this facility.
                           </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={handleCloseDialogDatePassed}>Close</Button>
                        </DialogActions>
                     </Dialog>
                     {/* <Dialog
                        open={openDialogHours}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialogHours}
                        aria-describedby="alert-dialog-slide-hours"
                     >
                        <DialogTitle
                           sx={{
                              whiteSpace: 'pre-line',
                              textAlign: 'center',
                           }}
                        >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                        <DialogContent>
                           <DialogContentText
                              id="alert-dialog-slide-hours"
                              sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                           >
                              Hours remaining.
                           </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={handleCloseDialogHours}>Close</Button>
                        </DialogActions>
                     </Dialog> */}
                     {/* <Dialog
                        open={openDialogSameCandi}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialogSameCandi}
                        aria-describedby="alert-dialog-slide-sameCandi"
                     >
                        <DialogTitle
                           sx={{
                              whiteSpace: 'pre-line',
                              textAlign: 'center',
                           }}
                        >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                        <DialogContent>
                           <DialogContentText
                              id="alert-dialog-slide-hours"
                              sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                           >
                              <Typography sx={{ color: '#4caf50' }}>
                                 Thanks for showing support to your favourite candidate.
                              </Typography>
                              <br />
                              You have supported the same candidate you supported last time. Both will be counted as
                              one.
                              <br />
                              <strong>
                                 Please make sure to go to the polling booth on{' '}
                                 {moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')} to caste your vote.
                                 This is your national duty.
                              </strong>
                              <br />
                              <Typography sx={{ color: '#4caf50' }}>
                                 अपने पसंदीदा प्रत्याशी को समर्थन दिखाने के लिए धन्यवाद।
                              </Typography>
                              <br />
                              आपने उसी प्रत्याशी का समर्थन किया है जिसका आपने पिछली बार समर्थन किया था। दोनों को एक ही
                              माना जाएगा।
                              <br />
                              <strong>
                                 कृपया {moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')} को मतदान केंद्र
                                 पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                              </strong>
                           </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={handleCloseDialogSameCandi}>Close</Button>
                        </DialogActions>
                     </Dialog> */}
                     {/* <Dialog
                        open={openDialogDifferentCandi}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialogDifferentCandi}
                        aria-describedby="alert-dialog-slide-differentCandi"
                     >
                        <DialogTitle
                           sx={{
                              whiteSpace: 'pre-line',
                              textAlign: 'center',
                           }}
                        >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                        <DialogContent>
                           <DialogContentText
                              id="alert-dialog-slide-differentCandi"
                              sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                           >
                              <Typography sx={{ color: '#4caf50' }}>
                                 Thanks for showing support to your favourite candidate.
                              </Typography>
                              <br />
                              You have supported a candidate other than the one you supported last time. The earlier
                              support will be dismissed and this support will be counted.
                              <br />
                              <strong>
                                 Please make sure to go to the polling booth on{' '}
                                 {moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')} to caste your vote.
                                 This is your national duty.
                              </strong>
                              <br />
                              <Typography sx={{ color: '#4caf50' }}>
                                 अपने पसंदीदा प्रत्याशी को समर्थन दिखाने के लिए धन्यवाद।
                              </Typography>
                              <br />
                              आपने पिछली बार जिस प्रत्याशी का समर्थन किया था, उसके अलावा किसी अन्य प्रत्याशी का समर्थन
                              किया है। पहले किया गया समर्थन निरस्त कर दिया जाएगा और इस समर्थन को गिना जाएगा।
                              <br />
                              <strong>
                                 कृपया {moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')} को मतदान केंद्र
                                 पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                              </strong>
                           </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={handleCloseDialogDifferentCandi}>Close</Button>
                        </DialogActions>
                     </Dialog> */}
                     <Dialog
                        id="atOtherConsti"
                        open={openDialogAtOtherConsti}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialogAtOtherConsti}
                        aria-describedby="alert-dialog-slide-atOtherConsti"
                     >
                        <DialogTitle
                           sx={{
                              whiteSpace: 'pre-line',
                              textAlign: 'center',
                           }}
                        >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                        <DialogContent>
                           <DialogContentText
                              id="alert-dialog-slide-atOtherConsti"
                              sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                           >
                              <strong>
                                 You are attached to some other constituency at the same level. Hence you cant support
                                 candidate of this constituency.
                              </strong>
                              {'\n'}
                              आप इस स्तर पर किसी अन्य निर्वाचन क्षेत्र से जुड़े हुए हैं। इसलिए आप इस निर्वाचन क्षेत्र के
                              प्रत्याशी का समर्थन नहीं कर सकते।
                           </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={handleCloseDialogAtOtherConsti}>Close</Button>
                        </DialogActions>
                     </Dialog>
                     <Dialog
                        open={openDialogAlreadySupported}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialogAlreadySupported}
                        aria-describedby="alert-dialog-slide-alreadySupported"
                     >
                        <DialogTitle
                           sx={{
                              whiteSpace: 'pre-line',
                              textAlign: 'center',
                           }}
                        >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                        <DialogContent>
                           {selectedConstiPhaseDate && (
                              <DialogContentText
                                 id="alert-dialog-slide-alreadySupported"
                                 sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                              >
                                 <Typography sx={{ color: '#4caf50' }}>
                                    You have already shown support to your favourite candidate.
                                 </Typography>
                                 <br />
                                 <strong>
                                    Please make sure to go to the polling booth on{' '}
                                    {moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')} to caste your vote.
                                    This is your national duty.
                                 </strong>
                                 <br />
                                 <Typography sx={{ color: '#4caf50' }}>
                                    आपने पहले ही अपने पसंदीदा प्रत्याशी को समर्थन दे दिया है।
                                 </Typography>
                                 <br />
                                 <strong>
                                    कृपया {moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')} को मतदान
                                    केंद्र पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                                 </strong>
                              </DialogContentText>
                           )}
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={handleCloseDialogAlreadySupported}>Close</Button>
                        </DialogActions>
                     </Dialog>
                     <Dialog
                        open={openDialogThanks}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleCloseDialogThanks}
                        aria-describedby="alert-dialog-slide-Thanks"
                     >
                        <DialogTitle
                           sx={{
                              whiteSpace: 'pre-line',
                              textAlign: 'center',
                           }}
                        >{`Online Support ${'\n'} ऑनलाइन समर्थन`}</DialogTitle>
                        <DialogContent>
                           {selectedConstiPhaseDate && (
                              <DialogContentText
                                 id="alert-dialog-slide-su"
                                 sx={{ whiteSpace: 'pre-line', color: 'Crimson' }}
                              >
                                 <Typography sx={{ color: '#4caf50' }}>
                                    Thanks for showing support to your favourite candidate.
                                 </Typography>
                                 <br />
                                 <strong>
                                    Please make sure to go to the polling booth on{' '}
                                    {moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')} to caste your vote.
                                    This is your national duty.
                                 </strong>
                                 <br />
                                 <Typography sx={{ color: '#4caf50' }}>
                                    अपने पसंदीदा प्रत्याशी को समर्थन दिखाने के लिए धन्यवाद।
                                 </Typography>
                                 <br />
                                 <strong>
                                    कृपया {moment(selectedConstiPhaseDate.toDate()).format('Do MMM YYYY')} को मतदान
                                    केंद्र पर जाकर अपना वोट अवश्य डालें। यह आपका राष्ट्रीय कर्तव्य है।
                                 </strong>
                              </DialogContentText>
                           )}
                        </DialogContent>
                        <DialogActions>
                           <Button onClick={handleCloseDialogThanks}>Close</Button>
                        </DialogActions>
                     </Dialog>
                     <Modal
                        open={openModalVotes}
                        onClose={handleCloseModalVotes}
                        aria-labelledby="modal-modal-title-addVotes"
                        aria-describedby="modal-modal-description-addVotes"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-addVotes"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              {selectedCandidate !== '' && `Update Votes of ${selectedCandidate.Name.toUpperCase()}`}
                           </Typography>
                           <AddNumber handleAddNumber={handleAddVotes}></AddNumber>
                        </Box>
                     </Modal>
                     <Modal
                        open={openModalWinner}
                        onClose={handleCloseModalWinner}
                        aria-labelledby="modal-modal-title-addWinner"
                        aria-describedby="modal-modal-description-addWinner"
                        disableScrollLock
                     >
                        <Box sx={styleModalBox}>
                           <Typography
                              id="modal-modal-title-addWinner"
                              variant="h6"
                              component="h6"
                              align="center"
                              sx={styleModalTypography}
                           >
                              {selectedCandidate !== '' &&
                                 `Update WINNER status of ${selectedCandidate.Name.toUpperCase()}`}
                           </Typography>
                           <AddWinner handleAddWinner={handleAddWinner}></AddWinner>
                        </Box>
                     </Modal>
                  </Box>
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecCandis.propTypes = {
   legis: PropTypes.object.isRequired,
   elec: PropTypes.object.isRequired,
   constituencyId: PropTypes.string.isRequired,
   candidatesListRoot: PropTypes.array.isRequired,
   handleCandidatesListRootUpdate: PropTypes.func.isRequired,
}

export default ElecCandis
