import { Alert, Box, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

function ConstituencyLeaders({ constituency, leaders }) {
   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - Leaders for ${constituency.Name}(${constituency.ConstituencyNumber}) - Constituency of ${constituency.LegislatureName}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               fontWeight: 500,
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               pl: 1,
            }}
         >
            Leaders for this constituency / इस निर्वाचन क्षेत्र के नेता:
         </Typography>
         <Box>
            <Alert
               variant="outlined"
               severity="success"
               sx={{ mx: 5, mt: 5, justifyContent: 'center', alignItems: 'center' }}
            >
               This facility will be available soon.
               <br />
               यह सुविधा शीघ्र ही उपलब्ध होगी।
            </Alert>
         </Box>
      </Box>
   )
}

ConstituencyLeaders.propTypes = {
   constituency: PropTypes.object.isRequired,
   leaders: PropTypes.array.isRequired,
}

export default ConstituencyLeaders
