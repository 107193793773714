import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Accordion,
   AccordionActions,
   AccordionDetails,
   AccordionSummary,
   Alert,
   AlertTitle,
   Box,
   Button,
   Link,
   List,
   ListItem,
   ListItemText,
   Modal,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableRow,
   Typography,
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../../../FirebaseConfig'
import AddElectionPhase from './AddElectionPhase'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'

import moment from 'moment'

import { useNavigate } from 'react-router-dom'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { Helmet } from 'react-helmet'

function ElecBasicInfo({ props }) {
   const elec = props

   const user = useSelector(selectUsers)
   const ip = user.ip

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [expanded, setExpanded] = useState(false)

   const handleChangeAccordianExpand = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
   }

   // related to phases list
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [phaseList, setPhaseList] = useState([])

   const fetchElec = async () => {
      // Note:
      // phases are already here in the elec object
      // however after adding a new phase, there was difficulty in displaying the new date under moment
      // hence this fucntion which will be used after adding a new phase
      try {
         const docRef = doc(db, 'Elections', elec.id)
         const docSnap = await getDoc(docRef)

         const sortedPhases = [...docSnap.data().Phases].sort((a, b) => a.PhaseNum - b.PhaseNum)
         setPhaseList(sortedPhases)
      } catch (err) {
         console.log('error', err)
      }
   }

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         const sortedPhases = [...elec.Phases].sort((a, b) => a.PhaseNum - b.PhaseNum)
         setPhaseList(sortedPhases)
         setFirstLoadStatus('success')
      }
   }, [])

   useEffect(() => {
      if (phaseList.length > 0) {
         const item = phaseList[0]
         setExpanded(item.PhaseNum)
      }
   }, [phaseList])

   // related to form filling and submission of phases

   const [openModalAddPhase, setOpenModalAddPhase] = useState(false)
   const [dataCase, setDataCase] = useState('')
   const [selectedPhaseNum, setSelectedPhaseNum] = useState('')
   const [selectedScheduleNum, setSelectedScheduleNum] = useState('')
   const [selectedPhase, setSelectedPhase] = useState({})

   const handleOpenModalAddPhase = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedPhaseNum('')
            setSelectedScheduleNum('')
            setSelectedPhase({})
            setDataCase('Original')
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleOpenModalEditPhase = (e, phaseNum, scheduleNum) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setSelectedPhaseNum(phaseNum)
            console.log('phaseNum', phaseNum)
            setSelectedScheduleNum(scheduleNum)
            console.log('scheduleNum', scheduleNum)
            setSelectedPhase(phaseList.find((item) => item.PhaseNum === phaseNum))

            console.log('stage: ', 3)
            setDataCase('Changed')
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   useEffect(() => {
      if (dataCase !== '') {
         console.log('stage dataCase: ', dataCase)
         setOpenModalAddPhase(true)
      } else {
         setOpenModalAddPhase(false)
      }
   }, [dataCase])

   const handleCloseModalAddPhase = () => setOpenModalAddPhase(false)

   const [lastPhase, setLastPhase] = useState('')
   const [uploading, setUploading] = useState(false)
   const [uploaded, setUploaded] = useState(false)
   const [uploadingFailed, setUploadingFailed] = useState(false)

   const handleAddPhase = async (phaseDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            if (
               phaseDetails.PhaseNum !== '' &&
               !isNaN(phaseDetails.PhaseNum) &&
               phaseDetails.PhaseNum > 0 &&
               phaseDetails.DateOfNotification !== '' &&
               phaseDetails.DateOfNominationStart !== '' &&
               phaseDetails.DateOfNominationScrutiny !== '' &&
               phaseDetails.DateOfWithdrawal !== '' > 0
            ) {
               phaseDetails.CrBy = auth.currentUser.uid
               phaseDetails.CrDt = new Date()
               phaseDetails.CrIP = ip.data.ip

               console.log('phaseDetails 1: ', phaseDetails)

               setLastPhase(phaseDetails.PhaseNum.toString())
               setUploading(true)

               console.log('phaseDetails 2: ', phaseDetails, ' uploading: ', uploading)

               try {
                  console.log('add start')

                  if (dataCase === 'Original') {
                     console.log('add start - entered original')

                     const docRef = doc(db, 'Elections', elec.id)

                     await updateDoc(docRef, {
                        Phases: arrayUnion(phaseDetails),
                     })
                  } else if (dataCase === 'Changed') {
                     console.log('add start - entered Changed')

                     console.log('Date 1', selectedPhase.DateOfNotification)
                     console.log('Date 1-1', selectedPhase.DateOfNotification.toDate())
                     console.log('Date 2: ', new Date(selectedPhase.DateOfNotification.toDate()).toDateString())
                     console.log(
                        'Date 3: ',
                        new Date(new Date(selectedPhase.DateOfNotification.toDate()).toDateString()),
                     )

                     // console.log('Date 4', phaseDetails.DateOfNotification.toDateString())
                     console.log('Date 5: ', new Date(phaseDetails.DateOfNotification).toDateString())
                     console.log('Date 6: ', new Date(new Date(phaseDetails.DateOfNotification).toDateString()))

                     const notification =
                        new Date(selectedPhase.DateOfNotification.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfNotification).toDateString()
                           ? {
                                DateOfNotification: selectedPhase.DateOfNotification,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfNotification: selectedPhase.DateOfNotification,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const nominationStart =
                        new Date(selectedPhase.DateOfNominationStart.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfNominationStart).toDateString()
                           ? {
                                DateOfNominationStart: selectedPhase.DateOfNominationStart,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfNominationStart: selectedPhase.DateOfNominationStart,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const nominationEnd =
                        new Date(selectedPhase.DateOfNominationEnd.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfNominationEnd).toDateString()
                           ? {
                                DateOfNominationEnd: selectedPhase.DateOfNominationEnd,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfNominationEnd: selectedPhase.DateOfNominationEnd,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const scrutiny =
                        new Date(selectedPhase.DateOfNominationScrutiny.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfNominationScrutiny).toDateString()
                           ? {
                                DateOfNominationScrutiny: selectedPhase.DateOfNominationScrutiny,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfNominationScrutiny: selectedPhase.DateOfNominationScrutiny,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const withdrawal =
                        new Date(selectedPhase.DateOfWithdrawal.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfWithdrawal).toDateString()
                           ? {
                                DateOfWithdrawal: selectedPhase.DateOfWithdrawal,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfWithdrawal: selectedPhase.DateOfWithdrawal,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const publicCanvassingEnd =
                        new Date(selectedPhase.DateOfPublicCanvassingEnd.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfPublicCanvassingEnd).toDateString()
                           ? {
                                DateOfPublicCanvassingEnd: selectedPhase.DateOfPublicCanvassingEnd,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfPublicCanvassingEnd: selectedPhase.DateOfPublicCanvassingEnd,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const doorCanvassingEnd =
                        new Date(selectedPhase.DateOfDoorCanvassingEnd.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfDoorCanvassingEnd).toDateString()
                           ? {
                                DateOfDoorCanvassingEnd: selectedPhase.DateOfDoorCanvassingEnd,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfDoorCanvassingEnd: selectedPhase.DateOfDoorCanvassingEnd,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const poll =
                        new Date(selectedPhase.DateOfPoll.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfPoll).toDateString()
                           ? {
                                DateOfPoll: selectedPhase.DateOfPoll,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfPoll: selectedPhase.DateOfPoll,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const pollEnd =
                        new Date(selectedPhase.DateOfPollEnd.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfPollEnd).toDateString()
                           ? {
                                DateOfPollEnd: selectedPhase.DateOfPollEnd,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfPollEnd: selectedPhase.DateOfPollEnd,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const counting =
                        new Date(selectedPhase.DateOfCounting.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfCounting).toDateString()
                           ? {
                                DateOfCounting: selectedPhase.DateOfCounting,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfCounting: selectedPhase.DateOfCounting,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }
                     const completion =
                        new Date(selectedPhase.DateOfCompletion.toDate()).toDateString() ===
                        new Date(phaseDetails.DateOfCompletion).toDateString()
                           ? {
                                DateOfCompletion: selectedPhase.DateOfCompletion,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: true,
                             }
                           : {
                                DateOfCompletion: selectedPhase.DateOfCompletion,
                                ModBy: auth.currentUser.uid,
                                MoDt: new Date(),
                                ModIP: ip.data.ip,
                                Duplicate: false,
                             }

                     const record = {
                        PhaseNum: selectedPhaseNum,
                        ScheduleNum: selectedScheduleNum,
                        DateOfNotification: phaseDetails.DateOfNotification,
                        DateOfNotificationOld:
                           selectedPhase.DateOfNotificationOld.length > 0
                              ? [...selectedPhase.DateOfNotificationOld, notification]
                              : [notification],
                        DateOfNominationStart: phaseDetails.DateOfNominationStart,
                        DateOfNominationStartOld:
                           selectedPhase.DateOfNominationStartOld.length > 0
                              ? [...selectedPhase.DateOfNominationStartOld, nominationStart]
                              : [nominationStart],
                        DateOfNominationEnd: phaseDetails.DateOfNominationEnd,
                        DateOfNominationEndOld:
                           selectedPhase.DateOfNominationEndOld.length > 0
                              ? [...selectedPhase.DateOfNominationEndOld, nominationEnd]
                              : [nominationEnd],
                        DateOfNominationScrutiny: phaseDetails.DateOfNominationScrutiny,
                        DateOfNominationScrutinyOld:
                           selectedPhase.DateOfNominationScrutinyOld.length > 0
                              ? [...selectedPhase.DateOfNominationScrutinyOld, scrutiny]
                              : [scrutiny],
                        DateOfWithdrawal: phaseDetails.DateOfWithdrawal,
                        DateOfWithdrawalOld:
                           selectedPhase.DateOfWithdrawalOld.length > 0
                              ? [...selectedPhase.DateOfWithdrawalOld, withdrawal]
                              : [withdrawal],
                        DateOfPublicCanvassingEnd: phaseDetails.DateOfPublicCanvassingEnd,
                        DateOfPublicCanvassingEndOld:
                           selectedPhase.DateOfPublicCanvassingEnd.length > 0
                              ? [...selectedPhase.DateOfPublicCanvassingEndOld, publicCanvassingEnd]
                              : [publicCanvassingEnd],
                        DateOfDoorCanvassingEnd: phaseDetails.DateOfDoorCanvassingEnd,
                        DateOfDoorCanvassingEndOld:
                           selectedPhase.DateOfDoorCanvassingEndOld.length > 0
                              ? [...selectedPhase.DateOfDoorCanvassingEndOld, doorCanvassingEnd]
                              : [doorCanvassingEnd],
                        DateOfPoll: phaseDetails.DateOfPoll,
                        DateOfPollOld:
                           selectedPhase.DateOfPollOld.length > 0 ? [...selectedPhase.DateOfPollOld, poll] : [poll],
                        DateOfPollEnd: phaseDetails.DateOfPollEnd,
                        DateOfPollEndOld:
                           selectedPhase.DateOfPollEndOld.length > 0
                              ? [...selectedPhase.DateOfPollEndOld, pollEnd]
                              : [pollEnd],
                        DateOfCounting: phaseDetails.DateOfCounting,
                        DateOfCountingOld:
                           selectedPhase.DateOfCountingOld.length > 0
                              ? [...selectedPhase.DateOfCountingOld, counting]
                              : [counting],
                        DateOfCompletion: phaseDetails.DateOfCompletion,
                        DateOfCompletionOld:
                           selectedPhase.DateOfCompletionOld.length > 0
                              ? [...selectedPhase.DateOfCompletionOld, completion]
                              : [completion],
                        Description:
                           phaseDetails.Description.length > 0
                              ? selectedPhase.Description.length > 0
                                 ? [...selectedPhase.Description, ...phaseDetails.Description]
                                 : phaseDetails.Description
                              : selectedPhase.Description,
                        IsDeleted: false,
                        IsDeletedRecord: {},
                        IsCancelled: false,
                        IsCancelledRecord: {},
                        CrBy: selectedPhase.CrBy,
                        CrDt: selectedPhase.CrDt,
                        CrIP: selectedPhase.CrIP,
                     }

                     console.log('add start - entered Changed - 3')

                     const docRef = doc(db, 'Elections', elec.id)

                     await updateDoc(docRef, {
                        Phases: arrayUnion(record),
                     })

                     await updateDoc(docRef, {
                        Phases: arrayRemove(selectedPhase),
                     })

                     console.log('record:', record)
                     setOpenModalAddPhase(false)
                  }

                  // Note:
                  // setPhaseList([...phaseList, phaseDetails]) was causing problem / error for date adjustment in the list
                  // hence, on insert, we fetch the election data afresh, as follows

                  fetchElec()

                  setUploading(false)
                  setUploaded(true)
                  setUploadingFailed(false)
               } catch (error) {
                  alert('Error adding election 22: ', error.message)
                  console.log('Error adding electione', error)
                  setUploading(false)
                  setUploaded(false)
                  setUploadingFailed(true)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDeclareResults = async (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to declare the results?')) {
               try {
                  const docRef = doc(db, 'Elections', elec.id)

                  await updateDoc(docRef, {
                     ResultsDeclared: true,
                  })

                  elec.ResultsDeclared = true
               } catch (error) {
                  alert('Error declaring results!')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   /// cancel a phase

   const handleOpenModalCancelPhase = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            // setOpenModalAddPhase(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box>
         <div>
            <Helmet>
               <title>{`Track Democracy - ${elec.Name}`}</title>
            </Helmet>
         </div>
         {elec.IsSpeculated && (
            <Box>
               <Alert variant="outlined" severity="error" sx={{ mx: 5, mt: 5 }}>
                  <strong>
                     <u>Disclaimer:</u>
                  </strong>{' '}
                  The <strong>dates</strong> of this election <strong>are speculated</strong> for the purpose of
                  display. Please wait for the actual announcement by the Election Commission of India.
                  <br />
                  <br />
                  <strong>
                     <u>अस्वीकरण:</u>
                  </strong>{' '}
                  इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से <strong>अनुमान</strong> हैं। कृपया भारत
                  के चुनाव आयोग द्वारा वास्तविक घोषणा की प्रतीक्षा करें।
               </Alert>
            </Box>
         )}
         {!elec.IsSpeculated && (
            <Box>
               <Box sx={{ display: 'flex', px: 1, mt: 1, flexDirection: 'column' }}>
                  <Typography variant="subtitle2">
                     This election is for <strong>House number {elec.HouseNumber}</strong> of{' '}
                     <Link
                        href={`/legislature/${elec.LegislatureName}/${elec.Legislature_id}`}
                        sx={{ textDecoration: 'none !important', px: 0 }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        {elec.LegislatureName}
                     </Link>
                     .
                  </Typography>
                  <Typography variant="subtitle2" component="h6">
                     यह चुनाव{' '}
                     <Link
                        href={`/legislature/${elec.LegislatureName}/${elec.Legislature_id}`}
                        sx={{ textDecoration: 'none !important', px: 0 }}
                        rel="noreferrer"
                        target="_blank"
                     >
                        {elec.LegislatureNameHindi}
                     </Link>{' '}
                     के सदन संख्या {elec.HouseNumber} के लिए है|
                  </Typography>
               </Box>
               <Box sx={{ display: 'flex', px: 1, mt: 1, flexDirection: 'column' }}>
                  {(isSuper || isDataEditor) && (
                     <Box>
                        <Button
                           onClick={(e) => {
                              handleOpenModalAddPhase(e)
                           }}
                           size="small"
                           variant="outlined"
                           sx={{ py: 0, px: 1, minWidth: 0, mr: 2 }}
                        >
                           Add a phase
                        </Button>
                        <Button
                           onClick={(e) => {
                              handleDeclareResults(e)
                           }}
                           size="small"
                           variant="outlined"
                           sx={{ py: 0, px: 1, minWidth: 0 }}
                        >
                           Declare results
                        </Button>

                        <Modal
                           open={openModalAddPhase}
                           onClose={handleCloseModalAddPhase}
                           aria-labelledby="modal-modal-title-addElec"
                           aria-describedby="modal-modal-description-addElec"
                           disableScrollLock
                        >
                           <Box sx={styleModalBox}>
                              <Typography
                                 id="modal-modal-title-addElec"
                                 variant="h6"
                                 component="h6"
                                 align="center"
                                 sx={styleModalTypography}
                              >
                                 Add a phase
                              </Typography>
                              <Box>
                                 <AddElectionPhase
                                    lastPhase={lastPhase}
                                    uploading={uploading}
                                    uploaded={uploaded}
                                    uploadingFailed={uploadingFailed}
                                    dataCase={dataCase}
                                    selectedPhase={selectedPhase}
                                    handleAddPhase={handleAddPhase}
                                 ></AddElectionPhase>
                              </Box>
                           </Box>
                        </Modal>
                     </Box>
                  )}
               </Box>

               <Box sx={{ m: 1 }}>
                  {phaseList && phaseList.length > 0 ? (
                     phaseList.map((item, index) => (
                        <Accordion
                           key={item.PhaseNum}
                           expanded={expanded === item.PhaseNum}
                           onChange={handleChangeAccordianExpand(item.PhaseNum)}
                           defaultExpanded={index === 0}
                           sx={{
                              mt: 0.5,
                              '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                              '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                           }}
                        >
                           <AccordionSummary
                              expandIcon={<ArrowDropDownIcon style={{ color: '#FFFFFF' }} />}
                              aria-controls={`${item.PhaseNum}-content`}
                              id={`${item.PhaseNum}-header`}
                              sx={{
                                 backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
                                 color: '#FFFFFF',
                              }}
                           >
                              <strong>{`Pahse / चरण: ${item.PhaseNum} `}</strong> &nbsp;{' '}
                              {` (Schedule / सारणी: ${item.ScheduleNum})`}
                           </AccordionSummary>
                           <AccordionDetails>
                              <TableContainer component={Paper}>
                                 <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
                                    <TableBody>
                                       <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row" sx={{ pl: 0.5, pr: 0 }}>
                                             Notification / अधिसूचना:
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontWeight: 500, px: 0 }}>
                                             <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                             >
                                                {item.DateOfNotification &&
                                                   moment(item.DateOfNotification.toDate()).format('Do MMM YYYY')}
                                                {item.DateOfNotificationOld.length > 0 && (
                                                   <List
                                                      dense
                                                      sx={{
                                                         p: 0,
                                                         '& .MuiListItemText-primary': {
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                         },
                                                         '& .MuiListItemText-root': {
                                                            m: 0,
                                                         },
                                                         '& .MuiListItem-root': {
                                                            p: 0,
                                                         },
                                                      }}
                                                   >
                                                      {item.DateOfNotificationOld.map((item, index) => (
                                                         <ListItem key={index}>
                                                            <ListItemText
                                                               primaryTypographyProps={{}}
                                                               primary={
                                                                  item.DateOfNotification &&
                                                                  !item.Duplicate &&
                                                                  moment(item.DateOfNotification.toDate()).format(
                                                                     'Do MMM YYYY',
                                                                  )
                                                               }
                                                               secondary={null}
                                                            />
                                                         </ListItem>
                                                      ))}
                                                   </List>
                                                )}
                                             </Box>
                                          </TableCell>
                                       </TableRow>
                                       <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row" sx={{ pl: 0.5, pr: 0 }}>
                                             Nomination start / नामांकन आरम्भ:
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontWeight: 500, px: 0 }}>
                                             <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                             >
                                                {item.DateOfNominationStart &&
                                                   moment(item.DateOfNominationStart.toDate()).format('Do MMM YYYY')}
                                                {item.DateOfNominationStartOld.length > 0 && (
                                                   <List
                                                      dense
                                                      sx={{
                                                         p: 0,
                                                         '& .MuiListItemText-primary': {
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                         },
                                                         '& .MuiListItemText-root': {
                                                            m: 0,
                                                         },
                                                         '& .MuiListItem-root': {
                                                            p: 0,
                                                         },
                                                      }}
                                                   >
                                                      {item.DateOfNominationStartOld.map((item, index) => (
                                                         <ListItem key={index}>
                                                            <ListItemText
                                                               primaryTypographyProps={{}}
                                                               primary={
                                                                  item.DateOfNominationStart &&
                                                                  !item.Duplicate &&
                                                                  moment(item.DateOfNominationStart.toDate()).format(
                                                                     'Do MMM YYYY',
                                                                  )
                                                               }
                                                               secondary={null}
                                                            />
                                                         </ListItem>
                                                      ))}
                                                   </List>
                                                )}
                                             </Box>
                                          </TableCell>
                                       </TableRow>
                                       <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row" sx={{ pl: 0.5, pr: 0 }}>
                                             Nomination end / नामांकन समाप्ति:
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontWeight: 500, px: 0 }}>
                                             <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                             >
                                                {item.DateOfNominationEnd &&
                                                   moment(item.DateOfNominationEnd.toDate()).format('Do MMM YYYY')}
                                                {item.DateOfNominationEndOld.length > 0 && (
                                                   <List
                                                      dense
                                                      sx={{
                                                         p: 0,
                                                         '& .MuiListItemText-primary': {
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                         },
                                                         '& .MuiListItemText-root': {
                                                            m: 0,
                                                         },
                                                         '& .MuiListItem-root': {
                                                            p: 0,
                                                         },
                                                      }}
                                                   >
                                                      {item.DateOfNominationEndOld.map((item, index) => (
                                                         <ListItem key={index}>
                                                            <ListItemText
                                                               primaryTypographyProps={{}}
                                                               primary={
                                                                  item.DateOfNominationEnd &&
                                                                  !item.Duplicate &&
                                                                  moment(item.DateOfNominationEnd.toDate()).format(
                                                                     'Do MMM YYYY',
                                                                  )
                                                               }
                                                               secondary={null}
                                                            />
                                                         </ListItem>
                                                      ))}
                                                   </List>
                                                )}
                                             </Box>
                                          </TableCell>
                                       </TableRow>
                                       <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row" sx={{ pl: 0.5, pr: 0 }}>
                                             Scrutiny / नामांकन समीक्षा:
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontWeight: 500, px: 0 }}>
                                             <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                             >
                                                {item.DateOfNominationScrutiny &&
                                                   moment(item.DateOfNominationScrutiny.toDate()).format('Do MMM YYYY')}
                                                {item.DateOfNominationScrutinyOld.length > 0 && (
                                                   <List
                                                      dense
                                                      sx={{
                                                         p: 0,
                                                         '& .MuiListItemText-primary': {
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                         },
                                                         '& .MuiListItemText-root': {
                                                            m: 0,
                                                         },
                                                         '& .MuiListItem-root': {
                                                            p: 0,
                                                         },
                                                      }}
                                                   >
                                                      {item.DateOfNominationScrutinyOld.map((item, index) => (
                                                         <ListItem key={index}>
                                                            <ListItemText
                                                               primaryTypographyProps={{}}
                                                               primary={
                                                                  item.DateOfNominationScrutiny &&
                                                                  !item.Duplicate &&
                                                                  moment(item.DateOfNominationScrutiny.toDate()).format(
                                                                     'Do MMM YYYY',
                                                                  )
                                                               }
                                                               secondary={null}
                                                            />
                                                         </ListItem>
                                                      ))}
                                                   </List>
                                                )}
                                             </Box>
                                          </TableCell>
                                       </TableRow>
                                       <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row" sx={{ pl: 0.5, pr: 0 }}>
                                             Last date of withdrawal / नाम वापसी:
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontWeight: 500, px: 0 }}>
                                             <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                             >
                                                {item.DateOfWithdrawal &&
                                                   moment(item.DateOfWithdrawal.toDate()).format('Do MMM YYYY')}
                                                {item.DateOfWithdrawalOld.length > 0 && (
                                                   <List
                                                      dense
                                                      sx={{
                                                         p: 0,
                                                         '& .MuiListItemText-primary': {
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                         },
                                                         '& .MuiListItemText-root': {
                                                            m: 0,
                                                         },
                                                         '& .MuiListItem-root': {
                                                            p: 0,
                                                         },
                                                      }}
                                                   >
                                                      {item.DateOfWithdrawalOld.map((item, index) => (
                                                         <ListItem key={index}>
                                                            <ListItemText
                                                               primaryTypographyProps={{}}
                                                               primary={
                                                                  item.DateOfWithdrawal &&
                                                                  !item.Duplicate &&
                                                                  moment(item.DateOfWithdrawal.toDate()).format(
                                                                     'Do MMM YYYY',
                                                                  )
                                                               }
                                                               secondary={null}
                                                            />
                                                         </ListItem>
                                                      ))}
                                                   </List>
                                                )}
                                             </Box>
                                          </TableCell>
                                       </TableRow>
                                       <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row" sx={{ pl: 0.5, pr: 0 }}>
                                             Poll / मतदान:
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontWeight: 500, px: 0 }}>
                                             <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                             >
                                                {item.DateOfPoll &&
                                                   moment(item.DateOfPoll.toDate()).format('Do MMM YYYY')}
                                                {item.DateOfPollOld.length > 0 && (
                                                   <List
                                                      dense
                                                      sx={{
                                                         p: 0,
                                                         '& .MuiListItemText-primary': {
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                         },
                                                         '& .MuiListItemText-root': {
                                                            m: 0,
                                                         },
                                                         '& .MuiListItem-root': {
                                                            p: 0,
                                                         },
                                                      }}
                                                   >
                                                      {item.DateOfPollOld.map((item, index) => (
                                                         <ListItem key={index}>
                                                            <ListItemText
                                                               primary={
                                                                  item.DateOfPoll &&
                                                                  !item.Duplicate &&
                                                                  moment(item.DateOfPoll.toDate()).format('Do MMM YYYY')
                                                               }
                                                               secondary={null}
                                                            />
                                                         </ListItem>
                                                      ))}
                                                   </List>
                                                )}
                                             </Box>
                                          </TableCell>
                                       </TableRow>
                                       <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row" sx={{ pl: 0.5, pr: 0 }}>
                                             Counting / गणना:
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontWeight: 500, px: 0 }}>
                                             <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                             >
                                                {item.DateOfCounting &&
                                                   moment(item.DateOfCounting.toDate()).format('Do MMM YYYY')}
                                                {item.DateOfCountingOld.length > 0 && (
                                                   <List
                                                      dense
                                                      sx={{
                                                         p: 0,
                                                         '& .MuiListItemText-primary': {
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                         },
                                                         '& .MuiListItemText-root': {
                                                            m: 0,
                                                         },
                                                         '& .MuiListItem-root': {
                                                            p: 0,
                                                         },
                                                      }}
                                                   >
                                                      {item.DateOfCountingOld.map((item, index) => (
                                                         <ListItem key={index}>
                                                            <ListItemText
                                                               primaryTypographyProps={{}}
                                                               primary={
                                                                  item.DateOfCounting &&
                                                                  !item.Duplicate &&
                                                                  moment(item.DateOfCounting.toDate()).format(
                                                                     'Do MMM YYYY',
                                                                  )
                                                               }
                                                               secondary={null}
                                                            />
                                                         </ListItem>
                                                      ))}
                                                   </List>
                                                )}
                                             </Box>
                                          </TableCell>
                                       </TableRow>
                                       <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                          <TableCell component="th" scope="row" sx={{ pl: 0.5, pr: 0 }}>
                                             Completion / समापन:
                                          </TableCell>
                                          <TableCell align="right" sx={{ fontWeight: 500, px: 0 }}>
                                             <Box
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                             >
                                                {item.DateOfCompletion &&
                                                   moment(item.DateOfCompletion.toDate()).format('Do MMM YYYY')}
                                                {item.DateOfCompletionOld.length > 0 && (
                                                   <List
                                                      dense
                                                      sx={{
                                                         p: 0,
                                                         '& .MuiListItemText-primary': {
                                                            textDecoration: 'line-through',
                                                            color: 'text.secondary',
                                                         },
                                                         '& .MuiListItemText-root': {
                                                            m: 0,
                                                         },
                                                         '& .MuiListItem-root': {
                                                            p: 0,
                                                         },
                                                      }}
                                                   >
                                                      {item.DateOfCompletionOld.map((item, index) => (
                                                         <ListItem key={index}>
                                                            <ListItemText
                                                               primaryTypographyProps={{}}
                                                               primary={
                                                                  item.DateOfCompletion &&
                                                                  !item.Duplicate &&
                                                                  moment(item.DateOfCompletion.toDate()).format(
                                                                     'Do MMM YYYY',
                                                                  )
                                                               }
                                                               secondary={null}
                                                            />
                                                         </ListItem>
                                                      ))}
                                                   </List>
                                                )}
                                             </Box>
                                          </TableCell>
                                       </TableRow>
                                    </TableBody>
                                 </Table>
                              </TableContainer>
                           </AccordionDetails>
                           <AccordionActions>
                              {(isSuper || isDataEditor) && (
                                 <Button
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                    onClick={(e) => {
                                       handleOpenModalCancelPhase(e, item.PhaseNum)
                                    }}
                                 >
                                    Cancel
                                 </Button>
                              )}
                              {(isSuper || isDataEditor) && (
                                 <Button
                                    sx={{ py: 0, px: 1, minWidth: 0 }}
                                    onClick={(e) => {
                                       handleOpenModalEditPhase(e, item.PhaseNum, item.ScheduleNum)
                                    }}
                                 >
                                    Change dates
                                 </Button>
                              )}
                           </AccordionActions>
                        </Accordion>
                     ))
                  ) : (
                     <Box>
                        <Alert
                           variant="outlined"
                           severity="warning"
                           sx={{ margin: 1, justifyContent: 'center', alignItems: 'center' }}
                        >
                           <AlertTitle sx={{ fontSize: 12 }}>
                              Details to be available soon. <br /> विवरण शीघ्र उपलब्ध होगा |
                           </AlertTitle>
                        </Alert>
                     </Box>
                  )}
               </Box>
            </Box>
         )}
      </Box>
   )
}

ElecBasicInfo.propTypes = {
   props: PropTypes.object.isRequired,
}

export default ElecBasicInfo
