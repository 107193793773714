import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
   Avatar,
   Box,
   Button,
   Divider,
   FormControl,
   IconButton,
   InputLabel,
   Link,
   List,
   ListItem,
   ListItemAvatar,
   ListItemText,
   MenuItem,
   Modal,
   Select,
   Typography,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { selectUsers } from '../../../redux/reducers/users-slice'
import { useNavigate } from 'react-router-dom'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import AddLegisMem from './AddLegisMem'
import { auth, db } from '../../../FirebaseConfig'
import { doc, getDoc } from 'firebase/firestore'

import DeleteIcon from '@mui/icons-material/Delete'

import styles from './LegisMems.module.css'
import { Helmet } from 'react-helmet'
import MyLoaderCircularGradient from '../../../Utils/Loaders/MyLoaderCircularGradient'
// import { useParams } from 'react-router-dom'

function LegisMems({ legis, membersListRoot, handleMembersListRootUpdate }) {
   //
   const user = useSelector(selectUsers)
   // const ip = user.ip
   // const countries = useSelector(selectCountries).countriesList

   const navigate = useNavigate()
   const [isDataEditor, setIsDataEditor] = useState(false)
   const [isSuper, setIsSuper] = useState(false)
   const [thisUser, setThisUser] = useState('')

   useEffect(() => {
      console.log('states - first use effect entered')

      if (user.currentUser !== null) {
         console.log('states - found user state not-null')
         setThisUser(user.currentUser.id)
         if (user.currentUser.superAdmin) {
            setIsSuper(true)
            setIsDataEditor(true)
         } else if (user.currentUser.dataAdmin) {
            setIsDataEditor(true)
         } else {
            setIsSuper(false)
            setIsDataEditor(false)
         }
      } else {
         console.log('states - found user state null')
         setThisUser('')
      }
   }, [user.currentUser])

   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')

   const [houseNumbersList, setHouseNumbersList] = useState([])
   const [selectedHouseNumber, setSelectedHouseNumber] = useState('')
   const [memCoreList, setMemCoreList] = useState('')
   const [uploadingMemCoreList, setUploadingMemCoreList] = useState(true)

   // const [lastMember, setLastMember] = useState('')
   // const [uploading, setUploading] = useState(false)
   // const [uploaded, setUploaded] = useState(false)
   // const [uploadingFailed, setUploadingFailed] = useState(false)

   useEffect(() => {
      if (firstLoadStatus === 'idle') {
         //
         console.log('legis:', legis)
         if (legis.HouseNumbers) {
            const sortedHouseNumbers = legis.HouseNumbers.sort((a, b) => b - a)
            setHouseNumbersList(sortedHouseNumbers)
         }
      }
   }, [])

   useEffect(() => {
      if (houseNumbersList && houseNumbersList.length > 0) {
         if (firstLoadStatus === 'idle') {
            setSelectedHouseNumber(houseNumbersList[0])
            console.log('house number at start: ', houseNumbersList[0])
            setFirstLoadStatus('success')
         }
      }
   }, [houseNumbersList])

   const handleChangeHouseNumber = async (e) => {
      e.preventDefault()

      setSelectedHouseNumber(e.target.value)
      console.log('Legis members - handle change HouseNumber')
   }

   useEffect(() => {
      if (selectedHouseNumber && selectedHouseNumber !== '') {
         const coreListId = 'MemCoreList-' + selectedHouseNumber
         fetchMemCoreList(coreListId)
      }
   }, [selectedHouseNumber])

   const fetchMemCoreList = async (coreListId) => {
      try {
         const docRef = doc(db, 'Legislatures', legis.id, 'MemCoreLists', coreListId)
         const docSnap = await getDoc(docRef)

         if (docSnap.exists()) {
            const sortedList = [...docSnap.data().Members].sort((a, b) => {
               if (b.Name.trim() > a.Name.trim()) {
                  return -1
               } else if (b.Name.trim() < a.Name.trim()) {
                  return 1
               } else {
                  return 0
               }
            })
            setMemCoreList(sortedList)
            setUploadingMemCoreList(false)
         } else {
            alert('does not exist')
            setUploadingMemCoreList(false)
         }
      } catch (err) {
         console.log('error', err)
      }
   }

   // related to form filling and submission

   const [openModalAddMember, setOpenModalAddMember] = useState(false)

   const handleOpenModalAddMember = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            setOpenModalAddMember(true)
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalAddMember = () => setOpenModalAddMember(false)

   const handleAddMember = async (constiDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isDataEditor || isSuper) {
            ///
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleDelete = async (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (isSuper) {
            if (confirm('Are you sure you want to remove this candidate?')) {
               try {
                  //
                  // await deleteDoc(doc(db, 'ElectionCandidates', thisCandidateId))
               } catch (error) {
                  alert('Error deleting district')
                  console.log(error)
               }
            }
         } else {
            navigate('/', { replace: true })
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   return (
      <Box sx={{ px: 1, minHeight: 600 }}>
         <div>
            <Helmet>
               <title>{`Track Democracy - Elected members of ${legis.Name}`}</title>
            </Helmet>
         </div>
         <Typography
            variant="subtitle1"
            color="#FFFFFF"
            component="div"
            sx={{
               backgroundImage: 'linear-gradient(to right, #3a6186, #89253e)',
               px: 1,
               py: 0.5,
               whiteSpace: 'pre-line',
               lineHeight: 1.2,
            }}
         >
            Elected Members: {'\n'} निर्वाचित सदस्य:
         </Typography>
         <Box>
            <Box id="addbutton" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 1 }}>
               {(isDataEditor || isSuper) && (
                  <Button
                     onClick={(e) => {
                        handleOpenModalAddMember(e)
                     }}
                     size="small"
                     variant="outlined"
                     sx={{ textTransform: 'none', py: 0, px: 1, minWidth: 0, mr: 1 }}
                  >
                     Add
                  </Button>
               )}
               <Box sx={{ display: 'flex', flexDirection: 'column', p: 0, ml: 'auto' }}>
                  <FormControl sx={{ m: 1, width: '30ch' }} size="small">
                     <InputLabel>House Number / सदन संख्या</InputLabel>
                     <Select
                        value={selectedHouseNumber}
                        label="House Number / सदन संख्या"
                        onChange={(e) => {
                           handleChangeHouseNumber(e)
                        }}
                        sx={{ backgroundColor: '#ffffff' }}
                     >
                        {houseNumbersList &&
                           houseNumbersList.map((item) => (
                              <MenuItem key={item} value={item}>
                                 {item}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Box>
            </Box>
            <Box id="sort" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', px: 1 }}>
               <>
                  <Box sx={{ display: 'inline', ml: 'auto', color: '#795548' }}></Box>
                  <Box sx={{ display: 'inline', padding: 0 }}></Box>
               </>
            </Box>
         </Box>
         <Box>
            {uploadingMemCoreList && (
               <MyLoaderCircularGradient title={'Fetching the list of members...'}></MyLoaderCircularGradient>
            )}
            {!uploadingMemCoreList && memCoreList && memCoreList.length > 0 && (
               <List dense>
                  {memCoreList.map((item, index) => (
                     <div key={item.LegisMem_id}>
                        <ListItem
                           alignItems="flex-start"
                           sx={{ pl: 0, ml: 0 }}
                           key={item.LegisMem_id}
                           secondaryAction={
                              <Box sx={{ ml: 'auto' }}>
                                 {isSuper && (
                                    <IconButton
                                       edge="end"
                                       aria-label="delete"
                                       onClick={(e) => {
                                          handleDelete(
                                             e,
                                             item.Candidate_id,
                                             item.Name,
                                             item.NameHindi,
                                             item.NameLocal,
                                             item.PhaseNum,
                                             item.Constituency_id,
                                             item.ConstituencyName,
                                             item.ConstituencyNameHindi,
                                             item.ConstituencyNameLocal,
                                             item.Age,
                                             item.DoB,
                                             item.Gender,
                                             item.Party_id,
                                             item.PartyName,
                                             item.PartyNameHindi,
                                             item.PartyNameLocal,
                                             item.PartyAbbreviation,
                                             item.PartyFlag,
                                             item.PartySymbol,
                                             item.AllottedSymbol,
                                             item.Alliances,
                                             item.Image,
                                             item.CoreListId,
                                             item.Announced,
                                             item.AnnouncedDate,
                                             item.AnnounceCancelled,
                                             item.AnnounceCancelledDate,
                                             item.Filed,
                                             item.FiledDate,
                                             item.Accepted,
                                             item.AcceptedDate,
                                             item.Rejected,
                                             item.RejectedDate,
                                             item.Withdrawn,
                                             item.WithdrawnDate,
                                             item.Votes,
                                             item.Winner,
                                          )
                                       }}
                                       sx={{ mr: 1, height: '25px', width: '25px' }}
                                    >
                                       <DeleteIcon fontSize="small" />
                                    </IconButton>
                                 )}
                              </Box>
                           }
                        >
                           <ListItemAvatar sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                 component="span"
                                 variant="body2"
                                 sx={{ color: 'text.primary', display: 'inline', fontWeight: 600, mb: 1 }}
                              >
                                 {index + 1}.
                              </Typography>
                              <Avatar alt={item.Name} src={item.Image} />
                           </ListItemAvatar>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left', whiteSpace: 'pre-line' }}
                              primary={
                                 <>
                                    <Link
                                       underline="hover"
                                       href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.Name.toUpperCase()}
                                    </Link>{' '}
                                    .{' '}
                                    {item.NameLocal && (
                                       <Link
                                          href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                          sx={{ textDecoration: 'none !important' }}
                                          target="_blank"
                                          rel="noopener"
                                       >
                                          {item.NameLocal}
                                       </Link>
                                    )}{' '}
                                    .{' '}
                                    <Link
                                       underline="none"
                                       href={`/${legis.Name}/member/${item.Name}/${item.LegisMem_id}`}
                                       color="warning"
                                       sx={{ textDecoration: 'none !important' }}
                                       target="_blank"
                                       rel="noopener"
                                    >
                                       {item.NameHindi}
                                       {'  '}
                                    </Link>
                                 </>
                              }
                              secondary={
                                 <>
                                    <>
                                       <Typography
                                          component="span"
                                          variant="body2"
                                          sx={{ color: 'text.primary', display: 'inline' }}
                                       >
                                          Constituency:
                                       </Typography>
                                       {` ${item.ConstituencyName} (${item.ConstituencyNumber})`}
                                    </>
                                    <>
                                       <br />
                                       <Typography
                                          component="span"
                                          variant="body2"
                                          sx={{ color: 'text.primary', display: 'inline' }}
                                       >
                                          Seat:
                                       </Typography>
                                       {` ${item.SeatType}`}
                                    </>
                                    <>
                                       <br />
                                       {item.PartyFlag && (
                                          <Link
                                             href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                             target="_blank"
                                             rel="noopener"
                                          >
                                             <img src={item.PartyFlag} className={styles.imgX2} />
                                          </Link>
                                       )}
                                       {item.PartyName !== 'Independent' && (
                                          <Link
                                             href={`/political-party/${item.PartyName}/${item.Party_id}`}
                                             target="_blank"
                                             rel="noopener"
                                             sx={{ textDecoration: 'none !important' }}
                                          >
                                             {item.PartyName}
                                          </Link>
                                       )}
                                       {item.PartyName === 'Independent' && <>{item.PartyName}</>}
                                       <br />
                                       {/* {legis.Strata === 'Central' && (
                                          <>
                                             <br />
                                             {' State / UT: '}
                                             <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                             >
                                                {item.StateName}
                                             </Typography>
                                          </>
                                       )} */}
                                    </>
                                 </>
                              }
                           />
                        </ListItem>

                        <Divider sx={{ width: '80%' }} />
                     </div>
                  ))}
               </List>
            )}
         </Box>
         <Box id="boxModals">
            <Modal
               open={openModalAddMember}
               onClose={handleCloseModalAddMember}
               aria-labelledby="modal-modal-title-addMember"
               aria-describedby="modal-modal-description-addMember"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-addMember"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a constituency
                  </Typography>
                  <AddLegisMem
                     // lastMember={lastMember}
                     // uploading={uploading}
                     // uploaded={uploaded}
                     // uploadingFailed={uploadingFailed}
                     handleAddMember={handleAddMember}
                  ></AddLegisMem>
               </Box>
            </Modal>
         </Box>
      </Box>
   )
}

LegisMems.propTypes = {
   legis: PropTypes.object.isRequired,
   membersListRoot: PropTypes.array.isRequired,
   handleMembersListRootUpdate: PropTypes.func.isRequired,
}

export default LegisMems
