import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Box, Divider, Link, List, ListItem, ListItemText, Typography } from '@mui/material'
import moment from 'moment'

function ElectionsGeneral({ electionsGE }) {
   return (
      <Box>
         <Box sx={{ mx: 1, mt: 1 }}>
            <List dense sx={{ width: '100%', bgcolor: 'background.paper', my: 1, p: 0 }}>
               {electionsGE.length > 0 &&
                  electionsGE.map((item) => (
                     <div key={item.Election_id}>
                        <ListItem alignItems="flex-start" key={item.Election_id}>
                           <ListItemText
                              secondaryTypographyProps={{ align: 'left' }}
                              primary=<Link
                                 href={`/election/${item.Name}/${item.Election_id}`}
                                 rel="noreferrer"
                                 target="_blank"
                                 sx={{ textDecoration: 'none !important' }}
                              >
                                 {item.Name}
                              </Link>
                              secondary={
                                 <React.Fragment>
                                    {item.NameHindi !== '' && (
                                       <>
                                          <Link
                                             href={`/election/${item.Name}/${item.Election_id}`}
                                             rel="noreferrer"
                                             target="_blank"
                                             sx={{ textDecoration: 'none !important' }}
                                          >
                                             {item.NameHindi}
                                          </Link>
                                          <br />
                                       </>
                                    )}
                                    {item.NameLocal !== '' && (
                                       <>
                                          <Link
                                             href={`/election/${item.Name}/${item.Election_id}`}
                                             rel="noreferrer"
                                             target="_blank"
                                             sx={{ textDecoration: 'none !important' }}
                                          >
                                             {item.NameLocal}
                                          </Link>
                                          <br />
                                       </>
                                    )}
                                    {' Poll start: '}
                                    <Typography
                                       sx={{ display: 'inline' }}
                                       component="span"
                                       variant="body2"
                                       color="text.primary"
                                    >
                                       {moment(item.DateStart.toDate()).format('Do MMMM YYYY')}
                                    </Typography>

                                    {item.DateFinish !== '' && (
                                       <>
                                          <br />
                                          {' Poll end: '}
                                          <Typography
                                             sx={{ display: 'inline' }}
                                             component="span"
                                             variant="body2"
                                             color="text.primary"
                                          >
                                             {moment(item.DateFinish.toDate()).format('Do MMMM YYYY')}
                                          </Typography>
                                          {item.IsSpeculated && (
                                             <>
                                                <br />
                                                <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="Crimson"
                                                >
                                                   <strong>
                                                      <u>Disclaimer:</u>
                                                   </strong>{' '}
                                                   The <strong>dates</strong> of this election{' '}
                                                   <strong>are speculated</strong> for the purpose of display. Please
                                                   wait for the actual announcement by the Election Commission of India.
                                                </Typography>
                                                <br />
                                                <Typography
                                                   sx={{ display: 'inline' }}
                                                   component="span"
                                                   variant="body2"
                                                   color="Crimson"
                                                >
                                                   <strong>
                                                      <u>अस्वीकरण:</u>
                                                   </strong>{' '}
                                                   इस चुनाव की <strong>तिथियां</strong> प्रदर्शन के उद्देश्य से{' '}
                                                   <strong>अनुमान</strong> हैं। कृपया भारत के चुनाव आयोग द्वारा वास्तविक
                                                   घोषणा की प्रतीक्षा करें।
                                                </Typography>
                                             </>
                                          )}
                                       </>
                                    )}
                                 </React.Fragment>
                              }
                           />
                        </ListItem>
                        <Divider />
                     </div>
                  ))}
            </List>
            {electionsGE.length < 1 && (
               <Alert variant="outlined" severity="warning" sx={{ mx: 5, mt: 5 }}>
                  No election for this body is available for display.
               </Alert>
            )}
         </Box>
      </Box>
   )
}

ElectionsGeneral.propTypes = {
   electionsGE: PropTypes.array.isRequired,
}

export default ElectionsGeneral
