import { Alert, AlertTitle, Box, IconButton, Link, Modal, Table, TableCell, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { styleModalBox, styleModalTypography } from '../../../Utils/MUITheme/MUITheme'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import EditIcon from '@mui/icons-material/Edit'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import XIcon from '@mui/icons-material/X'
import YouTubeIcon from '@mui/icons-material/YouTube'

import myStyles from './Bio.module.css'
import AddWeblink from '../../../Components/Edits/AddWeblink'
import AddAddress from '../../../Components/Edits/AddAddress'
import AddImageOnly from '../../../Components/Edits/AddImageOnly'
import AddDate from '../../../Components/Edits/AddDate'
import { auth, db, dbStorage } from '../../../FirebaseConfig'
import { arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { selectUsers, setUserProfile } from '../../../redux/reducers/users-slice'
import { v4 } from 'uuid'
import moment from 'moment'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { sentenceCase } from '../../../Utils/Conversions/CaseConversion'
import AddName from '../../../Components/Edits/AddName'
import CloudOffIcon from '@mui/icons-material/CloudOff'

function Bio({ isUserLoggedIn, userProfile }) {
   const user = useSelector(selectUsers)
   const ip = user.ip

   let fullName = ''
   if (userProfile) {
      fullName = userProfile.NameMiddle
         ? userProfile.NameFirst + ' ' + userProfile.NameMiddle + ' ' + userProfile.NameLast
         : userProfile.NameFirst + ' ' + userProfile.NameLast
   }
   console.log('full name: ', fullName)

   const navigate = useNavigate()
   const dispatch = useDispatch()
   const [firstLoadStatus, setFirstLoadStatus] = useState('idle')
   const [thisUser, setThisUser] = useState('')
   const [sourceImage, setSourceImage] = useState('')
   const [countryId, setCountryId] = useState('')

   useEffect(() => {
      if (userProfile && firstLoadStatus === 'idle') {
         if (navigator.onLine) {
            setThisUser(userProfile.User_id)
            setCountryId(userProfile.Country_id)
            setSourceImage(userProfile.Image.Image)
            setFirstLoadStatus('success')
         }
      }
   }, [])

   useEffect(() => {
      if (userProfile && firstLoadStatus !== 'idle') {
         if (navigator.onLine) {
            setThisUser(userProfile.User_id)
            setCountryId(userProfile.Country_id)
            setSourceImage(userProfile.Image.Image)
         }
      }
   }, [userProfile])

   const handleUserProfile = async (userId) => {
      try {
         const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', userId))
         const querySnapshot = await getDocs(querryUser)
         let userFetched = ''
         querySnapshot.forEach((docSnap) => {
            userFetched = { id: docSnap.id, ...docSnap.data() }
         })
         console.log('user profile from bio js: ', userFetched)
         dispatch(setUserProfile(userFetched))
      } catch (error) {
         console.error('error fetching user profile', error)
      }
   }

   // related to Name modal

   const [openModalName, setOpenModalName] = useState(false)

   const handleOpenModalName = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (userProfile.NamesOld && userProfile.NamesOld.length >= 5) {
            // dont allow
         } else {
            // allow
            setOpenModalName(true)
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalName = () => setOpenModalName(false)

   const handleAddName = async (nameDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (nameDetails.NameFirst.length > 1 && nameDetails.NameLast.length > 1) {
            const userRef = doc(db, 'UserProfiles', userProfile.id)

            await updateDoc(userRef, {
               NameFirst: nameDetails.NameFirst,
               NameMiddle: nameDetails.NameMiddle,
               NameLast: nameDetails.NameLast,
               NamesOld: arrayUnion({
                  NameFirst: nameDetails.NameFirst,
                  NameMiddle: nameDetails.NameMiddle,
                  NameLast: nameDetails.NameLast,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })
            // update the local state
            handleUserProfile(userProfile.User_id)
         }

         // close the modal
         setOpenModalName(false)
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameHindi modal

   const [openModalNameHindi, setOpenModalNameHindi] = useState(false)

   const handleOpenModalNameHindi = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (userProfile.NamesOldHindi && userProfile.NamesOldHindi.length >= 5) {
            // dont allow
         } else {
            // allow
            setOpenModalNameHindi(true)
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameHindi = () => setOpenModalNameHindi(false)

   const handleAddNameHindi = async (nameDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (nameDetails.NameFirst.length > 1 && nameDetails.NameLast.length > 1) {
            const userRef = doc(db, 'UserProfiles', userProfile.id)

            await updateDoc(userRef, {
               NameHindiFirst: nameDetails.NameFirst,
               NameHindiMiddle: nameDetails.NameMiddle,
               NameHindiLast: nameDetails.NameLast,
               NamesOldHindi: arrayUnion({
                  NameHindiFirst: nameDetails.NameFirst,
                  NameHindiMiddle: nameDetails.NameMiddle,
                  NameHindiLast: nameDetails.NameLast,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })
            // update the local state
            handleUserProfile(userProfile.User_id)
         }

         // close the modal
         setOpenModalNameHindi(false)
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to NameLocal modal

   const [openModalNameLocal, setOpenModalNameLocal] = useState(false)

   const handleOpenModalNameLocal = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (userProfile.NamesOld && userProfile.NamesOld.length >= 5) {
            // dont allow
         } else {
            // allow
            setOpenModalNameLocal(true)
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalNameLocal = () => setOpenModalNameLocal(false)

   const handleAddNameLocal = async (nameDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (nameDetails.NameFirst.length > 1 && nameDetails.NameLast.length > 1) {
            const userRef = doc(db, 'UserProfiles', userProfile.id)

            await updateDoc(userRef, {
               NameLocalFirst: nameDetails.NameFirst,
               NameLocalMiddle: nameDetails.NameMiddle,
               NameLocalLast: nameDetails.NameLast,
               NamesOldLocal: arrayUnion({
                  NameLocalFirst: nameDetails.NameFirst,
                  NameLocalMiddle: nameDetails.NameMiddle,
                  NameLocalLast: nameDetails.NameLast,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }),
            })
            // update the local state
            handleUserProfile(userProfile.User_id)
         }

         // close the modal
         setOpenModalNameLocal(false)
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to DoB modal

   const [openModalDoB, setOpenModalDoB] = useState(false)

   const handleOpenModalDoB = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (userProfile.DoBsOld && userProfile.DoBsOld.length >= 3) {
            // dont allow
         } else {
            // allow
            setOpenModalDoB(true)
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   const handleCloseModalDoB = () => setOpenModalDoB(false)

   const handleAddDoB = async (dateDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (dateDetails.Date !== '') {
            const dobForOld = {
               DoB: dateDetails.Date,
               CrBy: auth.currentUser.uid,
               CrDt: new Date(),
               CrIP: ip.data.ip,
            }
            const userRef = doc(db, 'UserProfiles', userProfile.id)

            await updateDoc(userRef, {
               DoB: dateDetails.Date,
               DoBsOld: arrayUnion(dobForOld),
            })
            // update the local state
            handleUserProfile(userProfile.User_id)

            // userProfile.DoBOld.push(dobForOld)
            setOpenModalDoB(false)
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Address modal

   const [openModalAddress, setOpenModalAddress] = useState(false)

   const handleOpenModalAddress = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (userProfile.AddressOld && userProfile.AddressOld.length >= 10) {
            // dont allow
         } else {
            // allow
            setOpenModalAddress(true)
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalAddress = () => setOpenModalAddress(false)

   const handleAddAddress = async (addressDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (
            addressDetails.State_id.length >= 3 &&
            addressDetails.District_id.length >= 3 &&
            addressDetails.Address.length >= 10 &&
            addressDetails.ValueRadio !== ''
         ) {
            ///
            addressDetails.CrBy = auth.currentUser.uid
            addressDetails.CrDt = new Date()
            addressDetails.CrIP = ip.data.ip
            console.log('stage 4 submit')

            addressDetails.Address = sentenceCase(addressDetails.Address)
            console.log('stage 5 submit')

            const userRef = doc(db, 'UserProfiles', userProfile.id)

            if (addressDetails.ValueRadio === 'Current address') {
               ///
               await updateDoc(userRef, {
                  Address: addressDetails,
                  AddressOld: arrayUnion(addressDetails),
               })
               console.log('stage 6 submit')
               // update the address display
               handleUserProfile(userProfile.User_id)
               console.log('stage 7 submit')
            } else {
               await updateDoc(userRef, {
                  AddressOld: arrayUnion(addressDetails),
               })
               console.log('stage 8 submit')
            }

            // close modal
            setOpenModalAddress(false)
         }
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to image modal
   const [openModalImage, setOpenModalImage] = useState(false)

   const handleOpenModalImage = (e) => {
      e.preventDefault()
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         setOpenModalImage(true)
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalImage = () => setOpenModalImage(false)

   const handleAddImage = async (imageDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         const imageRef = ref(dbStorage, `Users/${auth.currentUser.uid}/${imageDetails.image.name + v4()}`)
         await uploadBytes(imageRef, imageDetails.image).then((snapshot) => {
            getDownloadURL(snapshot.ref).then(async (url) => {
               //
               const imgObj = {
                  Image: url,
                  CrBy: auth.currentUser.uid,
                  CrDt: new Date(),
                  CrIP: ip.data.ip,
               }

               const userRef = doc(db, 'UserProfiles', userProfile.id)
               const userRefSubColl = doc(db, 'UserProfiles', userProfile.id, 'CoreLists', 'Images-0001')
               const docSnapUserSubColl = await getDoc(userRefSubColl)

               if (docSnapUserSubColl.exists()) {
                  // means at least one record has been inserted erliear for this subcollection
                  // update subcollection 'CoreLists' of legislature with new values
                  await updateDoc(userRefSubColl, {
                     Images: arrayUnion(imgObj),
                  })
               } else {
                  // means no record has been inserted for this subcollection
                  // create the subcollection CoreLists with new values
                  setDoc(
                     userRefSubColl,
                     {
                        Images: [imgObj],
                     },
                     { merge: true },
                  )
               }

               if (imageDetails.ValueRadio === 'Current image') {
                  //
                  await updateDoc(userRef, {
                     Image: imgObj,
                  })
                  // update local state
                  handleUserProfile(userProfile.User_id)

                  // setSourceImage(url)
               }
            })
         })

         // close modal
         setOpenModalImage(false)
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Phone modal
   // const [openModalPhone, setOpenModalPhone] = useState(false)

   // const handleOpenModalPhone = (e) => {
   //    e.preventDefault()

   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       setOpenModalPhone(true)
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }
   // const handleCloseModalPhone = () => setOpenModalPhone(false)

   // const [lastPhone, setLastPhone] = useState('')
   // const [uploadingPhone, setUploadingPhone] = useState(false)
   // const [uploadedPhone, setUploadedPhone] = useState(false)
   // const [uploadingFailedPhone, setUploadingFailedPhone] = useState(false)

   // const handleAddPhone = async (phoneDetails) => {
   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       phoneDetails.CrBy = auth.currentUser.uid
   //       phoneDetails.CrDt = new Date()
   //       phoneDetails.CrIP = ip.data.ip

   //       setLastPhone(phoneDetails.Phone.toString())
   //       setUploadingPhone(true)
   //       try {
   //          const userRef = doc(db, 'UserProfiles', userProfile.id)
   //          await updateDoc(userRef, {
   //             Phones: arrayUnion(phoneDetails),
   //          })

   //          // update the local
   //          userProfile.Phones.push(phoneDetails)

   //          // close modal
   //          // setOpenModalPhone(false)

   //          setUploadingPhone(false)
   //          setUploadedPhone(true)
   //          setUploadingFailedPhone(false)
   //       } catch (error) {
   //          alert('Error adding Phone: ', error.message)
   //          console.log('Error adding Phone', error)
   //          setUploadingPhone(false)
   //          setUploadedPhone(false)
   //          setUploadingFailedPhone(true)
   //       }
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }

   // related to Website modal
   const [openModalWebsite, setOpenModalWebsite] = useState(false)

   const handleOpenModalWebsite = (e) => {
      e.preventDefault()

      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (userProfile && userProfile.Weblinks && userProfile.Weblinks.length > 9) {
            // dont allow
         } else {
            // allow
            setOpenModalWebsite(true)
         }
      } else {
         navigate('/', { replace: true })
      }
   }
   const handleCloseModalWebsite = () => setOpenModalWebsite(false)

   const handleAddWebsite = async (weblinkDetails) => {
      if (auth.currentUser && thisUser === auth.currentUser.uid) {
         if (userProfile && userProfile.Weblinks && userProfile.Weblinks.length > 9) {
            // dont allow
            navigate('/', { replace: true })
         } else {
            // allow
            weblinkDetails.CrBy = auth.currentUser.uid
            weblinkDetails.CrDt = new Date()
            weblinkDetails.CrIP = ip.data.ip

            const userRef = doc(db, 'UserProfiles', userProfile.id)
            await updateDoc(userRef, {
               Weblinks: arrayUnion(weblinkDetails.Weblink),
               WeblinksOld: arrayUnion(weblinkDetails),
            })

            // update the local state
            handleUserProfile(userProfile.User_id)
         }

         // close modal
         // setOpenModalWebsite(false)
      } else {
         navigate('/', { replace: true })
      }
   }

   // related to Email modal
   // const [openModalEmail, setOpenModalEmail] = useState(false)

   // const handleOpenModalEmail = (e) => {
   //    e.preventDefault()

   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       setOpenModalEmail(true)
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }
   // const handleCloseModalEmail = () => setOpenModalEmail(false)

   // const handleAddEmail = async (emailDetails) => {
   //    if (auth.currentUser && thisUser === auth.currentUser.uid) {
   //       emailDetails.CrBy = auth.currentUser.uid
   //       emailDetails.CrDt = new Date()
   //       emailDetails.CrIP = ip.data.ip

   //       const userRef = doc(db, 'UserProfiles', userProfile.id)
   //       await updateDoc(userRef, {
   //          EmailsOther: arrayUnion(emailDetails.Email),
   //          EmailsOtherOld: arrayUnion(emailDetails),
   //       })

   //       // update the local state
   //       userProfile.EmailsOther.push(emailDetails.Email)

   //       // close modal
   //       setOpenModalEmail(false)
   //    } else {
   //       navigate('/', { replace: true })
   //    }
   // }

   return (
      <Box sx={{ minHeight: 500 }}>
         {!navigator.onLine && (
            <div>
               <Alert
                  variant="outlined"
                  severity="error"
                  sx={{ margin: 5, justifyContent: 'center', alignItems: 'center' }}
               >
                  <AlertTitle>
                     You are offline !
                     <CloudOffIcon sx={{ ml: 5 }} />
                  </AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && isUserLoggedIn === '' && (
            <div>
               <Alert variant="outlined" severity="warning" sx={{ margin: 5 }}>
                  <AlertTitle>... Loading !</AlertTitle>
               </Alert>
            </div>
         )}
         {navigator.onLine && isUserLoggedIn === 'out' && (
            <Box>
               <Alert
                  variant="outlined"
                  severity="warning"
                  sx={{
                     margin: 5,
                     alignItems: 'center',
                     justifyContent: 'center',
                     whiteSpace: 'pre-line',
                     textAlign: 'center',
                  }}
               >
                  <AlertTitle>Please log in! {'\n'} कृपया लॉगिन करें!</AlertTitle>
                  You are not logged in the site. {'\n'} आप साइट पर लॉग इन नहीं हैं.
               </Alert>
            </Box>
         )}
         {navigator.onLine && isUserLoggedIn === 'in' && (
            <Box sx={{ m: 1 }}>
               <Box sx={{ fontWeight: 600, textAlign: 'center', mb: 1 }}>Welcome ... {fullName}!</Box>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div>
                     <Box
                        sx={{
                           display: 'flex',
                           borderRadius: 1,
                           flexDirection: 'column',
                           px: 2,
                        }}
                     >
                        <Table>
                           <tbody>
                              <TableRow>
                                 <TableCell className={myStyles.cell_style_left} variant="head">
                                    Name:
                                 </TableCell>
                                 <TableCell className={myStyles.cell_style_right}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                       }}
                                    >
                                       {userProfile.NameMiddle !== '' ? (
                                          <Box>
                                             {userProfile.NameFirst} {userProfile.NameMiddle} {userProfile.NameLast}
                                          </Box>
                                       ) : (
                                          <Box>
                                             {userProfile.NameFirst} {userProfile.NameMiddle}
                                          </Box>
                                       )}

                                       {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editName"
                                             onClick={(e) => {
                                                handleOpenModalName(e)
                                             }}
                                             sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <TableRow className={myStyles.row_style}>
                                 <TableCell className={myStyles.cell_style_left} variant="head">
                                    Name hindi:
                                 </TableCell>
                                 <TableCell className={myStyles.cell_style_right}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                       }}
                                    >
                                       {userProfile.NameHindiMiddle !== '' ? (
                                          <Box>
                                             {userProfile.NameHindiFirst} {userProfile.NameHindiMiddle}{' '}
                                             {userProfile.NameHindiLast}
                                          </Box>
                                       ) : (
                                          <Box>
                                             {userProfile.NameHindiFirst} {userProfile.NameHindiMiddle}
                                          </Box>
                                       )}

                                       {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editNameHindi"
                                             onClick={(e) => {
                                                handleOpenModalNameHindi(e)
                                             }}
                                             sx={{
                                                ml: 'auto',
                                                height: '25px',
                                                width: '25px',
                                             }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>
                                 </TableCell>
                              </TableRow>

                              <TableRow className={myStyles.row_style}>
                                 <TableCell className={myStyles.cell_style_left} variant="head">
                                    Name local:
                                 </TableCell>
                                 <TableCell className={myStyles.cell_style_right}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                       }}
                                    >
                                       {userProfile.NameLocalMiddle !== '' ? (
                                          <Box>
                                             {userProfile.NameLocalFirst} {userProfile.NameLocalMiddle}{' '}
                                             {userProfile.NameLocalLast}
                                          </Box>
                                       ) : (
                                          <Box>
                                             {userProfile.NameLocalFirst} {userProfile.NameLocalMiddle}
                                          </Box>
                                       )}

                                       {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editNameLocal"
                                             onClick={(e) => {
                                                handleOpenModalNameLocal(e)
                                             }}
                                             sx={{
                                                ml: 'auto',
                                                height: '25px',
                                                width: '25px',
                                             }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell
                                    sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                                    className={myStyles.cell_style_left}
                                    variant="head"
                                 >
                                    Birth date {'\n'} जन्म तिथि:
                                 </TableCell>
                                 <TableCell className={myStyles.cell_style_right}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                       }}
                                    >
                                       <Box>
                                          {userProfile.DoB && userProfile.DoB !== '' && (
                                             <Box>{moment(userProfile.DoB.toDate()).format('Do MMMM YYYY')}</Box>
                                          )}
                                       </Box>

                                       {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editDoB"
                                             onClick={(e) => {
                                                handleOpenModalDoB(e)
                                             }}
                                             sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell className={myStyles.cell_style_left} variant="head">
                                    Image:
                                 </TableCell>
                                 <TableCell className={myStyles.cell_style_right}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                       }}
                                    >
                                       <Box>
                                          {sourceImage !== '' ? (
                                             <img src={sourceImage} className={myStyles.imgX} />
                                          ) : null}
                                       </Box>

                                       {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editImage"
                                             onClick={(e) => {
                                                handleOpenModalImage(e)
                                             }}
                                             sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell
                                    sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                                    className={myStyles.cell_style_left}
                                    variant="head"
                                 >
                                    Address {'\n'} पता:
                                 </TableCell>
                                 <TableCell
                                    sx={{ width: '70%', wordBreak: 'break-all' }}
                                    className={myStyles.cell_style_right}
                                 >
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                       }}
                                    >
                                       {userProfile.Address && (
                                          <Box>
                                             <div>{userProfile.Address.Address}</div>
                                             <span>
                                                {userProfile.Address.DistrictName} - {userProfile.Address.StateName} -{' '}
                                                {userProfile.Address.PinCode}
                                             </span>
                                          </Box>
                                       )}

                                       {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editAddress"
                                             onClick={(e) => {
                                                handleOpenModalAddress(e)
                                             }}
                                             sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell
                                    sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                                    className={myStyles.cell_style_left}
                                    variant="head"
                                 >
                                    Registered number {'\n'} पंजीकृत दूरभाष:
                                 </TableCell>
                                 <TableCell className={myStyles.cell_style_right}>
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                       }}
                                    >
                                       <Box>{userProfile.MobilePhone}</Box>

                                       {/* {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editPhone"
                                             onClick={(e) => {
                                                handleOpenModalPhone(e)
                                             }}
                                             sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )} */}
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell
                                    sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                                    className={myStyles.cell_style_left}
                                    variant="head"
                                 >
                                    Registered Email: {'\n'} पंजीकृत ईमेल:
                                 </TableCell>
                                 <TableCell
                                    sx={{ width: '70%', wordBreak: 'break-all' }}
                                    className={myStyles.cell_style_right}
                                 >
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                       }}
                                    >
                                       <Box>{userProfile.Email}</Box>

                                       {/* {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editEmail"
                                             onClick={(e) => {
                                                handleOpenModalEmail(e)
                                             }}
                                             sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )} */}
                                    </Box>
                                 </TableCell>
                              </TableRow>
                              <TableRow>
                                 <TableCell
                                    sx={{ whiteSpace: 'pre-line', lineHeight: 1.3 }}
                                    className={myStyles.cell_style_left}
                                    variant="head"
                                 >
                                    Web Links {'\n'} वेब लिंक:
                                 </TableCell>
                                 <TableCell
                                    sx={{ width: '70%', wordBreak: 'break-all' }}
                                    className={myStyles.cell_style_right}
                                 >
                                    <Box
                                       sx={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                       }}
                                    >
                                       <Box>
                                          {userProfile.Weblinks &&
                                             userProfile.Weblinks.length > 0 &&
                                             userProfile.Weblinks.map((item, index) => (
                                                <div key={index}>
                                                   {item &&
                                                      (item.indexOf('http://') === 0 ||
                                                         item.indexOf('https://') === 0) && (
                                                         <Link
                                                            underline="hover"
                                                            href={item}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                         >
                                                            {item.substring(0, 25) === 'https://www.facebook.com/' && (
                                                               <FacebookIcon />
                                                            )}
                                                            {item.substring(0, 26) === 'https://www.instagram.com/' && (
                                                               <InstagramIcon />
                                                            )}
                                                            {item.substring(0, 25) === 'https://www.linkedin.com/' && (
                                                               <LinkedInIcon />
                                                            )}
                                                            {item.substring(0, 14) === 'https://x.com/' && <XIcon />}
                                                         </Link>
                                                      )}
                                                   {item &&
                                                      item.indexOf('http://') !== 0 &&
                                                      item.indexOf('https://') !== 0 && (
                                                         <Link
                                                            underline="hover"
                                                            href={`//${item}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                         >
                                                            {item.substring(0, 25) === 'https://www.facebook.com/' ? (
                                                               <FacebookIcon />
                                                            ) : item.substring(0, 26) ===
                                                              'https://www.instagram.com/' ? (
                                                               <InstagramIcon />
                                                            ) : item.substring(0, 25) ===
                                                              'https://www.linkedin.com/' ? (
                                                               <LinkedInIcon />
                                                            ) : item.substring(0, 14) === 'https://x.com/' ? (
                                                               <XIcon sx={{ color: '#000000' }} />
                                                            ) : item.substring(0, 24) === 'https://www.youtube.com/' ? (
                                                               <YouTubeIcon />
                                                            ) : (
                                                               item.Weblink
                                                            )}
                                                         </Link>
                                                      )}
                                                </div>
                                             ))}
                                       </Box>

                                       {auth.currentUser && thisUser === auth.currentUser.uid && (
                                          <IconButton
                                             edge="end"
                                             aria-label="editWebsite"
                                             onClick={(e) => {
                                                handleOpenModalWebsite(e)
                                             }}
                                             sx={{ ml: 'auto', height: '25px', width: '25px' }}
                                          >
                                             <EditIcon fontSize="small" />
                                          </IconButton>
                                       )}
                                    </Box>
                                 </TableCell>
                              </TableRow>
                           </tbody>
                        </Table>
                     </Box>
                  </div>
               </LocalizationProvider>
            </Box>
         )}
         <Box id="boxModals">
            <Modal
               open={openModalName}
               onClose={handleCloseModalName}
               aria-labelledby="modal-modal-title-name"
               aria-describedby="modal-modal-description-name"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-name"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Your name (in English)
                  </Typography>
                  <AddName handleAddName={handleAddName}></AddName>
               </Box>
            </Modal>
            <Modal
               open={openModalNameHindi}
               onClose={handleCloseModalNameHindi}
               aria-labelledby="modal-modal-title-Hindiname"
               aria-describedby="modal-modal-description-Hindiname"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-hindiname"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Your name (in Hindi)
                  </Typography>
                  <AddName handleAddName={handleAddNameHindi} labelName="Name in hindi"></AddName>
               </Box>
            </Modal>
            <Modal
               open={openModalNameLocal}
               onClose={handleCloseModalNameLocal}
               aria-labelledby="modal-modal-title-localname"
               aria-describedby="modal-modal-description-localname"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-localname"
                     variant="h6"
                     component="h6"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Your name (in your local language)
                  </Typography>
                  <AddName handleAddName={handleAddNameLocal} labelName="Name (in local language)"></AddName>
               </Box>
            </Modal>
            <Modal
               open={openModalDoB}
               onClose={handleCloseModalDoB}
               aria-labelledby="modal-modal-title-dob"
               aria-describedby="modal-modal-description-dob"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-dob"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Change Date Of Birth
                  </Typography>
                  <AddDate labelName="Date of Birth / जन्म तिथि" handleAddDate={handleAddDoB}></AddDate>
               </Box>
            </Modal>
            <Modal
               open={openModalAddress}
               onClose={handleCloseModalAddress}
               aria-labelledby="modal-modal-title-headquarter"
               aria-describedby="modal-modal-description-headquarter"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-headquarter"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add Address
                  </Typography>
                  <AddAddress countryId={countryId} handleAddAddress={handleAddAddress}></AddAddress>
               </Box>
            </Modal>
            <Modal
               open={openModalImage}
               onClose={handleCloseModalImage}
               aria-labelledby="modal-modal-title-logo"
               aria-describedby="modal-modal-description-logo"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-logo"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add image of Candidate
                  </Typography>
                  <AddImageOnly handleAddImage={handleAddImage}></AddImageOnly>
               </Box>
            </Modal>
            {/* <Modal
               open={openModalEmail}
               onClose={handleCloseModalEmail}
               aria-labelledby="modal-modal-title-email"
               aria-describedby="modal-modal-description-email"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-email"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add an email
                  </Typography>
                  <AddEmail handleAddEmail={handleAddEmail}></AddEmail>
               </Box>
            </Modal> */}
            <Modal
               open={openModalWebsite}
               onClose={handleCloseModalWebsite}
               aria-labelledby="modal-modal-title-website"
               aria-describedby="modal-modal-description-website"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-website"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add a web link
                  </Typography>
                  <AddWeblink handleAddWeblink={handleAddWebsite}></AddWeblink>
               </Box>
            </Modal>
            {/* <Modal
               open={openModalPhone}
               onClose={handleCloseModalPhone}
               aria-labelledby="modal-modal-title-phones"
               aria-describedby="modal-modal-description-phones"
               disableScrollLock
            >
               <Box sx={styleModalBox}>
                  <Typography
                     id="modal-modal-title-phones"
                     variant="h6"
                     component="h2"
                     align="center"
                     sx={styleModalTypography}
                  >
                     Add Phones:
                  </Typography>
                  <AddPhone
                     lastPhone={lastPhone}
                     handleAddPhone={handleAddPhone}
                     uploadingPhone={uploadingPhone}
                     uploadedPhone={uploadedPhone}
                     uploadingFailedPhone={uploadingFailedPhone}
                  ></AddPhone>
               </Box>
            </Modal> */}
         </Box>
      </Box>
   )
}

Bio.propTypes = {
   isUserLoggedIn: PropTypes.string.isRequired,
   userProfile: PropTypes.object.isRequired,
}

export default Bio
