import { Box, Tabs, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

import Grid2 from '@mui/material/Unstable_Grid2/Grid2'

import { TabContext } from '@mui/lab'
import { StyledTab1, TabPanel2, a11yProps } from '../../Components/TabPanelSystem'
import SwipeableViews from 'react-swipeable-views'
import Login from './Login'
import Signup from './Signup'
import Subscriptions from './Subscriptions'
import { onAuthStateChanged } from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserProfile, setUser, setUserProfile } from '../../redux/reducers/users-slice'
import { auth, db } from '../../FirebaseConfig'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import Activities from './Activities'
import Bio from './Bio/Bio'

function Dashboard() {
   // const navigate = useNavigate()
   const dispatch = useDispatch()

   const [value, setValue] = useState(0)
   const handleChangeNavTab = (event, newValue) => {
      setValue(newValue)
      // setConstituencyId('')
   }
   const handleChangeNavTabIndex = (index) => {
      setValue(index)
      // setConstituencyId('')
   }

   const userProfile = useSelector(selectUserProfile)
   console.log('user profile: ', userProfile)

   const [mode, setMode] = useState('email')

   const [isUserLoggedIn, setIsUserLoggedIn] = useState('')
   const [supports, setSupports] = useState('')

   useEffect(() => {
      console.log('login stage -1 : useeffect ')
      // setError('')
      setMode('email')
      if (navigator.onLine) {
         try {
            onAuthStateChanged(auth, (user) => {
               if (user) {
                  dispatch(setUser({ id: user.uid, email: user.email, displayName: user.displayName }))
                  handleUserProfile(user.uid)
                  console.log('login stage -1 : useeffect: user present ')
                  setIsUserLoggedIn('in')
               } else {
                  console.log('login stage -1 : useeffect: user not present ')
                  dispatch(setUser(null))
                  dispatch(setUserProfile(null))
                  setIsUserLoggedIn('out')
               }
            })
         } catch {
            dispatch(setUser(null))
            dispatch(setUserProfile(null))
            setIsUserLoggedIn('out')
         }
      }
   }, [])

   const handleIsUserLoggedIn = async (isUserLoggdIn) => {
      setIsUserLoggedIn(isUserLoggdIn)
   }

   const handleUserProfile = async (userId) => {
      try {
         const querryUser = query(collection(db, 'UserProfiles'), where('User_id', '==', userId))
         const querySnapshot = await getDocs(querryUser)
         let userFetched = ''
         querySnapshot.forEach((docSnap) => {
            userFetched = { id: docSnap.id, ...docSnap.data() }
         })
         console.log('user profile from app js: ', userFetched)
         dispatch(setUserProfile(userFetched))
         fetchSupports(userFetched.id)
      } catch (error) {
         console.error('error fetching user profile', error)
      }
   }

   const fetchSupports = async (id) => {
      try {
         const docRefUserProfileSubColl = doc(db, 'UserProfiles', id, 'CoreLists', 'Supports-0001')
         const docSnapUserProfileSubColl = await getDoc(docRefUserProfileSubColl)

         if (docSnapUserProfileSubColl.exists()) {
            const list = docSnapUserProfileSubColl.data().Supports
            setSupports(list)
         } else {
            setSupports([])
         }
      } catch (error) {
         //
      }
   }

   const theme = useTheme()
   //
   return (
      <Box sx={{ minHeight: 800, px: 0.5 }}>
         <Box>
            <Typography sx={{ fontFamily: 'Poppins', fontSize: 25, fontWeight: 900 }}>Dashboard</Typography>
            <Typography sx={{ fontFamily: 'Poppins', fontSize: 20, fontWeight: 900 }}>नियंत्रण-पट्ट</Typography>
         </Box>
         <TabContext value={value}>
            <Box>
               <Tabs
                  value={value}
                  onChange={handleChangeNavTab}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="full width tabs example"
                  centered
                  // variant="scrollable"
                  // scrollButtons="auto"
                  sx={{
                     marginBottom: 1,
                     py: 0.5,
                     minHeight: 25,
                     backgroundColor: '#e7e9bb',
                  }}
               >
                  <StyledTab1 label={`Login \n लॉग इन करें`} {...a11yProps(0)} />
                  <StyledTab1 label={`Signup \n साइन अप करें`} {...a11yProps(1)} />
                  <StyledTab1 label={`Bio \n परिचय`} {...a11yProps(2)} />
                  <StyledTab1 label={`Subscriptions \n सदस्यता`} {...a11yProps(3)} />
                  {/* <StyledTab1 label={`Leader Profile \n नेता प्रोफ़ाइल`} {...a11yProps(3)} /> */}
                  <StyledTab1 label={`Activities \n गतिविधियाँ`} {...a11yProps(4)} />
               </Tabs>
            </Box>
            <Grid2 container sx={{ mt: 2 }} id="vBoxGridContainer">
               <Grid2 item xs={12} sm={12} md={8} id="vboxGrid1">
                  <Box>
                     <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeNavTabIndex}
                     >
                        <TabPanel2 value={value} index={0} dir={theme.direction}>
                           <Login
                              isUserLoggedIn={isUserLoggedIn}
                              handleIsUserLoggedIn={handleIsUserLoggedIn}
                              userProfile={userProfile}
                           />
                        </TabPanel2>
                        <TabPanel2 value={value} index={1} dir={theme.direction}>
                           <Signup
                              isUserLoggedIn={isUserLoggedIn}
                              mode={mode}
                              handleIsUserLoggedIn={handleIsUserLoggedIn}
                              userProfile={userProfile}
                           />
                        </TabPanel2>
                        <TabPanel2 value={value} index={2} dir={theme.direction}>
                           <Bio isUserLoggedIn={isUserLoggedIn} userProfile={userProfile} />
                        </TabPanel2>
                        <TabPanel2 value={value} index={3} dir={theme.direction}>
                           <Subscriptions isUserLoggedIn={isUserLoggedIn} userProfile={userProfile} />
                        </TabPanel2>
                        {/* <TabPanel2 value={value} index={3} dir={theme.direction}>
                           <NetaProfile isUserLoggedIn={isUserLoggedIn} userProfile={userProfile} />
                        </TabPanel2> */}
                        <TabPanel2 value={value} index={4} dir={theme.direction}>
                           <Activities isUserLoggedIn={isUserLoggedIn} userProfile={userProfile} supports={supports} />
                        </TabPanel2>
                     </SwipeableViews>
                  </Box>
               </Grid2>
               <Grid2 item xs={12} sm={12} md={4}>
                  <Box sx={{ height: '95%', backgroundColor: '#F1F1F1', borderRadius: 2, m: 2 }}></Box>
               </Grid2>
            </Grid2>
         </TabContext>
      </Box>
   )
}

export default Dashboard
